jQuery(document).ready(function(){

    /*Temp conditional
    * TODO: add to dom based routing and only trigger on gsm
    * */
    if(jQuery('body').hasClass("template_order-form")) {
        /*
         * Acquisition (new contract) conditional fields
         * */

        var checkInput = jQuery('.form-phonenumber-keep input');
        var type = jQuery('.form-phonenumber-keep-type');
        var typeInput = jQuery('.form-phonenumber-keep-type input');
        var cancelled = jQuery('.form-phonenumber-keep-cancelled');
        var cancelledInput = jQuery('.form-phonenumber-keep-cancelled input');
        var busCon = jQuery('.form-phonenumber-keep-buscon');
        var busConInput = jQuery('.form-phonenumber-keep-buscon input');
        var acq = jQuery('.form-phonenumber-keep-acq');
        var simnr = jQuery('.form-phonenumber-keep-simnr');
        var simnrInput = jQuery('.form-phonenumber-keep-simnr input');
        var busClientnr = jQuery('.form-phonenumber-keep-bus-clientnr');
        var busClientnrInput = jQuery('.form-phonenumber-keep-bus-clientnr input');
        var provider = jQuery('.form-phonenumber-keep-provider');
        var providerLabelInput = jQuery('#phonenumber-keep-provider-name');
        var identInput = jQuery('.form-identification select');
        var identIssuer = jQuery('.form-identification-issuer');
        var identIssuerIsRdw = jQuery('.form-identification-issuer input#identification-issuer-rdw');
        var identIssuerIsMunicipality = jQuery('.form-identification-issuer input#identification-issuer-mun');
        var keepNoDate = jQuery('.form-phonenumber-keep-whish-date');

        //Logic when cookie has already been set
        var checkValue = jQuery('input[name=phonenumber-keep]:checked', '#gsm-order');
        if (checkValue.val() === 'j') {
            type.removeProp('hidden');
            keepNoDate.removeProp('hidden');
        }

        var typeValue = jQuery('input[name=phonenumber-keep-type]:checked', '#gsm-order');
        if (typeValue.val() === 'post') {
            cancelled.removeProp('hidden');
            busCon.removeProp('hidden');
        } else if (typeValue.val() === 'pre') {
            acq.removeProp('hidden');
            simnr.removeProp('hidden');
            provider.removeProp('hidden');
        }

        var busConValue = jQuery('input[name=phonenumber-keep-buscon]:checked', '#gsm-order');
        if (busConValue.val() === 'con') {
            acq.removeProp('hidden');
            simnr.removeProp('hidden');
            provider.removeProp('hidden');
        } else if (busConValue.val() === 'bus') {
            acq.removeProp('hidden');
            busClientnr.removeProp('hidden');
            provider.removeProp('hidden');
        }

        //If identification is RIJ (drivers license) then show .form-identification-issuer
        //Don't show, default 'issued by gemeente' is acceptable
        if (identInput.val() === 'RIJ') {
            identIssuerIsMunicipality.prop("checked", true);
        } else {
            identIssuerIsMunicipality.prop("checked", false);
        }
        identInput.on('change', function () {
            if (identInput.val() === 'RIJ') {
                identIssuerIsMunicipality.prop("checked", true);
            } else {
                identIssuerIsMunicipality.prop("checked", false);
            }
        });

        //Don't allow order if identification issuer is RDW
        identIssuerIsRdw.on('click', function () {
            jQuery("#modal-order").prop("checked", true);
            jQuery('.modal-title').html('Je legitimatiebewijs wordt niet geaccepteerd');
            jQuery('.modal-content #error_callback').html('Met het door jou opgegeven legitimatiebewijs is het niet mogelijk om ' +
                'een T-Mobile abonnement af te sluiten. <br /><br /> Kies voor een ander legitimatiebewijs of een andere provider.');
            $(this).prop('checked', false);
        });

        checkInput.on('change', function () {
            typeInput.prop('checked', false);
            cancelled.prop('checked', false);
            busConInput.prop('checked', false);

            var checkValue = jQuery('input[name=phonenumber-keep]:checked', '#gsm-order');
            if (checkValue.val() === 'j') {
                type.removeProp('hidden');
                keepNoDate.removeProp('hidden');
            } else if (checkValue.val() === 'n') {
                type.prop('hidden', 'hidden');
                cancelled.prop('hidden', 'hidden');
                busCon.prop('hidden', 'hidden');
                acq.prop('hidden', 'hidden');
                simnr.prop('hidden', 'hidden');
                busClientnr.prop('hidden', 'hidden');
                provider.prop('hidden', 'hidden');
                keepNoDate.prop('hidden', 'hidden');
            }
        });

        provider.on('change', function () {
            providerLabelInput.val(jQuery("option:selected", this).text());
        });

        typeInput.on('change', function () {
            cancelled.prop('checked', false);
            busConInput.prop('checked', false);

            var typeValue = jQuery('input[name=phonenumber-keep-type]:checked', '#gsm-order');
            if (typeValue.val() === 'post') {
                acq.prop('hidden', 'hidden');
                simnr.prop('hidden', 'hidden');
                provider.prop('hidden', 'hidden');

                cancelled.removeProp('hidden');
                busCon.removeProp('hidden');
            } else if (typeValue.val() === 'pre') {
                cancelled.prop('hidden', 'hidden');
                busCon.prop('hidden', 'hidden');
                busClientnr.val('');

                acq.removeProp('hidden');
                simnr.removeProp('hidden');
                provider.removeProp('hidden');
            }
        });

        busConInput.on('change', function () {
            var busConValue = jQuery('input[name=phonenumber-keep-buscon]:checked', '#gsm-order');
            if (busConValue.val() === 'con') {
                busClientnr.prop('hidden', 'hidden');

                acq.removeProp('hidden');
                simnr.removeProp('hidden');
                provider.removeProp('hidden');
            } else if (busConValue.val() === 'bus') {
                simnr.prop('hidden', 'hidden');

                acq.removeProp('hidden');
                busClientnr.removeProp('hidden');
                provider.removeProp('hidden');
            }
        });

        /*
         * Tele2 Number portation whish date
         * */
        var curDateRaw = new Date();
        curDateRaw.setHours(0,0,0,0);
        var curDateStamp = curDateRaw.setDate(curDateRaw.getDate());
        var minDateStamp = curDateRaw.setDate(curDateRaw.getDate() + 15);
        var maxDateStamp = curDateRaw.setDate(curDateRaw.getDate() + 59);
        var keepNoInput = jQuery('.form-phonenumber-keep-whish-date input');
        var keepNoDay = jQuery('#phonenumber-keep-whish-date-day');
        var keepNoMonth = jQuery('#phonenumber-keep-whish-date-month');
        var keepNoYear = jQuery('#phonenumber-keep-whish-date-year');

        function keepNoValidator() { // jshint ignore:line
            var formItem = jQuery('.form-phonenumber-keep-whish-date');
            var message = '';
            if(keepNoYear.val() !== '' && keepNoMonth.val() !== '' && keepNoDay.val() !== '') {
                var keepNoDateRaw = keepNoYear.val() + '-' + keepNoMonth.val() + '-' + keepNoDay.val();
                var keepNoDate = new Date(keepNoDateRaw);
                var keepNoDateStamp = keepNoDate.setDate(keepNoDate.getDate());
                var oneDay = 1000*60*60*24;
                var difference = keepNoDateStamp - curDateStamp;
                var daysDifference = Math.round(difference/oneDay);
                var isWeekend = keepNoDate.getDay() % 6 === 0;

                if (isWeekend || daysDifference < 15 || daysDifference > 59) {
                    message = '<ul>';
                    if(isWeekend) {
                        message += '<li>Deze datum ligt in het weekend</li>';
                    }
                    if(daysDifference < 15){
                        message += '<li>Datum ligt minder dan 15 dagen in de toekomst, namelijk: ' + daysDifference + ' dagen</li>';
                    } else if(daysDifference > 59){
                        message += '<li>Datum ligt meer dan 59 dagen in de toekomst, namelijk: ' + daysDifference + ' dagen</li>';
                    }
                    message += '</ul><p style="margin-top: 0.5rem;padding-top: 0.6rem;border-top: solid 1px #ec9292;color:#000;line-height:1.2rem;display:inline-block">Datum moet tussen de 15 en 59 dagen in de toekomst liggen, niet in het weekend of op feestdagen</p>';
                    setErrorMessage(formItem, message);
                } else{
                   //Holiday check not build in yet, if not a holiday, date is accepted
                    setErrorMessage(formItem, message);
                }
            }
            setErrorMessage(formItem, message);
        }

        //TODO: check if onblur should be used
        keepNoInput.on('blur',function(e){
            keepNoValidator();
        });

        function setErrorMessage(formItem, message){ // jshint ignore:line
            formItem.removeClass('has-error');
            formItem.find('.is-input').removeClass('form-hint-wrapper');
            formItem.find('.is-input .form-hint').remove();
            if(message !== ''){
                formItem.addClass('has-error');
                formItem.find('.is-input').addClass('form-hint-wrapper');
                formItem.find('.is-input').append('<span class="form-hint is-always-visible">'+ message +'</span>');
            }
        }
    }

});
