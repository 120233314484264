var heroSection = jQuery('section.hero');

function sliderImage(currentSlideKey, nextSlideKey){
    currentSlideTile = jQuery('.hero-tile-wrapper[data-slick-index='+currentSlideKey+'] .tile');

    if(nextSlideKey !== false) {
        nextSlideTile = jQuery('.hero-tile-wrapper[data-slick-index='+nextSlideKey+'] .tile');
        if(nextSlideTile.attr('data-sim-only') === 'simonly'){
            slideSubscription = nextSlideTile.attr('data-sim-only');

        } else if(nextSlideTile.attr('data-subscription-form') === 'los_basis'){
            slideSubscription = nextSlideTile.attr('data-provider')+'_basis';
        } else{
            slideSubscription = nextSlideTile.attr('data-subscription-form');
        }
        slideProductID = nextSlideTile.attr('data-id');

    } else{
        if(currentSlideTile.attr('data-sim-only') === 'simonly'){
            slideSubscription = currentSlideTile.attr('data-sim-only');
        } else if(currentSlideTile.attr('data-subscription-form') === 'los_basis'){
            slideSubscription = currentSlideTile.attr('data-provider')+'_basis';
        } else{
            slideSubscription = currentSlideTile.attr('data-subscription-form');
        }
        slideProductID = currentSlideTile.attr('data-id');
    }

    if(slideSubscription !== heroSection.attr('data-subscription')){
        heroSection.attr('data-subscription',slideSubscription);
    }
    heroSection.attr('data-id',slideProductID);


    var dealCTAHTML = false;
    var dealCTA = jQuery('.hero .cta.is-deal');

    if(slideSubscription === 'gsmtopdeal'){
        dealCTAHTML = '<a class="cta is-deal is-'+slideSubscription+'" href="/gsm/gsm-topdeal/">Bekijk alle GSM topdeals</a>';
    } else if(slideSubscription === 'simonly'){
        dealCTAHTML = '<a class="cta is-deal is-'+slideSubscription+'" href="/gsm/abonnement/sim-only/">Bekijk alle Sim Only deals</a>';
    } else if(slideSubscription === 'tmobile_basis'){
        dealCTAHTML = '<a class="cta is-deal is-'+slideSubscription+'" href="/gsm/abonnement/t-mobile/">Bekijk alle T-Mobile deals</a>';
    } else if(slideSubscription === 'tmobile_go') {
        dealCTAHTML = '<a class="cta is-deal is-'+slideSubscription+'" href="/gsm/abonnement/t-mobile-go/">Bekijk alle T-Mobile Go deals</a>';
    } else if(slideSubscription === 'tele2_basis'){
        dealCTAHTML = '<a class="cta is-deal is-'+slideSubscription+'" href="/gsm/abonnement/tele-2/">Bekijk alle Tele2 deals</a>';
    } else if(slideSubscription === 'ben_basis'){
        dealCTAHTML = '<a class="cta is-deal is-'+slideSubscription+'" href="/gsm/abonnement/ben/">Bekijk alle Ben deals</a>';
    } else if(slideSubscription === 'vodafone_basis'){
        dealCTAHTML = '<a class="cta is-deal is-'+slideSubscription+'" href="/gsm/abonnement/vodafone/">Bekijk alle Vodafone deals</a>';
    } else{
        dealCTAHTML = '<a class="cta is-deal is-'+slideSubscription+'" href="/gsm/gsm-topdeal/">Bekijk alle unieke Miky deals</a>';
    }

    dealCTA.remove();
    if(dealCTAHTML){
        jQuery('.hero .offer-wrapper').after(dealCTAHTML);
    }
}

jQuery( document ).ready(function() {
    if(heroSection.length){
        var currentSlideKey = false;
        var currentSlideTile = false;
        var currentSlideSubscription = false;
        var nextSlideKey = false;
        var nextSlideTile = false;

        jQuery(heroSection).hover(function() {
            jQuery('.hero .offer-wrapper').slick('slickPause') ;
        }, function() {
            jQuery('.hero .offer-wrapper').slick('slickPlay') ;
        });

        jQuery('.hero .cta.is-deal').on('click', function() {
            jQuery('.hero .offer-wrapper').slick('slickPause') ;
        });

        jQuery('.hero .offer-wrapper').on('init', function(slick){
            //console.log('initial slide:');
            currentSlideKey = jQuery('section.hero .hero-tile-wrapper[aria-hidden="false"]').attr('data-slick-index');
            nextSlideKey = false;
            sliderImage(currentSlideKey,nextSlideKey);
        });
        // On before slide change
        jQuery('.hero .offer-wrapper').on('beforeChange', function(event, slick, currentSlide, nextSlide){
            //console.log( "slick before change!" );
            currentSlideKey = currentSlide;
            nextSlideKey = nextSlide;
            sliderImage(currentSlideKey,nextSlideKey);
        });
        // On after slide change
        // jQuery('section.hero .offer-wrapper').on('afterChange', function(event, slick, currentSlide){
        //     console.log( "slick after change!" );
        //     currentSlideKey = currentSlide;
        //     nextSlideKey = false;
        //     sliderImage(currentSlideKey,nextSlideKey);
        // });
    }
});
