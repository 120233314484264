jQuery(document).ready(function() {
    var jQuerystar_rating = jQuery('.star-rating .fa');

    var SetRatingStar = function() {
        return jQuerystar_rating.each(function() {
            if (parseInt(jQuerystar_rating.siblings('input.rating-value').val()) >= parseInt(jQuery(this).data('rating'))) {
                return jQuery(this).removeClass('fa-star-o').addClass('fa-star');
            } else {
                return jQuery(this).removeClass('fa-star').addClass('fa-star-o');
            }
        });
    };

    jQuerystar_rating.on('click', function(e) {
        e.preventDefault();
        jQuerystar_rating.siblings('input.rating-value').val(jQuery(this).data('rating'));
        return new SetRatingStar();
    });

    new SetRatingStar();
});