jQuery(document).ready(function() {

    var sectionTriggerNext = jQuery('.order-form section .is-order-form .is-next');
    var sectionHeader = jQuery('.order-form section .header-trigger');

    var checkSelection = jQuery('.order-form .is-order .is-previous');
    var checkForm = jQuery('.order-form .is-order .is-next');

    //TODO wrap in if statement to check if section has class "is-pre-complete"
    sectionTriggerNext.click(function(){
        var jQuerysection = jQuery(this).closest('section');
        var target = jQuery(this.hash);
        jQuerysection.next('section').find('.section-content').slideToggle(500, 'swing', function(){
            if(jQuery('body').hasClass('is-mq-mobile')){
                jQuery('html,body').delay(250).animate({
                    scrollTop: (target.offset().top)
                }, 500);
            } else if(jQuery('body').hasClass('is-mq-desktop')){
                jQuery('html,body').animate({
                    scrollTop: (target.offset().top - 87)
                }, 500);
            } else{
                jQuery('html,body').animate({
                    scrollTop: (target.offset().top)
                }, 500);
            }
            return false;
        });
        jQuerysection.next('section').addClass("is-visited").toggleClass('is-active');

        jQuerysection.find('.section-content').slideToggle(500, 'swing');
        jQuerysection.addClass("is-complete").toggleClass('is-active');

        checkSelection.removeClass('is-single');
        checkForm.show();
    });

    sectionHeader.click(function(e){
        e.preventDefault();
        var jQuerysection = jQuery(this).closest('section');
        var target = jQuery(this.hash);

        if(jQuerysection.hasClass('is-visited') && !jQuerysection.hasClass('is-active')){
            jQuerysection.find('.section-content').slideToggle(500, 'swing', function(){
                if(jQuery('body').hasClass('is-mq-mobile')){
                    jQuery('html,body').delay(250).animate({
                        scrollTop: (target.offset().top)
                    }, 500);
                } else if(jQuery('body').hasClass('is-mq-desktop')){
                    jQuery('html,body').animate({
                        scrollTop: (target.offset().top - 87)
                    }, 500);
                } else{
                    jQuery('html,body').animate({
                        scrollTop: (target.offset().top)
                    }, 500);
                }
                return false;
            });
            jQuerysection.toggleClass('is-active');

            jQuerysection.siblings('section.is-active').find('.section-content').slideToggle(500, 'swing');
            jQuerysection.siblings('section.is-active').toggleClass('is-active');
        }
        //else if(jQuerysection.previous('section').hasClass('is-pre-complete')){
        //
        //}
    });

    //Masked Input
    jQuery("#account").mask("aa99 aaaa 9999 9999 99", {autoclear: false});
    //jQuery("#account").mask("aa99 aaaa 9999 9999 99",{placeholder:"NL99 BANK 0123 4567 89"});

    jQuery("label[for=phonenumber-keep-yes]").on("click", function () {
        var phonenumberKeepInfo = jQuery(this).closest('.control-row').next('.form-phonenumber-keep-info');
        phonenumberKeepInfo.fadeIn();
    });

    jQuery("label[for=phonenumber-keep-no]").on("click", function () {
        var phonenumberKeepInfo = jQuery(this).closest('.control-row').next('.form-phonenumber-keep-info');
        phonenumberKeepInfo.fadeOut('fast');
    });

    jQuery("label[for=phonenumber-mobile-ret-check] input").on("click", function () {
        var phonenumberRet = jQuery(this).closest('.control-row').next('.form-phonenumber-mobile-ret');
        phonenumberRet.slideToggle(this.checked);
    });

});
