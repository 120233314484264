jQuery(document).ready(function(jQuery) {
    //TODO: check if 'tel' anchors need to be excluded
    jQuery('a').not('[href*="mailto:"]').each(function () {

        /**
         * Open all external links in a new window
         */
        var isInternalLink = new RegExp('/' + window.location.host + '/');
        if (!isInternalLink.test(this.href) ) {
            jQuery(this).attr('target', '_blank');

            /**
             * Add nofollow relationship if link does not contain the class '.follow'
             */
            if (!jQuery(this).hasClass('follow')){
                jQuery(this).attr('rel', 'nofollow');
            }
        }
    });
});