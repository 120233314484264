var routingGSM = {
    // GSM pages
    'is_gsm': {
        init: function() {
            // JavaScript to be fired on the home page
            //console.log('I am initially gsm!');

            if(jQuery('body').is('.template_front')){
                // slick carousel sim only
                // slick carousel
                jQuery('.overview-contracts-sim-only .offer-wrapper').finish().slick({
                    infinite: true,
                    mobileFirst: true,
                    dots: true,
                    speed: 300,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    responsive: [
                        {
                            breakpoint: 576,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                dots: false,
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 1024,
                            settings: {
                                dots: false,
                                slidesToShow: 3,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
                // slick carousel phones
                // enquire.register("screen and (max-width:79.9375em)", {
                //     match : function() {
                        // slick carousel
                        jQuery('.overview-contracts-with-phone .offer-wrapper, .overview-phones .offer-wrapper').finish().slick({
                            infinite: true,
                            mobileFirst: true,
                            dots: true,
                            speed: 300,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            responsive: [
                                {
                                    breakpoint: 576,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 1
                                    }
                                },
                                {
                                    breakpoint: 768,
                                    settings: {
                                        dots: false,
                                        slidesToShow: 2,
                                        slidesToScroll: 1
                                    }
                                },
                                {
                                    breakpoint: 1024,
                                    settings: {
                                        dots: false,
                                        slidesToShow: 3,
                                        slidesToScroll: 1
                                    }
                                },
                                {
                                    breakpoint: 1280,
                                    settings: {
                                        dots: false,
                                        slidesToShow: 4,
                                        slidesToScroll: 1
                                    }
                                }
                            ]
                        });
                //     },
                //     unmatch : function() {
                //         //jQuery('.overview-contracts-with-phone .offer-wrapper, .overview-phones .offer-wrapper').finish().slick('unslick');
                //     }
                // });
            }

        },
        finalize: function() {
            // JavaScript to be fired on the home page, after the init JS
            //console.log('I am finally home!');

        }
    }
};