jQuery(document).ready(function() {

    var sectionHeader = jQuery('.order-form-check section h2');
    var section = jQuery('.order-form-check section');

    if (jQuery('body').hasClass('is-mq-desktop')) {
        // Open all
        //section.find('.section-content').show();
        //section.find('.fa').toggleClass('fa-plus').toggleClass('fa-minus');

        // Only open first
        section.first().find('.section-content').show();
        section.first().find('.fa').toggleClass('fa-plus').toggleClass('fa-minus');
    }

    sectionHeader.click(function(){
        var jQuerysectionContent = jQuery(this).next();
        jQuery(this).find('.fa').toggleClass('fa-plus').toggleClass('fa-minus');
        jQuerysectionContent.slideToggle(500, 'swing');
    });
});