var routingHome = {
    // Home page
    'home': {
        init: function() {
            // JavaScript to be fired on the home page
            //console.log('I am initially home!');

            // slick carousel for all media queries
            jQuery('.is-overview .offer-wrapper').finish().slick({
                infinite: true,
                mobileFirst: true,
                dots: true,
                speed: 300,
                slidesToShow: 1,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            dots: false,
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            dots: false,
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 1280,
                        settings: {
                            dots: false,
                            slidesToShow: 3,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        },
        finalize: function() {
            // JavaScript to be fired on the home page, after the init JS
            //console.log('I am finally home!');

            // Match offer height with menu height on label overview sections
            jQuery('.overview-wrapper .is-mh').matchHeight();
        }
    }
};