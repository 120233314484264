// Localised script variables
var themeUrl = WPVARS.themeUrl,
    environment = WPVARS.wpEnvironment,
    tracking = false;

function setErrorMessage(formItem, message){ // jshint ignore:line
    formItem.removeClass('has-error');
    formItem.find('.is-input').removeClass('form-hint-wrapper');
    formItem.find('.is-input .form-hint').remove();
    if(message !== ''){
        formItem.addClass('has-error');
        formItem.find('.is-input').addClass('form-hint-wrapper');
        formItem.find('.is-input').append('<span class="form-hint is-always-visible">'+ message +'</span>');
    }
}

function internalValidityCheck(){
    var internalValidity = true;
    var aInternalValidity = [];
    aInternalValidity['errors'] = []; //jshint ignore:line
    var message = '';

    /*
     * Tele2 4G only condition
     * TODO: make a global function for internal validation
     * */
    var condition3GCheck = jQuery('#terms-conditions-3g-barring');
    if (condition3GCheck.length > 0) {
        var condition3GCheckRow = jQuery('.form-terms-conditions-3g-barring');
        message = '';
        if(condition3GCheck.is(":checked")){
            setErrorMessage(condition3GCheckRow, message);
        } else{
            internalValidity = false;
            message = 'Accepteer de Tele2 4G voorwaarden';
            setErrorMessage(condition3GCheckRow, message);
        }
        aInternalValidity['errors']['condition3GCheck'] = message; //jshint ignore:line
    }

    /*
     * ILT validation
     */
    var iltNetIncome = jQuery('#ilt-net-income'),
        iltLivingCost = jQuery('#ilt-living-cost');

    if (iltNetIncome.length > 0) {
        var iltNetIncomeValue = iltNetIncome.val(),
            iltNetIncomeRow = iltNetIncome.closest('.control-row');

        message = ''; //jshint ignore:line
        if(iltNetIncomeValue !== ''){
            setErrorMessage(iltNetIncomeRow, message); //jshint ignore:line
        } else{
            internalValidity = false;
            message = 'Vul je maandelijkse netto inkomen in.'; //jshint ignore:line
            setErrorMessage(iltNetIncomeRow, message); //jshint ignore:line
        }
        aInternalValidity['errors']['iltNetIncome'] = message; //jshint ignore:line
    }

    if (iltLivingCost.length > 0) {
        var iltLivingCostValue = iltLivingCost.val(),
            iltLivingCostRow = iltLivingCost.closest('.control-row');

        message = ''; //jshint ignore:line
        if(iltLivingCostValue !== ''){
            setErrorMessage(iltLivingCostRow, message); //jshint ignore:line
        } else{
            internalValidity = false;
            message = 'Vul je vaste woonlasten in.'; //jshint ignore:line
            setErrorMessage(iltLivingCostRow, message); //jshint ignore:line
        }
        aInternalValidity['errors']['iltLivingCost'] = message; //jshint ignore:line
    }
    aInternalValidity['global'] = internalValidity; //jshint ignore:line

    return aInternalValidity;
}

jQuery('.order-wrapper button').on('click', function(ev){
  ev.preventDefault();

  var button = jQuery(this);
  var form = button.closest('form');
  // Log unserialized form data
  //console.log(form);

  var data = jQuery(form).serialize();
  data += '&action=check_propositie';

  // TODO check if serialized data output is correct (some values seem malformed)
  // Log serialized form data
  //console.log(data);

  jQuery.ajax({
  type: 'POST',
  url: themeUrl + '/lib/soap3bm/call_soap.php',
  dataType: 'json',
  data : data ,
    success: function(data) {

      if(data.soap.RESULTAAT_CODE === "0"){
        button.closest('form').submit();
      }else{
          //TODO: set proper error message for users
          jQuery("#modal-order").prop("checked", true);
          //alert(data.soap.ERROR.LINE);
      }
    },
    error: function(data){
        //TODO: set proper error message for users
        jQuery("#modal-order").prop("checked", true);
        console.log('SOAP is dead, Jim. Check with Jack');
    }
  });
});

jQuery('#formSubmit').on('click', function(ev){
  ev.preventDefault();

  var internalValidityObject = internalValidityCheck(),
      internalValidityMessages = internalValidityObject.errors,
      internalValidity = internalValidityObject['global']; //jshint ignore:line

  /*TODO make this a global function*/
  if(typeof mouseflow !== 'undefined'){
    //console.log(mouseflow);
    //console.log(mouseflow.isRecording);
    if(mouseflow.isRecording !== false){
      tracking = true;
    }
  }
  //console.log(tracking);
  if (environment === 'production' && tracking === true) {mouseflow.formSubmitAttempt('#gsm-order');}
  var button = jQuery(this);
  button.html('even wachten..');
  var form = button.closest('form');
//  if (form[0].checkValidity()) {
    var data = jQuery(form).serialize();
    data += '&action=check_order';
    jQuery.ajax({
    type: 'POST',
    url: themeUrl + '/lib/soap3bm/call_soap.php',
    dataType: 'json',
    data :  data,
      success: function(data) {
        if(data.soap.RESULTAAT_CODE === "0" && internalValidity){
          // 0 is good in this case
          if (environment === 'production' && tracking === true) {mouseflow.formSubmitSuccess('#gsm-order');}
          button.closest('form').submit();
        }else{
          if (environment === 'production' && tracking === true) {mouseflow.formSubmitFailure('#gsm-order');}
          var feedback = '<ul>';
          // you never know what you gone get from the API, so we have to check the error type. object or string
          if(!internalValidity){
              for (var message in internalValidityMessages) {
                  feedback += '<li>'+internalValidityMessages[message]+'</li>';
              }
          } if(typeof data.errors === 'object'){
            // If it is an object we can assume we have to loop trough it.

            jQuery('.order-form').addClass('is-checked');

            jQuery('.form-personal').addClass('is-active');
            jQuery('.form-personal .section-content').show();
            jQuery('.form-personal > a').css('pointer-events', 'none');
            jQuery('.form-personal > a span').hide();

            jQuery('.form-contact-payment').addClass('is-active');
            jQuery('.form-contact-payment .section-content').show();
            jQuery('.form-contact-payment > a').css('pointer-events', 'none');
            jQuery('.form-contact-payment > a span').hide();

            jQuery('.form-personal .order-navigation').hide();

            jQuery('.order-form .control-row').each(function(index,val){
                jQuery(this).removeClass('has-error');
                jQuery(this).find('.is-input').removeClass('form-hint-wrapper');
                jQuery(this).find('.is-input .form-hint').remove();
            });

            //console.log(data.errors);
            jQuery(data.errors).each(function(index,val){
              if(val !== 'false' && val.value !== 'undefined') {
                if(jQuery(val[0]).length !== 0){
                  //Loop through sub-objects
                  for (var key in val) {
                    if(!val.hasOwnProperty(key)){
                      continue;
                    }
                    //console.log(val[key]);
                    jQuery('.' + val[key].fieldClass).addClass('has-error');
                    jQuery('.' + val[key].fieldClass).find('.is-input').addClass('form-hint-wrapper');
                    jQuery('.' + val[key].fieldClass).find('.is-input').append('<span class="form-hint is-always-visible">'+ val[key].value +'</span>');
                    feedback += '<li>' + val[key].value + '</li>';
                      if(val[key].fieldClass === 'form-account'){
                          jQuery('.form-account').next('.expander').find('.expander-trigger.expander-hidden').trigger('click');
                      }
                  }
                } else{
                  //Normal loop through objects
                  jQuery('.' + val.fieldClass).addClass('has-error');
                  jQuery('.' + val.fieldClass).find('.is-input').addClass('form-hint-wrapper');
                  jQuery('.' + val.fieldClass).find('.is-input').append('<span class="form-hint is-always-visible">'+ val.value +'</span>');
                  feedback += '<li>' + val.value + '</li>';
                  if(val.fieldClass === 'form-account'){
                      jQuery('.form-account').next('.expander').find('.expander-trigger.expander-hidden').trigger('click');
                  }
                }
              }
            });
            feedback += '</ul>';

          }else if(typeof data.soap.ERROR.LINE === 'string'){

            // it's just a string with the error.
            var line = data.soap.ERROR.LINE;
              console.log(line);
              if (line.substring(0, 6) === 'ERROR:') {
              feedback = '<ul><li>' + line.substring(7) + '</li></ul>';
            }
            else {
              feedback = '<strong>Dit veld was niet in orde</strong><ul><li>' + data.soap.ERROR.LINE + '</li></ul>';
            }
          }else {
            //we got some data and we aperently can't handle it
            feedback ='<ul><li>' + data.soap.ERROR.LINE + '</li></ul>';
          }
          jQuery('.is-order .modal-title').empty();
          jQuery('.is-order .modal-title').html('Er is een aantal velden niet juist ingevoerd').show();
          jQuery('.is-order .modal-content #error_callback').empty();
          jQuery('.is-order .modal-content #error_callback').html(feedback).show();
          jQuery("#modal-order").prop("checked", true);

          //alert('Problem with configuration, please contact Miky directly.');
        }
        internalValidityCheck();
        button.html('Gegevens controleren');
      },
      error: function(data){
        if (environment === 'production' && tracking === true) {mouseflow.formSubmitFailure('#gsm-order');}
        // server error. Probably 3bm or client internet down?
        console.log(data);
        console.log('Something went wrong with the call.');

        var offerLink = jQuery('.offer-link').html();

        if (data.responseJSON.error_type === 'cookie_not_set') {
          feedback = '<ul><li>Je selectie kan niet meer worden opgehaald. Je kan het product opnieuw selecteren door terug te gaan naar de <a href="' + offerLink + '">productpagina</a>.</li></ul>';
        }
        else {
          feedback = '<ul><li>Communicatie met server verliep niet goed, druk nog een keer op de blauwe bestelknop,</li></ul><p>Neem contact op met Miky als dit probleem zich voor blijft doen.</p>';
        }

        jQuery('#error_callback').empty();
        jQuery('#error_callback').html(feedback).show();
        button.html('Gegevens controleren');
        jQuery("#modal-order").prop("checked", true);
      }
    });

    //jQueryform.submit();
  // } else {
  //   console.log('not valid');
  // }
});

var type = false;
jQuery('#housenumber, #housenumber_contact').on('blur', function(ev){
  // check wich form is asking the check
  if(jQuery(this).attr('id') === "housenumber_contact"){
    type = '_contact';
  }else{
    type = '';
  }
  //console.log(type);
  var postcode = jQuery('#postalcode'+type).val();
  var postcodenumbers = jQuery('#postalcode-numbers'+type).val();
  var postcodeletters = jQuery('#postalcode-letters'+type).val();
  var housenumber     = jQuery('#housenumber'+type).val();

  tijdstip = new Date(); tijd = tijdstip.getTime();

  if(postcodenumbers !== '' && postcodeletters !== '' && housenumber !== ''){

    jQuery.ajax({
    type: 'get',
    url: 'https://justwalk.3bm.nl/postcodecheck_3bm.php?INPBEDRIJFSCODE=10305638&POSTKODE=' + postcode + '&HUISNUMMER=' + housenumber + '&TIJD=' + tijd,
    //url: 'http://api.postcodedata.nl/v1/postcode/?postcode=' + postcodenumbers + postcodeletters + '&streetnumber='+ housenumber +'&ref=miky.nl&type=json',
    dataType: 'html',
    data : { },
      success: function(data) {
        var aData = data.split('|');
        jQuery('#streetname'+type).val(aData[0]);
        jQuery('#cityname'+type).val(aData[1]);
        jQuery('#error_callback_zip').empty().hide();
      },
      error: function(data){
        console.log('Something went wrong with the call.');

        jQuery('#streetname'+type).val('');
        jQuery('#cityname'+type).val('');

        feedback ='<ul id="zip"><li>Postcode / huisnummer combinatie niet gevonden</li></ul>';
        jQuery('#error_callback_zip').empty();
        jQuery('#error_callback_zip').html(feedback).show();
      }
    });


  }else{

    // feedback ='<ul id="zip"><li>Postcode + huisnummer niet volledig</li></ul>';
    jQuery('#error_callback_zip').empty();
    // jQuery('#error_callback_zip').html(feedback).show();
  }


//
});


jQuery('#confirmOrder').on('click', function(ev){
  ev.preventDefault();
  var button = jQuery(this);
  button.html('even wachten..');

    data = '&action=new_order';
    jQuery.ajax({
    type: 'POST',
    url: themeUrl + '/lib/soap3bm/call_soap.php',
    dataType: 'json',
    data :  data,
      success: function(data) {
        if(data.soap.RESULTAAT_CODE === "0"){
          //console.log(ccOrder);

          // 0 is good in this case
          //button.closest('form').submit();

          // Redirect to payment page, if required for the ordered phone. Otherwise, redirect to the confirmation page.
          if(typeof data.soap.IDEAL_URL !== 'undefined' && data.soap.IDEAL_URL !== '' && ccOrder === false) {
            window.location = data.soap.IDEAL_URL;
          } else {
            window.location = button.attr('href');
          }

        }else{
          feedback ='<ul style="background:red;"><li>Er ging iets mis</li></ul>';
          //console.log(data);
          jQuery('#error_callback').empty();
          jQuery('#error_callback').html(feedback).show();
        }
        button.html('Gegevens controleren');
      },
      error: function(data){
        // server error. Probably 3bm or client internet down?
        var offerLink = jQuery('.offer-link').html();
        console.log(data);
        console.log('Something went wrong with the call.');
        feedback ='<ul style="background:red;"><li>Communicatie met server verliep niet goed.</li>' +
            '<li>Je kan het product opnieuw selecteren door <a href="' + offerLink + '">hier</a> te klikken. ' +
            'Blijven de problemen zich voordoen, neem dan contact op met Miky.</li></ul>';
        jQuery('#error_callback').empty();
        jQuery('#error_callback').html(feedback).show();
        button.html('Gegevens controleren');
      }
    });

    //jQueryform.submit();
  // } else {
  //   console.log('not valid');
  // }
});

jQuery(document).ready(function(){
  if ( jQuery('.order-confirmation').length ) {
    jQuery.ajax({
      type: 'POST',
      url: themeUrl + '/lib/soap3bm/call_soap.php',
      dataType: 'json',
      data: 'action=order_confirmed',
      success: function (data) {
        //console.log('Order cookies zijn verwijderd');
      },
      error: function (data) {
        //console.log('Order cookies zijn niet verwijderd');
      }
    });
  }
});

jQuery(document).ready(function(){
    //console.log(document.cookie.selected_offer);

    var confirmOnPageExit = function (e)
    {
      // If we haven't been passed the event get the window.event
      e = e || window.event;

      var message = 'Any text will block the navigation and display a prompt';

      // For IE6-8 and Firefox prior to version 4
      if (e)
      {
        e.returnValue = message;
      }

      // For Chrome, Safari, IE8+ and Opera 12+
      return message;
    };

    var orderOverview = jQuery('body').hasClass('template_order-overview');
    var orderForm = jQuery('body').hasClass('template_order-form');
    var orderFormCheck = jQuery('body').hasClass('template_order-form-check');
    var orderNavigationLink = jQuery('.order-navigation a');

    var isOrderPage = false;
    if(orderOverview || orderForm || orderFormCheck){
        isOrderPage = true;
    }

    switch(isOrderPage) {
        case true:
            // Turn it on - assign the function that returns the string
            window.onbeforeunload = confirmOnPageExit;
            orderNavigationLink.on('click',function(){
                // Turn it off - remove the function entirely
                window.onbeforeunload = null;
            });
            break;
        default:
            // Turn it off - remove the function entirely
            window.onbeforeunload = null;
    }

    jQuery("#cc-employee-random").click(function () {
      var select = document.getElementById('cc-employee');
      var items = jQuery('select option.employee');

      var index = Math.floor(Math.random() * items.length + 1);
      select.selectedIndex = index;
    });
});
