var routingGSMOffer = {
    // GSM pages
    'template_offer': {
        init: function() {

            if(jQuery('body').is('.is-gsm')) {

                // JavaScript to be fired on the home page
                //console.log('I am initially gsm offer!');

                //this is where we apply opacity to the arrow
                jQuery(window).scroll( function(){

                    //get scroll position
                    var topWindow = jQuery(window).scrollTop();
                    //multiply by 1.5 so the arrow will become transparent half-way up the page
                    var topWindowFactor = topWindow * 1.5;

                    //get height of window
                    var windowHeight = jQuery(window).height();

                    //set position as percentage of how far the user has scrolled
                    var position = topWindowFactor / windowHeight;
                    //invert the percentage
                    position = 1 - position;

                    //define arrow opacity as based on how far up the page the user has scrolled
                    //no scrolling = 1, half-way up the page = 0
                    jQuery('.arrow-wrap').css('opacity', position);

                });

                enquire.register("screen and (min-width:48em)", {
                    match: function () {
                        // Matchheight
                        jQuery('.offer-receipt').matchHeight({
                            target: jQuery('.offer-main')
                        });

                        jQuery('.receipt.is-desktop').matchHeight({
                            target: jQuery('.receipt-content')
                        });
                        jQuery(window).scroll(function () {
                            jQuery('.receipt.is-desktop').matchHeight({
                                target: jQuery('.receipt-content')
                            });
                        });
                    },
                    unmatch: function () {
                        jQuery('.offer-receipt').matchHeight({remove: true});
                        jQuery('.receipt.is-desktop').matchHeight({remove: true});
                    }
                });

                // Fix button height
                jQuery('button .name').matchHeight();
                jQuery('button .content').matchHeight();
                jQuery('button .popular').matchHeight();
                jQuery('button .price .info').matchHeight();

                // Scrolltofixed
                jQuery('.receipt.is-mobile').scrollToFixed({
                    bottom: 0,
                    maxWidth: (767 - scrollbarMeasure()),
                    limit: function () {
                        // Limit without compensating for wrapper margin used in the demo
                        //var limit = jQuery('footer').offset().top - jQuery('.receipt-mobile').outerHeight(true);
                        //var limit = jQuery('footer').offset().top - jQuery('.offer-info').outerHeight(true) - jQuery('.receipt.is-mobile').outerHeight(true) - parseFloat(jQuery('.offer-receipt').css("margin-bottom"));
                        //return limit;
                    },
                    zIndex: 1000,
                    spacerClass: 'scrollspacer'
                });


                //Set :after pseudo element css
                var addRule = (function(style){
                    var sheet = document.head.appendChild(style).sheet;
                    return function(selector, css){
                        var propText = Object.keys(css).map(function(p){
                            return p+":"+css[p];
                        }).join(";");
                        sheet.insertRule(selector + "{" + propText + "}", sheet.cssRules.length);
                    };
                })(document.createElement("style"));

                var colorButtons = jQuery('.offer-intro .phone-colors button');
                if(colorButtons.length !== '0'){
                    jQuery.each(colorButtons, function (key, value) {
                        var colorCode = jQuery(this).attr('data-hex');
                        var colorID = jQuery(this).attr("id");
                        addRule(jQuery("#"+colorID+":after").selector, {
                            "border-left": "1rem solid "+colorCode+" !important"
                        });
                    });
                }

                jQuery('.receipt.is-desktop').scrollToFixed({
                    marginTop: function () {
                        var marginTop = jQuery(window).height() - jQuery('.receipt.is-desktop .receipt-content').outerHeight(true) - 20;
                        var headerScrollHeight = 87;
                        var loggedInHeight = 32;
                        var spacing = 20;
                        if (marginTop >= 0 && jQuery("body").hasClass("logged-in")) {
                            return headerScrollHeight + loggedInHeight + spacing;
                        }
                        else if (marginTop >= 0) {
                            return headerScrollHeight + spacing;
                        }
                        return marginTop;
                    },
                    minWidth: (768 - scrollbarMeasure()),
                    limit: function () {
                        var limit;
                        if (jQuery('body').hasClass('is-mq-md')) {
                            limit = jQuery('footer').offset().top - jQuery('.offer-information').outerHeight(true) - jQuery('.receipt.is-desktop .receipt-content').outerHeight(true) - 30;
                        } else {
                            limit = jQuery('footer').offset().top - jQuery('.receipt.is-desktop .receipt-content').outerHeight(true) - 30;
                        }
                        return limit;
                    },
                    preAbsolute: function () {
                        jQuery(this).addClass('scroll-to-fixed-fixed');
                    },
                    zIndex: 999
                });

                jQuery('.is-overview .is-trigger').bind('click', function(e){
                    e.preventDefault();
                    var subMenu = jQuery('.is-overview .is-detailed');
                    var collapsedText = 'Bekijk';
                    var expandedText = 'Verberg';
                    var toggleClass = 'is-expanded';

                    jQuery(this).parent().find(subMenu).slideToggle('fast');
                    jQuery(this).parent().toggleClass(toggleClass);

                    if(jQuery(this).parent().hasClass(toggleClass)){
                        jQuery(this).find('.trigger-cta .switch').html(expandedText);
                    } else{
                        jQuery(this).find('.trigger-cta .switch').html(collapsedText);
                    }

                    if(subMenu.length > 1) {
                        jQuery(this).parent().siblings().find(subMenu).slideUp('fast');
                        jQuery(this).parent().siblings().removeClass(toggleClass);
                        jQuery(this).parent().siblings().find('.trigger-cta .switch').html(collapsedText);
                    }

                });
            }
        },
        finalize: function() {
            // JavaScript to be fired on the home page, after the init JS
            //console.log('I am finally gsm offer!');

            if(jQuery('body').is('.is-gsm')) {

                //Switch main image by clicking thumbnail
                var thumbImg = jQuery('.phone-thumbs .thumb-wrapper');
                thumbImg.mouseenter(function (e) {
                    e.preventDefault();
                    var mainImg = jQuery('.phone-image img');
                    var curPosition = mainImg.attr('data-current-position');
                    var newPosition = jQuery(this).find('img').attr('data-position-image');

                    if(curPosition !== newPosition) {
                        //TODO: find smooth way of animating
                        // mainImg.fadeOut(200, 'swing', function () {
                        //     // will be called when the element finishes fading out
                        //     // if selector matches multiple elements it will be called once for each
                        //     console.log('cur: ' + curPosition + '. - replace with new: ' + newPosition + '.');
                        //     mainImg.attr('src', mainImg.attr('src').replace(curPosition + '.', newPosition + '.'));
                        //     mainImg.attr('data-current-position', newPosition);
                        //     mainImg.fadeIn(200, 'swing');
                        // });
                        mainImg.attr('src', mainImg.attr('src').replace(curPosition + '.', newPosition + '.'));
                        mainImg.attr('data-current-position', newPosition);
                    }
                });


                /*
                 * Offer page dynamic
                 * TODO: only run offerBundle -> types if they are applicable
                 * TODO: refactor offerBundle object and setReceiptData function
                 * */

                //Data attributes
                //Contract
                var contractDuration = jQuery('.offer-contract-duration button.is-active').attr('data-contract-duration');
                var contractStatus = jQuery('.offer-contract-status button.is-active').attr('data-contract-status');
                //Button
                var buttonKey = 'data-bundle-key';
                var buttonPriceMonthValueRaw = 'data-bundle-price';
                var buttonPriceMonthValueDiscountRaw = 'data-bundle-discount-price';
                var buttonPriceOnceOriginalRaw = 'data-bundle-price-once-original';
                var buttonPriceOnceDiscountRaw = 'data-bundle-price-once-discount';
                var buttonPriceOnceRaw = 'data-bundle-price-once';
                var buttonConnectionCostOriginal= 'data-bundle-connection-cost-original';
                var buttonConnectionCostDiscount= 'data-bundle-connection-cost-discount';
                //Offer bundles
                var offerBundle = {
                    contractType: {
                        buttonCount: jQuery('.offer-contract-type button').length,
                        button: jQuery('.offer-contract-type button'),
                        buttonToggle: false,
                        buttonNameValue: jQuery('.name'),
                        buttonNameUnit: false,
                        buttonPriceMonthValue: jQuery('.price .actual .value'),
                        buttonPriceOnceValue: false,
                        buttonActive: jQuery('.offer-contract-type button.is-active'),
                        receiptRowSummary: false,
                        receiptRowMonth: jQuery('.receipt-monthly .contract-type'),
                        receiptRowMonthDiscount: jQuery('.receipt-monthly .contract-basic-discount'),
                        receiptRowOnce: jQuery('.receipt-once .connection-cost'),
                        hasMonthNameValue: true,
                        hasMonthNameUnit: false,
                        hasPriceOnce: true,
                        connectionCost: true
                    },
                    data: {
                        buttonCount: jQuery('.offer-contract-data button').length,
                        button: jQuery('.offer-contract-data button'),
                        buttonToggle: false,
                        buttonNameValue: jQuery('.name .value .actual'),
                        buttonNameUnit: jQuery('.name .unit'),
                        buttonPriceMonthValue: jQuery('.price .value'),
                        buttonPriceOnceValue: false,
                        buttonActive: jQuery('.offer-contract-data button.is-active'),
                        receiptRowSummary: jQuery('.receipt-offer .contract-data'),
                        receiptRowMonth: jQuery('.receipt-monthly .contract-data'),
                        hasMonthNameValue: true,
                        hasMonthNameUnit: true,
                        receiptRowOnce: false
                    },
                    allinone: {
                        buttonCount: jQuery('.offer-contract-allinone button').length,
                        button: jQuery('.offer-contract-allinone button'),
                        buttonToggle: false,
                        buttonNameTitle: jQuery('.allinone-type'),
                        buttonNameUnit: '',
                        buttonNameDataTitle: jQuery('.allinone-data strong'),
                        buttonNameDataUnit: jQuery('.allinone-data small'),
                        buttonNameVoiceTitle: jQuery('.allinone-voice strong'),
                        buttonNameVoiceUnit: jQuery('.allinone-voice small'),
                        buttonPriceMonthValue: jQuery('.price .value'),
                        buttonPriceOnceValue: false,
                        buttonActive: jQuery('.offer-contract-allinone button.is-active'),
                        receiptRowBasicMonth: jQuery('.receipt-monthly .contract-type'),
                        receiptRowDataSummary: jQuery('.receipt-offer .contract-data'),
                        receiptRowDataMonth: jQuery('.receipt-monthly .contract-data'),
                        receiptRowVoiceSummary: jQuery('.receipt-offer .contract-call'),
                        receiptRowVoiceMonth: jQuery('.receipt-monthly .contract-call'),
                        receiptRowPhoneMonth: jQuery('.receipt-monthly .phone-payment'),
                        receiptRowPriceOnceOriginal: jQuery('.receipt-once .phone-with-contract'),
                        receiptRowPriceOnceDiscount: jQuery('.receipt-once .phone-discount-once'),
                        receiptRowPriceOnceAfterDiscount: jQuery('.receipt-once .phone-after-discount'),
                        receiptRowPriceOnceTotalMonthly: jQuery('.receipt-once .phone-total-monthly'),
                        receiptRowPriceOnce: jQuery('.receipt-once .phone-total-once'),
                        receiptRowOnce: jQuery('.receipt-once .phone-total-once'),
                        hasMonthNameValue: true,
                        hasMonthNameUnit: true,
                        receiptRowConnectionCostOnce: jQuery('.receipt-once .connection-cost'),
                        connectionCost: true,
                        phonePayMent: true
                    },
                    allinonevoda: {
                        buttonCount: jQuery('.offer-contract-allinone button').length,
                        button: jQuery('.offer-contract-allinone button'),
                        buttonToggle: false,
                        buttonNameTitle: jQuery('.allinone-type'),
                        buttonNameUnit: '',
                        buttonNameDataTitle: jQuery('.allinone-data strong'),
                        buttonNameDataUnit: jQuery('.allinone-data small'),
                        buttonNameVoiceTitle: jQuery('.allinone-voice strong'),
                        buttonNameVoiceUnit: jQuery('.allinone-voice small'),
                        buttonPriceMonthValue: jQuery('.price .value'),
                        buttonPriceOnceValue: false,
                        buttonActive: jQuery('.offer-contract-allinone button.is-active'),
                        receiptRowBasicMonth: jQuery('.receipt-monthly .contract-type'),
                        receiptRowDataSummary: jQuery('.receipt-offer .contract-data'),
                        receiptRowDataMonth: jQuery('.receipt-monthly .contract-data'),
                        receiptRowVoiceSummary: jQuery('.receipt-offer .contract-call'),
                        receiptRowVoiceMonth: jQuery('.receipt-monthly .contract-call'),
                        receiptRowPhoneMonth: jQuery('.receipt-monthly .phone-payment'),
                        receiptRowPriceOnceOriginal: jQuery('.receipt-once .phone-with-contract'),
                        receiptRowPriceOnceDiscount: jQuery('.receipt-once .phone-discount-once'),
                        receiptRowPriceOnceAfterDiscount: jQuery('.receipt-once .phone-after-discount'),
                        receiptRowPriceOnceTotalMonthly: jQuery('.receipt-once .phone-total-monthly'),
                        receiptRowPriceOnce: jQuery('.receipt-once .phone-total-once'),
                        receiptRowOnce: jQuery('.receipt-once .phone-total-once'),
                        hasMonthNameValue: true,
                        hasMonthNameUnit: true,
                        receiptRowConnectionCostOnce: jQuery('.receipt-once .connection-cost'),
                        connectionCost: true,
                        phonePayMent: true
                    },
                    allinoneGo: {
                        buttonCount: jQuery('.offer-contract-allinone button').length,
                        button: jQuery('.offer-contract-allinone button'),
                        buttonToggle: false,
                        buttonNameTitle: jQuery('.name .actual'),
                        buttonNameUnit: '',
                        buttonNameDataTitle: jQuery('.allinone-data strong'),
                        buttonNameVoiceTitle: jQuery('.allinone-voice strong'),
                        buttonNameSMSTitle: jQuery('.allinone-sms strong'),
                        buttonPriceMonthValue: jQuery('.price .value'),
                        buttonPriceOnceValue: false,
                        buttonActive: jQuery('.offer-contract-allinone button.is-active'),
                        receiptRowBasicMonth: jQuery('.receipt-monthly .contract-type'),
                        receiptRowDataSummary: jQuery('.receipt-offer .contract-data'),
                        receiptRowDataMonth: jQuery('.receipt-monthly .contract-data'),
                        receiptRowVoiceSummary: jQuery('.receipt-offer .contract-call'),
                        receiptRowVoiceMonth: jQuery('.receipt-monthly .contract-call'),
                        receiptRowSMSSummary: jQuery('.receipt-offer .contract-sms'),
                        receiptRowSMSMonth: jQuery('.receipt-monthly .contract-sms'),
                        receiptRowPhoneMonth: jQuery('.receipt-monthly .phone-payment'),
                        receiptRowPriceOnceOriginal: jQuery('.receipt-once .phone-with-contract'),
                        receiptRowPriceOnceDiscount: jQuery('.receipt-once .phone-discount-once'),
                        receiptRowPriceOnceAfterDiscount: jQuery('.receipt-once .phone-after-discount'),
                        receiptRowPriceOnceTotalMonthly: jQuery('.receipt-once .phone-total-monthly'),
                        receiptRowPriceOnce: jQuery('.receipt-once .phone-total-once'),
                        receiptRowOnce: jQuery('.receipt-once .phone-total-once'),
                        hasMonthNameValue: true,
                        hasMonthNameUnit: true,
                        receiptRowConnectionCostOnce: jQuery('.receipt-once .connection-cost'),
                        connectionCost: true,
                        phonePayMent: false,
                        allinone: true
                    },
                    voice: {
                        buttonCount: jQuery('.offer-contract-call button').length,
                        button: jQuery('.offer-contract-call button'),
                        buttonToggle: false,
                        buttonNameValue: jQuery('.name .value .actual'),
                        buttonNameUnit: jQuery('.name .unit'),
                        buttonPriceMonthValue: jQuery('.price .value'),
                        buttonPriceOnceValue: false,
                        buttonActive: jQuery('.offer-contract-call button.is-active'),
                        receiptRowSummary: jQuery('.receipt-offer .contract-call'),
                        receiptRowMonth: jQuery('.receipt-monthly .contract-call'),
                        hasMonthNameValue: true,
                        hasMonthNameUnit: true,
                        receiptRowOnce: false
                    },
                    sms: {
                        buttonCount: jQuery('.offer-contract-sms button').length,
                        button: jQuery('.offer-contract-sms button'),
                        buttonToggle: false,
                        buttonNameValue: jQuery('.name .value'),
                        buttonNameUnit: jQuery('.name .unit'),
                        buttonPriceMonthValue: jQuery('.price .value'),
                        buttonPriceOnceValue: false,
                        buttonActive: jQuery('.offer-contract-sms button.is-active'),
                        receiptRowSummary: jQuery('.receipt-offer .contract-sms'),
                        receiptRowMonth: jQuery('.receipt-monthly .contract-sms'),
                        hasMonthNameValue: true,
                        hasMonthNameUnit: true,
                        receiptRowOnce: false
                    },
                    addon: {
                        buttonCount: jQuery('.offer-contract-addon button').length,
                        button: jQuery('.offer-contract-addon button'),
                        buttonToggle: true,
                        buttonNameValue: jQuery('.name'),
                        buttonNameUnit: false,
                        buttonPriceMonthValue: jQuery('.price .actual .value'),
                        buttonPriceOnceValue: false,
                        buttonActive: jQuery('.offer-contract-addon button.is-active'),
                        buttonActiveContractType: jQuery('.offer-contract-type button.is-active'),
                        receiptRowSummary: false,
                        receiptRowMonth: jQuery('.receipt-monthly .is-contract-add-on'),
                        receiptRowMonthDiscount: jQuery('.receipt-monthly .is-contract-add-on'),
                        hasMonthNameValue: true,
                        hasMonthNameUnit: false,
                        noActiveToggleText: 'Geen extra bundels geselecteerd',
                        receiptRowOnce: false
                    },
                    phonepay: {
                        buttonCount: jQuery('.offer-phone-payment button').length,
                        button: jQuery('.offer-phone-payment button'),
                        buttonToggle: false,
                        buttonNameValue: false,
                        buttonNameUnit: false,
                        buttonPriceMonthValue: jQuery('.price-month .value'),
                        buttonPriceOnceValue: jQuery('.price-once .value'),
                        buttonActive: jQuery('.offer-phone-payment button.is-active'),
                        receiptRowSummary: false,
                        receiptRowMonth: jQuery('.receipt-monthly .phone-payment'),
                        receiptRowPriceOnceOriginal: jQuery('.receipt-once .phone-with-contract'),
                        receiptRowPriceOnceDiscount: jQuery('.receipt-once .phone-discount-once'),
                        receiptRowPriceOnceAfterDiscount: jQuery('.receipt-once .phone-after-discount'),
                        receiptRowPriceOnceTotalMonthly: jQuery('.receipt-once .phone-total-monthly'),
                        receiptRowPriceOnce: jQuery('.receipt-once .phone-total-once'),
                        receiptRowOnce: jQuery('.receipt-once .phone-total-once'),
                        hasMonthNameValue: false,
                        hasMonthNameUnit: false,
                        hasPriceOnce: true,
                        phonePayMent: true
                    },
                    insurance: {
                        buttonCount: jQuery('.offer-phone-insurance button').length,
                        button: jQuery('.offer-phone-insurance button'),
                        buttonToggle: false,
                        buttonNameValue: jQuery('.name'),
                        buttonNameUnit: false,
                        buttonPriceMonthValue: jQuery('.price .actual .value'),
                        buttonPriceOnceValue: false,
                        buttonActive: jQuery('.offer-phone-insurance button.is-active'),
                        receiptRowSummary: false,
                        receiptRowMonth: jQuery('.receipt-monthly .phone-insurance'),
                        hasMonthNameValue: true,
                        hasMonthNameUnit: false,
                        receiptRowOnce: false
                    },
                    simcombination: {
                        buttonCount: jQuery('.offer-phone-sim-combination button').length,
                        button: jQuery('.offer-phone-sim-combination button'),
                        buttonToggle: false,
                        buttonNameValue: false,
                        buttonNameUnit: false,
                        buttonPriceMonthValue: false,
                        buttonPriceOnceValue: false,
                        buttonActive: jQuery('.offer-phone-sim-combination button.is-active'),
                        receiptRowSummary: false,
                        receiptRowMonth: false,
                        hasMonthNameValue: false,
                        hasMonthNameUnit: false,
                        receiptRowOnce: false
                    },
                    simprovider: {
                        buttonCount: jQuery('.offer-phone-sim-provider button').length,
                        button: jQuery('.offer-phone-sim-provider button'),
                        buttonToggle: false,
                        buttonNameValue: false,
                        buttonNameUnit: false,
                        buttonPriceMonthValue: false,
                        buttonPriceOnceValue: false,
                        buttonActive: jQuery('.offer-phone-sim-provider button.is-active'),
                        receiptRowSummary: false,
                        receiptRowMonth: false,
                        hasMonthNameValue: false,
                        hasMonthNameUnit: false,
                        receiptRowOnce: false
                    },
                    phonecolor: {
                        buttonCount: jQuery('.offer-phone-image .phone-colors.is-main button.is-once').length,
                        button: jQuery('.offer-phone-image .phone-colors.is-main button'),
                        buttonToggle: false,
                        buttonNameValue: false,
                        buttonNameUnit: false,
                        buttonPriceMonthValue: false,
                        buttonPriceOnceValue: jQuery('.price-once .value'),
                        buttonActive: jQuery('.offer-phone-image .phone-colors.is-main button.is-active'),
                        receiptRowSummary: false,
                        receiptRowMonth: false,
                        receiptRowPriceOnceOriginal: jQuery('.receipt-once .phone-with-contract'),
                        receiptRowPriceOnceDiscount: jQuery('.receipt-once .phone-discount-once'),
                        receiptRowPriceOnceAfterDiscount: jQuery('.receipt-once .phone-after-discount'),
                        receiptRowPriceOnceTotalMonthly: jQuery('.receipt-once .phone-total-monthly'),
                        receiptRowPriceOnce: jQuery('.receipt-once .phone-total-once'),
                        receiptRowOnce: false,
                        hasMonthNameValue: false,
                        hasMonthNameUnit: false,
                        hasPriceOnce: true,
                        phonePayMent: true,
                        phoneOnly: true
                    }
                };

                //Offer receipt population
                function setReceiptData(bundleType, activeButton) { // jshint ignore:line
                    //Active contract type
                    var activeContractType = jQuery('input.contract_type').val();
                    // Fill iDEAL payment
                    var idealPriceRaw = false;
                    var idealPrice = false;
                    if(activeContractType == 'tele2_basis') { //jshint ignore:line
                        //Titles
                        bundleType.receiptRowDataSummary.find(jQuery('.value')).html(activeButton.find(bundleType.buttonNameDataTitle).text());
                        bundleType.receiptRowVoiceSummary.find(jQuery('.value')).html(activeButton.find(bundleType.buttonNameVoiceTitle).text());
                        bundleType.receiptRowDataSummary.find(jQuery('.unit')).html(activeButton.find(bundleType.buttonNameDataUnit).text());
                        bundleType.receiptRowVoiceSummary.find(jQuery('.unit')).html(activeButton.find(bundleType.buttonNameVoiceUnit).text());

                        bundleType.receiptRowBasicMonth.find(jQuery('.name')).html(activeButton.find(bundleType.buttonNameTitle).text());

                        bundleType.receiptRowDataMonth.find(jQuery('.name .value')).html(activeButton.find(bundleType.buttonNameDataTitle).text());
                        bundleType.receiptRowDataMonth.find(jQuery('.name .unit')).html(activeButton.find(bundleType.buttonNameDataUnit).text());

                        bundleType.receiptRowVoiceMonth.find(jQuery('.name .value')).html(activeButton.find(bundleType.buttonNameVoiceTitle).text());
                        bundleType.receiptRowVoiceMonth.find(jQuery('.name .unit')).html(activeButton.find(bundleType.buttonNameVoiceUnit).text());

                        //Pricing
                        bundleType.receiptRowBasicMonth.find(jQuery('.price')).html('');

                        bundleType.receiptRowDataMonth.find(jQuery('.price .value')).html(parseFloat(activeButton.attr('data-bundle-price-data')).toFixed(2).toString().replace(".", ","));
                        bundleType.receiptRowVoiceMonth.find(jQuery('.price .value')).html(parseFloat(activeButton.attr('data-bundle-price-voice')).toFixed(2).toString().replace(".", ","));

                        //Phone payment
                        //Fill phone payment data
                        if(bundleType.connectionCost === true) {

                            // Get and calculate  data attributes
                            buttonSetPriceConnectionCostOriginalRaw = activeButton.attr(buttonConnectionCostOriginal);
                            buttonSetPriceConnectionCostOriginal = parseFloat(buttonSetPriceConnectionCostOriginalRaw).toFixed(2).toString().replace(".", ",");

                            buttonSetPriceConnectionCostDiscountRaw = activeButton.attr(buttonConnectionCostDiscount);
                            buttonSetPriceConnectionCostDiscount = parseFloat(buttonSetPriceConnectionCostDiscountRaw).toFixed(2).toString().replace(".", ",");

                            buttonSetPriceConnectionCostRaw = (parseFloat(buttonSetPriceConnectionCostOriginalRaw) + parseFloat(buttonSetPriceConnectionCostDiscountRaw)).toFixed(2);
                            buttonSetPriceConnectionCost = buttonSetPriceConnectionCostRaw.toString().replace(".", ",");

                            bundleType.receiptRowConnectionCostOnce.children('.price').children('.value').html(buttonSetPriceConnectionCost);
                            bundleType.receiptRowConnectionCostOnce.attr('data-bundle-price-once', buttonSetPriceConnectionCostRaw);

                        }

                        //Fill phone payment data
                        if(bundleType.phonePayMent === true) {

                            // Get and calculate  data attributes
                            buttonSetPriceOnceOriginalRaw = jQuery('.phonepay-meta .total_cost_original .value').text();
                            buttonSetPriceOnceOriginal = parseFloat(buttonSetPriceOnceOriginalRaw).toFixed(2).toString().replace(".", ",");
                            // Fill phone with contract original price
                            bundleType.receiptRowPriceOnceOriginal.children('.price').children('.value').html(buttonSetPriceOnceOriginal);

                            buttonSetPriceOnceDiscountRaw = jQuery('.phonepay-meta .discount_once .value').text();
                            buttonSetPriceOnceDiscount = parseFloat(buttonSetPriceOnceDiscountRaw).toFixed(2).toString().replace(".", ",");
                            // Fill phone with contract discount price
                            bundleType.receiptRowPriceOnceDiscount.children('.price').children('.value').html(buttonSetPriceOnceDiscount);

                            buttonSetPriceOnceAfterDiscountRaw = parseFloat(jQuery('.phonepay-meta .total_cost_after_discount_once .value').text());
                            buttonSetPriceOnceAfterDiscount = buttonSetPriceOnceAfterDiscountRaw.toFixed(2).toString().replace(".", ",");
                            // Fill phone with contract after discount price
                            bundleType.receiptRowPriceOnceAfterDiscount.children('.price').children('.value').html(buttonSetPriceOnceAfterDiscount);

                            //price month value
                            buttonSetPriceMonthValueRaw = parseFloat(jQuery('.phonepay-meta .discount_monthly .value').text());
                            buttonSetPriceMonthValue = buttonSetPriceMonthValueRaw.toFixed(2).toString().replace(".", ",");
                            bundleType.receiptRowPhoneMonth.children('.price').children('.value').html(buttonSetPriceMonthValue);
                            bundleType.receiptRowPhoneMonth.attr('data-bundle-price', buttonSetPriceMonthValueRaw);

                            // Fill phone with contract total once price
                            buttonSetPriceOnceRaw = parseFloat(jQuery('.phonepay-meta .total_cost_after_discount_monthly .value').text());
                            buttonSetPriceOnce = buttonSetPriceOnceRaw.toFixed(2).toString().replace(".", ",");
                            bundleType.receiptRowPriceOnce.children('.price').children('.value').html(buttonSetPriceOnce);

                            //TODO: check if this is implemented elsewhere already
                            buttonPriceOnceDiscount = parseFloat(jQuery('.phonepay-meta .discount_once .value').text());

                            // Fill iDEAL payment
                            idealPriceRaw = buttonSetPriceOnceRaw + parseFloat(jQuery('.receipt .delivery').attr('data-bundle-price-once')) + parseFloat(jQuery('.receipt .copy-levy').attr('data-bundle-price-once'));
                            idealPrice = idealPriceRaw.toFixed(2).toString().replace(".", ",");
                            jQuery('.receipt .ideal .price .value').html(idealPrice);
                            jQuery('.receipt .ideal').attr('data-bundle-price-once', idealPriceRaw);

                            buttonSetPriceOnceTotalMonthlyRaw = contractDuration * buttonSetPriceMonthValueRaw * -1;
                            buttonSetPriceOnceTotalMonthly = parseFloat(buttonSetPriceOnceTotalMonthlyRaw).toFixed(2).toString().replace(".", ",");
                            // Fill phone with contract total monthly price
                            bundleType.receiptRowPriceOnceTotalMonthly.children('.name').html(contractDuration + ' x ' + buttonSetPriceMonthValue + '/mnd');
                            bundleType.receiptRowPriceOnceTotalMonthly.children('.price').children('.value').html(buttonSetPriceOnceTotalMonthly);


                            // Hide / Show elements
                            // console.log('month value raw');
                            // console.log(buttonSetPriceMonthValueRaw);
                            //
                            // console.log('once value raw:');
                            // console.log(buttonSetPriceOnceRaw);
                            //
                            // console.log('once discount value raw:');
                            // console.log(buttonPriceOnceDiscount);
                            //
                            // console.log('separate phone value raw:');
                            // console.log(buttonSetPriceOnceOriginalRaw);

                            // Only Monthly phone payment
                            if (buttonSetPriceMonthValueRaw !== 0 && (buttonSetPriceOnceRaw === 0 || buttonPriceOnceDiscount === 0)) {
                                if(buttonPriceOnceDiscount === 0){
                                    bundleType.receiptRowPriceOnceDiscount.hide();
                                    bundleType.receiptRowPriceOnceAfterDiscount.hide();
                                } else{
                                    bundleType.receiptRowPriceOnceDiscount.show();
                                    bundleType.receiptRowPriceOnceAfterDiscount.show();
                                }
                                bundleType.receiptRowPriceOnceTotalMonthly.show();
                                bundleType.receiptRowPriceOnce.show();
                            }
                            // Only Direct phone payment
                            else if ((buttonSetPriceMonthValueRaw === 0 || typeof buttonSetPriceMonthValueRaw === 'undefined') && buttonSetPriceOnceRaw !== 0) {
                                if(buttonPriceOnceDiscount === 0){
                                    bundleType.receiptRowPriceOnce.hide();
                                    bundleType.receiptRowPriceOnceDiscount.hide();
                                } else{
                                    bundleType.receiptRowPriceOnce.show();
                                    bundleType.receiptRowPriceOnceDiscount.show();
                                }
                                bundleType.receiptRowPriceOnceAfterDiscount.hide();
                                bundleType.receiptRowPriceOnceTotalMonthly.hide();
                            }
                            // Direct and Monthly phone payment
                            else if (buttonSetPriceMonthValueRaw !== 0 && buttonSetPriceOnceRaw !== 0) {
                                bundleType.receiptRowPriceOnceDiscount.show();
                                bundleType.receiptRowPriceOnceAfterDiscount.show();
                                bundleType.receiptRowPriceOnceTotalMonthly.show();
                                bundleType.receiptRowPriceOnce.show();
                            }

                            //Los toestel
                            if(buttonSetPriceOnceOriginalRaw == undefined || buttonSetPriceOnceOriginalRaw == '') { // jshint ignore:line
                                bundleType.receiptRowPriceOnceOriginal.hide();
                                bundleType.receiptRowPriceOnceDiscount.hide();
                            }
                        }
                    }

                    else if(activeContractType == 'vodafone_basis') { //jshint ignore:line

                        //Titles
                        bundleType.receiptRowDataSummary.find(jQuery('.value')).html(activeButton.find(bundleType.buttonNameDataTitle).text());
                        bundleType.receiptRowVoiceSummary.find(jQuery('.value')).html(activeButton.find(bundleType.buttonNameVoiceTitle).text());
                        bundleType.receiptRowDataSummary.find(jQuery('.unit')).html(activeButton.find(bundleType.buttonNameDataUnit).text());
                        bundleType.receiptRowVoiceSummary.find(jQuery('.unit')).html(activeButton.find(bundleType.buttonNameVoiceUnit).text());

                        bundleType.receiptRowBasicMonth.find(jQuery('.name')).html(activeButton.find(bundleType.buttonNameTitle).text() + ' abonnement');

                        bundleType.receiptRowDataMonth.find(jQuery('.name .value')).html(activeButton.find(bundleType.buttonNameDataTitle).text());
                        bundleType.receiptRowDataMonth.find(jQuery('.name .unit')).html(activeButton.find(bundleType.buttonNameDataUnit).text());

                        bundleType.receiptRowVoiceMonth.find(jQuery('.name .value')).html(activeButton.find(bundleType.buttonNameVoiceTitle).text());
                        bundleType.receiptRowVoiceMonth.find(jQuery('.name .unit')).html(activeButton.find(bundleType.buttonNameVoiceUnit).text());

                        //Pricing
                        bundleType.receiptRowBasicMonth.find(jQuery('.price .value')).html(parseFloat(activeButton.attr('data-bundle-price')).toFixed(2).toString().replace(".", ","));

                        //Phone payment
                        //Fill phone payment data
                        if(bundleType.connectionCost === true) {

                            // Get and calculate  data attributes
                            buttonSetPriceConnectionCostOriginalRaw = activeButton.attr(buttonConnectionCostOriginal);
                            buttonSetPriceConnectionCostOriginal = parseFloat(buttonSetPriceConnectionCostOriginalRaw).toFixed(2).toString().replace(".", ",");

                            buttonSetPriceConnectionCostDiscountRaw = activeButton.attr(buttonConnectionCostDiscount);
                            buttonSetPriceConnectionCostDiscount = parseFloat(buttonSetPriceConnectionCostDiscountRaw).toFixed(2).toString().replace(".", ",");

                            buttonSetPriceConnectionCostRaw = (parseFloat(buttonSetPriceConnectionCostOriginalRaw) + parseFloat(buttonSetPriceConnectionCostDiscountRaw)).toFixed(2);
                            buttonSetPriceConnectionCost = buttonSetPriceConnectionCostRaw.toString().replace(".", ",");

                            bundleType.receiptRowConnectionCostOnce.children('.price').children('.value').html(buttonSetPriceConnectionCost);
                            bundleType.receiptRowConnectionCostOnce.attr('data-bundle-price-once', buttonSetPriceConnectionCostRaw);

                        }

                        //Fill phone payment data
                        if(bundleType.phonePayMent === true) {

                            // Get and calculate  data attributes
                            buttonSetPriceOnceOriginalRaw = jQuery('.phonepay-meta .total_cost_original .value').text();
                            buttonSetPriceOnceOriginal = parseFloat(buttonSetPriceOnceOriginalRaw).toFixed(2).toString().replace(".", ",");
                            // Fill phone with contract original price
                            bundleType.receiptRowPriceOnceOriginal.children('.price').children('.value').html(buttonSetPriceOnceOriginal);

                            buttonSetPriceOnceDiscountRaw = jQuery('.phonepay-meta .discount_once .value').text();
                            buttonSetPriceOnceDiscount = parseFloat(buttonSetPriceOnceDiscountRaw).toFixed(2).toString().replace(".", ",");
                            // Fill phone with contract discount price
                            bundleType.receiptRowPriceOnceDiscount.children('.price').children('.value').html(buttonSetPriceOnceDiscount);

                            buttonSetPriceOnceAfterDiscountRaw = parseFloat(jQuery('.phonepay-meta .total_cost_after_discount_once .value').text());
                            buttonSetPriceOnceAfterDiscount = buttonSetPriceOnceAfterDiscountRaw.toFixed(2).toString().replace(".", ",");
                            // Fill phone with contract after discount price
                            bundleType.receiptRowPriceOnceAfterDiscount.children('.price').children('.value').html(buttonSetPriceOnceAfterDiscount);

                            //price month value
                            buttonSetPriceMonthValueRaw = parseFloat(jQuery('.phonepay-meta .discount_monthly .value').text());
                            buttonSetPriceMonthValue = buttonSetPriceMonthValueRaw.toFixed(2).toString().replace(".", ",");
                            bundleType.receiptRowPhoneMonth.children('.price').children('.value').html(buttonSetPriceMonthValue);
                            bundleType.receiptRowPhoneMonth.attr('data-bundle-price', buttonSetPriceMonthValueRaw);

                            // Fill phone with contract total once price
                            buttonSetPriceOnceRaw = parseFloat(jQuery('.phonepay-meta .total_cost_after_discount_monthly .value').text());
                            buttonSetPriceOnce = buttonSetPriceOnceRaw.toFixed(2).toString().replace(".", ",");
                            bundleType.receiptRowPriceOnce.children('.price').children('.value').html(buttonSetPriceOnce);

                            //TODO: check if this is implemented elsewhere already
                            buttonPriceOnceDiscount = parseFloat(jQuery('.phonepay-meta .discount_once .value').text());

                            // Fill iDEAL payment
                            idealPriceRaw = buttonSetPriceOnceRaw + parseFloat(jQuery('.receipt .delivery').attr('data-bundle-price-once')) + parseFloat(jQuery('.receipt .copy-levy').attr('data-bundle-price-once'));
                            idealPrice = idealPriceRaw.toFixed(2).toString().replace(".", ",");
                            jQuery('.receipt .ideal .price .value').html(idealPrice);
                            jQuery('.receipt .ideal').attr('data-bundle-price-once', idealPriceRaw);

                            buttonSetPriceOnceTotalMonthlyRaw = contractDuration * buttonSetPriceMonthValueRaw * -1;
                            buttonSetPriceOnceTotalMonthly = parseFloat(buttonSetPriceOnceTotalMonthlyRaw).toFixed(2).toString().replace(".", ",");
                            // Fill phone with contract total monthly price
                            bundleType.receiptRowPriceOnceTotalMonthly.children('.name').html(contractDuration + ' x ' + buttonSetPriceMonthValue + '/mnd');
                            bundleType.receiptRowPriceOnceTotalMonthly.children('.price').children('.value').html(buttonSetPriceOnceTotalMonthly);


                            // Hide / Show elements
                            // console.log('month value raw');
                            // console.log(buttonSetPriceMonthValueRaw);
                            //
                            // console.log('once value raw:');
                            // console.log(buttonSetPriceOnceRaw);
                            //
                            // console.log('once discount value raw:');
                            // console.log(buttonPriceOnceDiscount);
                            //
                            // console.log('separate phone value raw:');
                            // console.log(buttonSetPriceOnceOriginalRaw);

                            // Only Monthly phone payment
                            if (buttonSetPriceMonthValueRaw !== 0 && (buttonSetPriceOnceRaw === 0 || buttonPriceOnceDiscount === 0)) {
                                if(buttonPriceOnceDiscount === 0){
                                    bundleType.receiptRowPriceOnceDiscount.hide();
                                    bundleType.receiptRowPriceOnceAfterDiscount.hide();
                                } else{
                                    bundleType.receiptRowPriceOnceDiscount.show();
                                    bundleType.receiptRowPriceOnceAfterDiscount.show();
                                }
                                bundleType.receiptRowPriceOnceTotalMonthly.show();
                                bundleType.receiptRowPriceOnce.show();
                            }
                            // Only Direct phone payment
                            else if ((buttonSetPriceMonthValueRaw === 0 || typeof buttonSetPriceMonthValueRaw === 'undefined') && buttonSetPriceOnceRaw !== 0) {
                                if(buttonPriceOnceDiscount === 0){
                                    bundleType.receiptRowPriceOnce.hide();
                                    bundleType.receiptRowPriceOnceDiscount.hide();
                                } else{
                                    bundleType.receiptRowPriceOnce.show();
                                    bundleType.receiptRowPriceOnceDiscount.show();
                                }
                                bundleType.receiptRowPriceOnceAfterDiscount.hide();
                                bundleType.receiptRowPriceOnceTotalMonthly.hide();
                            }
                            // Direct and Monthly phone payment
                            else if (buttonSetPriceMonthValueRaw !== 0 && buttonSetPriceOnceRaw !== 0) {
                                bundleType.receiptRowPriceOnceDiscount.show();
                                bundleType.receiptRowPriceOnceAfterDiscount.show();
                                bundleType.receiptRowPriceOnceTotalMonthly.show();
                                bundleType.receiptRowPriceOnce.show();
                            }

                            //Los toestel
                            if(buttonSetPriceOnceOriginalRaw == undefined || buttonSetPriceOnceOriginalRaw == '') { // jshint ignore:line
                                bundleType.receiptRowPriceOnceOriginal.hide();
                                bundleType.receiptRowPriceOnceDiscount.hide();
                            }
                        }
                    }

                    else if(activeContractType === 'tmobile_go' && bundleType.allinone === true){
                        bundleType.receiptRowDataSummary.html(activeButton.find(bundleType.buttonNameDataTitle).text());
                        bundleType.receiptRowVoiceSummary.html(activeButton.find(bundleType.buttonNameVoiceTitle).text());
                        bundleType.receiptRowSMSSummary.html(activeButton.find(bundleType.buttonNameSMSTitle).text());

                        bundleType.receiptRowBasicMonth.find(jQuery('.name')).html(activeButton.find(bundleType.buttonNameTitle).text());
                        bundleType.receiptRowDataMonth.find(jQuery('.name')).html('- '+ activeButton.find(bundleType.buttonNameDataTitle).text());
                        bundleType.receiptRowVoiceMonth.find(jQuery('.name')).html('- '+ activeButton.find(bundleType.buttonNameVoiceTitle).text());
                        bundleType.receiptRowSMSMonth.find(jQuery('.name')).html('- '+ activeButton.find(bundleType.buttonNameSMSTitle).text());

                        //Pricing
                        bundleType.receiptRowBasicMonth.find(jQuery('.price .value')).html(parseFloat(activeButton.attr('data-bundle-price')).toFixed(2).toString().replace(".", ","));
                    }

                    else{

                        //Get active bundle selection data
                        //Name and unit
                        if(bundleType.buttonNameValue !== false) {
                            buttonNameValue = activeButton.find(bundleType.buttonNameValue).text();
                        }
                        if(bundleType.buttonNameUnit !== false) {
                            buttonNameUnit = activeButton.find(bundleType.buttonNameUnit).text();
                        }

                        //Pricing
                        buttonSetPriceMonthValue = activeButton.find(bundleType.buttonPriceMonthValue).text();
                        buttonSetPriceOnceValue = activeButton.find(bundleType.buttonPriceOnceValue).text();
                        buttonSetPriceMonthValueRaw = activeButton.attr(buttonPriceMonthValueRaw);
                        buttonSetPriceMonthValue = parseFloat(buttonSetPriceMonthValueRaw).toFixed(2).toString().replace(".", ",");
                        buttonSetPriceMonthValueDiscountRaw = activeButton.attr(buttonPriceMonthValueDiscountRaw);
                        buttonSetPriceMonthValueDiscount = parseFloat(buttonSetPriceMonthValueDiscountRaw).toFixed(2).toString().replace(".", ",");

                        //Key
                        buttonSetKey = activeButton.attr(buttonKey);

                        // Run Button Switch logic

                        //Fill summary data
                        if (bundleType.receiptRowSummary !== false) {
                            bundleType.receiptRowSummary.children('.value').html(buttonNameValue);
                            bundleType.receiptRowSummary.children('.unit').html(buttonNameUnit);
                        }

                        //Fill monthly data
                        if (bundleType.receiptRowMonth !== false) {
                            //Fill bundle name value
                            if (bundleType.hasMonthNameValue !== false) {
                                bundleType.receiptRowMonth.children('.name').children('.value').html(buttonNameValue);
                            }
                            //Fill bundle name unit
                            if (bundleType.hasMonthNameUnit !== false) {
                                bundleType.receiptRowMonth.children('.name').children('.unit').html(buttonNameUnit);
                            }
                            //Fill bundle discount value
                            if (bundleType.receiptRowMonthDiscount != null){
                                bundleType.receiptRowMonthDiscount.children('.price').children('.value').html(buttonSetPriceMonthValueDiscount);
                            }
                            //Fill bundle price value
                            bundleType.receiptRowMonth.children('.price').children('.value').html(buttonSetPriceMonthValue);
                        }

                        //Fill once data
                        if(bundleType.hasPriceOnce === true){

                            //Fill phone payment data
                            if(bundleType.connectionCost === true) {

                                // Get and calculate data attributes
                                buttonSetPriceConnectionCostOriginalRaw = activeButton.attr(buttonConnectionCostOriginal);
                                buttonSetPriceConnectionCostOriginal = parseFloat(buttonSetPriceConnectionCostOriginalRaw).toFixed(2).toString().replace(".", ",");

                                buttonSetPriceConnectionCostDiscountRaw = activeButton.attr(buttonConnectionCostDiscount);
                                buttonSetPriceConnectionCostDiscount = parseFloat(buttonSetPriceConnectionCostDiscountRaw).toFixed(2).toString().replace(".", ",");

                                buttonSetPriceConnectionCostRaw = (parseFloat(buttonSetPriceConnectionCostOriginalRaw) + parseFloat(buttonSetPriceConnectionCostDiscountRaw)).toFixed(2);
                                buttonSetPriceConnectionCost = buttonSetPriceConnectionCostRaw.toString().replace(".", ",");

                                bundleType.receiptRowOnce.children('.price').children('.value').html(buttonSetPriceConnectionCost);
                                bundleType.receiptRowOnce.attr('data-bundle-price-once', buttonSetPriceConnectionCostRaw);

                            }

                            //Fill phone payment data
                            if(bundleType.phonePayMent === true) {

                                // Get and calculate  data attributes
                                buttonSetPriceOnceOriginalRaw = activeButton.attr(buttonPriceOnceOriginalRaw);
                                buttonSetPriceOnceOriginal = parseFloat(buttonSetPriceOnceOriginalRaw).toFixed(2).toString().replace(".", ",");
                                // Fill phone with contract original price
                                bundleType.receiptRowPriceOnceOriginal.children('.price').children('.value').html(buttonSetPriceOnceOriginal);

                                buttonSetPriceOnceDiscountRaw = activeButton.attr(buttonPriceOnceDiscountRaw);
                                buttonSetPriceOnceDiscount = parseFloat(buttonSetPriceOnceDiscountRaw).toFixed(2).toString().replace(".", ",");
                                // Fill phone with contract discount price
                                bundleType.receiptRowPriceOnceDiscount.children('.price').children('.value').html(buttonSetPriceOnceDiscount);

                                buttonSetPriceOnceAfterDiscountRaw = parseFloat(buttonSetPriceOnceOriginalRaw) + parseFloat(buttonSetPriceOnceDiscountRaw);
                                buttonSetPriceOnceAfterDiscount = parseFloat(buttonSetPriceOnceAfterDiscountRaw).toFixed(2).toString().replace(".", ",");
                                // Fill phone with contract after discount price
                                bundleType.receiptRowPriceOnceAfterDiscount.children('.price').children('.value').html(buttonSetPriceOnceAfterDiscount);

                                // Fill iDEAL payment
                                idealPriceRaw = (buttonSetPriceOnceAfterDiscountRaw + parseFloat(jQuery('.receipt .delivery').attr('data-bundle-price-once')) + parseFloat(jQuery('.receipt .copy-levy').attr('data-bundle-price-once'))).toFixed(2);
                                idealPrice = idealPriceRaw.toString().replace(".", ",");
                                jQuery('.receipt .ideal .price .value').html(idealPrice);
                                jQuery('.receipt .ideal').attr('data-bundle-price-once', idealPriceRaw);

                                buttonSetPriceOnceTotalMonthlyRaw = contractDuration * buttonSetPriceMonthValueRaw * -1;
                                buttonSetPriceOnceTotalMonthly = parseFloat(buttonSetPriceOnceTotalMonthlyRaw).toFixed(2).toString().replace(".", ",");
                                // Fill phone with contract total monthly price
                                bundleType.receiptRowPriceOnceTotalMonthly.children('.name').html(contractDuration + ' x ' + buttonSetPriceMonthValue + '/mnd');
                                bundleType.receiptRowPriceOnceTotalMonthly.children('.price').children('.value').html(buttonSetPriceOnceTotalMonthly);


                                buttonSetPriceOnceRaw = activeButton.attr(buttonPriceOnceRaw);
                                buttonPriceOnceDiscount = activeButton.attr(buttonPriceOnceDiscountRaw);
                                buttonSetPriceOnce = parseFloat(buttonSetPriceOnceRaw).toFixed(2).toString().replace(".", ",");
                                // Fill phone with contract total once price
                                bundleType.receiptRowPriceOnce.children('.price').children('.value').html(buttonSetPriceOnce);

                                // Hide / Show elements
                                // Only Monthly phone payment
                                if (buttonSetPriceMonthValueRaw !== '0' && (buttonSetPriceOnceRaw === '0' || buttonPriceOnceDiscount === '0')) {
                                    bundleType.receiptRowPriceOnceDiscount.hide();
                                    bundleType.receiptRowPriceOnceAfterDiscount.hide();
                                    bundleType.receiptRowPriceOnceTotalMonthly.show();
                                }
                                // Only Direct phone payment
                                else if ((buttonSetPriceMonthValueRaw === '0' || typeof buttonSetPriceMonthValueRaw === 'undefined') && buttonSetPriceOnceRaw !== '0') {
                                    bundleType.receiptRowPriceOnceDiscount.show();
                                    bundleType.receiptRowPriceOnceAfterDiscount.hide();
                                    bundleType.receiptRowPriceOnceTotalMonthly.hide();
                                }
                                // Direct and Monthly phone payment
                                else if (buttonSetPriceMonthValueRaw !== '0' && buttonSetPriceOnceRaw !== '0') {
                                    bundleType.receiptRowPriceOnceDiscount.show();
                                    bundleType.receiptRowPriceOnceAfterDiscount.show();
                                    bundleType.receiptRowPriceOnceTotalMonthly.show();
                                }

                                //Los toestel
                                if(buttonSetPriceOnceOriginalRaw == undefined || buttonSetPriceOnceOriginalRaw == '') { // jshint ignore:line
                                    bundleType.receiptRowPriceOnceOriginal.hide();
                                    bundleType.receiptRowPriceOnceDiscount.hide();
                                }
                            }
                        }

                        //Fill addon bundle data
                        //TODO: if applicable, calculate individual addon discounts

                        if(bundleType.buttonToggle !== false) {
                            noActiveToggleText = bundleType.noActiveToggleText;
                            noActiveToggleHTML = jQuery('<div class="is-row contract-add-on add-on-empty">' + noActiveToggleText + '</div>');

                            //Add active / remove inactive addons
                            if(activeButton.length > 0) {
                                jQuery.each(activeButton, function (key, value) {
                                    if (jQuery(this).hasClass('is-active')) {
                                        activeToggleHTML = jQuery('<div class="is-row contract-add-on add-on-' + jQuery(this).attr(buttonKey) + '"><div class="name">' + jQuery(this).find(bundleType.buttonNameValue).text() + '</div><div class="price"><span class="unit">&euro;</span> <span class="value">' + jQuery(this).find(bundleType.buttonPriceMonthValue).text() + '</span></div></div>');

                                        //console.log(jQuery(this));
                                        if(bundleType.receiptRowMonth.find('.contract-add-on').length){
                                            activeToggleHTML.insertAfter(bundleType.receiptRowMonth.find('.contract-add-on').last());
                                        } else{
                                            activeToggleHTML.appendTo(bundleType.receiptRowMonth);
                                        }

                                        // TODO: This highlight code is added here because for some reason HTML has not been added when executing this code in highLightRow() function > refactor
                                        var targetAddonRow = jQuery('.contract-add-on.add-on-' + jQuery(this).attr(buttonKey));
                                        targetAddonRow.addClass("is-highlight").delay(750).queue(function () {
                                            targetAddonRow.removeClass("is-highlight").dequeue();
                                        });

                                    } else{
                                        bundleType.receiptRowMonth.find('.add-on-' + jQuery(this).attr(buttonKey) + '').remove();
                                    }
                                });
                            }

                            //Check if there are any active addons
                            if (!bundleType.button.hasClass('is-active')) {
                                noActiveToggleHTML.appendTo(bundleType.receiptRowMonth);
                                bundleType.receiptRowMonth.find('.contract-addon-combi-discount').remove();
                            } else {
                                bundleType.receiptRowMonth.find('.add-on-empty').remove();
                            }

                            // Check active bundles
                            if(bundleType.buttonActiveContractType.attr('data-bundle-key') && bundleType.buttonActiveContractType.attr('data-bundle-key').toLowerCase().indexOf('dp') >= 0) {
                                // Check active bundles
                                if(bundleType.button.hasClass('is-active') === false){
                                    //No Addons
                                    activeContractTypeKey = 'DP001';
                                } else{
                                    //Active addon available
                                    var activeAddonBundles = [];
                                    jQuery.each(bundleType.button,function(key, value){
                                        if(jQuery(this).hasClass('is-active')){
                                            activeAddonBundles.push(jQuery(this).attr(buttonKey));
                                        }
                                    });

                                    // Decision matrix to match addon-bundle combination to DP code
                                    // TODO: Check if this can be set dynamically
                                    var addonVisualVoicemail = 'VVMI2';
                                    var addonDeezerPremium = 'DEEZ3_U';
                                    var addonInternetEurope = 'TMING';
                                    var addonVoiceEurope = 'MIN33';

                                    // if( activeAddonBundles.indexOf(addonVisualVoicemail) > -1 &&
                                    //     activeAddonBundles.indexOf(addonDeezerPremium) > -1 &&
                                    //     activeAddonBundles.indexOf(addonInternetEurope) > -1 &&
                                    //     activeAddonBundles.indexOf(addonVoiceEurope) > -1){
                                    //     activeContractTypeKey = 'DP007';
                                    // }
                                    if(activeAddonBundles.indexOf(addonInternetEurope) > -1 &&
                                        activeAddonBundles.indexOf(addonVoiceEurope) > -1){
                                        activeContractTypeKey = 'DP008'; // € 5 discount
                                    }
                                    // else if(activeAddonBundles.indexOf(addonDeezerPremium) > -1){
                                    //     activeContractTypeKey = 'DP002'; // € 4 discount
                                    // }
                                    else if(activeAddonBundles.indexOf(addonVisualVoicemail) > -1){
                                        activeContractTypeKey = 'DP004'; // € 1.04 discount
                                    }
                                    else if(activeAddonBundles.indexOf(addonVoiceEurope) > -1){
                                        activeContractTypeKey = 'DP009'; // € 0 discount
                                    }
                                    else if(activeAddonBundles.indexOf(addonInternetEurope) > -1){
                                        activeContractTypeKey = 'DP003'; // € 0 discount
                                    }
                                    else{
                                        activeContractTypeKey = 'DP001'; // € 0 discount
                                    }

                                    //Set bundle key attribute
                                    bundleType.buttonActiveContractType.attr('data-bundle-key', activeContractTypeKey);
                                    //Get discount value from contractType button
                                    activeContractTypeDiscountRaw = bundleType.buttonActiveContractType.attr('data-bundle-'+(activeContractTypeKey));
                                    activeContractTypeDiscount = parseFloat(activeContractTypeDiscountRaw).toFixed(2).toString().replace(".", ",");
                                    hasAddonDiscountHTML = jQuery('<div data-bundle-price="'+activeContractTypeDiscountRaw+'" class="is-row contract-addon-combi-discount is-monthly is-active is-discount"><div class="name">korting voordeelbundels</div><div class="price"><span class="unit">&euro;</span> <span class="value">'+activeContractTypeDiscount+'</span></div></div>');

                                    //Show discount row
                                    if(!bundleType.receiptRowMonth.find('.contract-addon-combi-discount').length && activeContractTypeDiscountRaw !== '0'){
                                        //New discount
                                        hasAddonDiscountHTML.appendTo(bundleType.receiptRowMonthDiscount);
                                    } else if(activeContractTypeDiscountRaw === '0') {
                                        //No discount
                                        bundleType.receiptRowMonth.find('.contract-addon-combi-discount').remove();
                                    } else{
                                        //Changed discount
                                        bundleType.receiptRowMonth.find('.contract-addon-combi-discount .price .value').html(activeContractTypeDiscount);
                                        bundleType.receiptRowMonth.find('.contract-addon-combi-discount').attr('data-bundle-price', activeContractTypeDiscountRaw);
                                    }
                                }
                            }

                            //TODO: Check if there is any discount on individual addons (check if applicable)

                        }
                    }

                }

                function setTele2CombinedBundle(bundleKeyData, bundleKeyVoice){ // jshint ignore:line
                    var combinedBundles = jQuery('.offer-contract-allinone.is-tele2 button');

                    combinedBundles.each(function() {
                        var targetDataBundle = jQuery(this).attr('data-bundle-key-data');
                        var targetVoiceBundle = jQuery(this).attr('data-bundle-key-voice');
                        if (bundleKeyData === targetDataBundle && bundleKeyVoice === targetVoiceBundle) {
                            jQuery(this).trigger('click');
                        }

                        if(bundleKeyVoice === targetVoiceBundle){
                          var activeDiscount = jQuery(this).find('.extra-discount .euro').text();
                          var splitDataButton = jQuery('.offer-contract-bundle.is-data button[data-bundle-key-data="'+targetDataBundle+'"]');
                          splitDataButton.find('.extra-discount .euro').text(activeDiscount);
                        }
                    });
                }

                var tele2Databuttons = jQuery('.offer-contract-bundle.is-data button');
                var tele2Callbuttons = jQuery('.offer-contract-bundle.is-call button');
                tele2Databuttons.click(function() {
                    tele2Databuttons.removeClass('is-active');
                    jQuery(this).addClass('is-active');

                    var activeDataKey = jQuery(this).attr('data-bundle-key-data');
                    var activeVoiceKey = jQuery('.offer-contract-bundle.is-call button.is-active').attr('data-bundle-key-voice');

                    setTele2CombinedBundle(activeDataKey,activeVoiceKey);
                });

                tele2Callbuttons.click(function() {
                    tele2Callbuttons.removeClass('is-active');
                    jQuery(this).addClass('is-active');

                    var activeDataKey = jQuery('.offer-contract-bundle.is-data button.is-active').attr('data-bundle-key-data');
                    var activeVoiceKey = jQuery(this).attr('data-bundle-key-voice');

                    setTele2CombinedBundle(activeDataKey,activeVoiceKey);
                });

                //initial tele2 button sync (atm for discount calculatoin only)
                setTele2CombinedBundle(jQuery('.offer-contract-bundle.is-data button.is-active').attr('data-bundle-key-data'),jQuery('.offer-contract-bundle.is-call button.is-active').attr('data-bundle-key-voice'));

                //Get active Ben configuration ID logic
                function getActiveBenConfID(bundleKeyData, bundleKeyDataSpeed, bundleKeyVoice, bundleKeyPhonePay, activeDataSpeed, bundleKeyAddonSMS, hasExtraSMS){ // jshint ignore:line
                    var confID = '0';
                    //TODO: merge .each by checking for activeDataSpeed in .each loop
                    // console.log(bundleKeyData);
                    // console.log(bundleKeyDataSpeed);
                    // console.log(bundleKeyVoice);
                    // console.log(bundleKeyPhonePay);
                    // console.log(activeDataSpeed);
                    // console.log(bundleKeyAddonSMS);
                    //Sim with Phone
                    if(typeof bundleKeyPhonePay === 'undefined') {
                        if (activeDataSpeed === '3G') {
                            //3G
                            if (hasExtraSMS) {
                                //3G
                                jQuery.each(benBundleCombinations, function (i, val) {
                                    if (typeof val[bundleKeyData] !== 'undefined' && typeof val[bundleKeyDataSpeed] === 'undefined' && typeof val[bundleKeyVoice] !== 'undefined' && typeof val[bundleKeyAddonSMS] !== 'undefined') {
                                        confID = i;
                                        //console.log('route 1' + ' - ' + confID);
                                        return false; //break
                                    }
                                });
                            } else {
                                jQuery.each(benBundleCombinations, function (i, val) {
                                    if (typeof val[bundleKeyData] !== 'undefined' && typeof val[bundleKeyDataSpeed] === 'undefined' && typeof val[bundleKeyVoice] !== 'undefined' && typeof val[bundleKeyAddonSMS] === 'undefined') {
                                        confID = i;
                                        //console.log('route 2' + ' - ' + confID);
                                        return false; //break
                                    }
                                });
                            }
                        } else {
                            if (hasExtraSMS) {
                                jQuery.each(benBundleCombinations, function (i, val) {
                                    if (typeof val[bundleKeyData] !== 'undefined' && typeof val[bundleKeyDataSpeed] !== 'undefined' && typeof val[bundleKeyVoice] !== 'undefined' && typeof val[bundleKeyAddonSMS] !== 'undefined') {
                                        confID = i;
                                        //console.log('route 3' + ' - ' + confID);
                                        return false; //break
                                    }
                                });
                            } else{
                                jQuery.each(benBundleCombinations, function (i, val) {
                                    if (typeof val[bundleKeyData] !== 'undefined' && typeof val[bundleKeyDataSpeed] !== 'undefined' && typeof val[bundleKeyVoice] !== 'undefined' && typeof val[bundleKeyAddonSMS] === 'undefined') {
                                        confID = i;
                                        //console.log('route 4' + ' - ' + confID);
                                        return false; //break
                                    }
                                });
                            }
                        }
                    }
                    // Contract
                    else{
                        if(activeDataSpeed === '3G') {
                            if (hasExtraSMS) {
                                jQuery.each(benBundleCombinations, function (i, val) {
                                    if (typeof val[bundleKeyData] !== 'undefined' && typeof val[bundleKeyDataSpeed] === 'undefined' && typeof val[bundleKeyVoice] !== 'undefined' && typeof val[bundleKeyPhonePay] !== 'undefined' && typeof val[bundleKeyAddonSMS] !== 'undefined') {
                                        confID = i;
                                        //console.log('route 5' + ' - ' + confID);
                                        return false; //break
                                    }
                                });
                            } else{
                                jQuery.each(benBundleCombinations, function (i, val) {
                                    if (typeof val[bundleKeyData] !== 'undefined' && typeof val[bundleKeyDataSpeed] === 'undefined' && typeof val[bundleKeyVoice] !== 'undefined' && typeof val[bundleKeyPhonePay] !== 'undefined' && typeof val[bundleKeyAddonSMS] === 'undefined') {
                                        confID = i;
                                        //console.log('route 6' + ' - ' + confID);
                                        return false; //break
                                    }
                                });
                            }
                        } else{
                            if (hasExtraSMS) {
                                jQuery.each( benBundleCombinations, function(i, val){
                                    if(typeof val[bundleKeyData] !== 'undefined' && typeof val[bundleKeyDataSpeed] !== 'undefined' && typeof val[bundleKeyVoice] !== 'undefined' && typeof val[bundleKeyPhonePay] !== 'undefined' && typeof val[bundleKeyAddonSMS] !== 'undefined'){
                                        confID = i;
                                        //console.log('route 7' + ' - ' + confID);
                                        return false; //break
                                    }
                                });
                            } else{
                                jQuery.each( benBundleCombinations, function(i, val){
                                    if(typeof val[bundleKeyData] !== 'undefined' && typeof val[bundleKeyDataSpeed] !== 'undefined' && typeof val[bundleKeyVoice] !== 'undefined' && typeof val[bundleKeyPhonePay] !== 'undefined' && typeof val[bundleKeyAddonSMS] === 'undefined'){
                                        confID = i;
                                        //console.log('route 8' + ' - ' + confID);
                                        return false; //break
                                    }
                                });
                            }
                        }
                    }

                    return confID;

                }

                function hideBenBlocks() { // jshint ignore:line
                    //TODO: add support for 1 month by only checking DAT_0000 en MIN_0100
                    //TODO: after, use Thijs's decision matrix for bundle compatibility
                    if(jQuery('input.contract_provider').val() === 'ben') {
                        //Temp fix for Ben incompatible bundles
                        var activeDuration = jQuery('.offer-contract-duration button.is-active').attr('data-contract-duration');
                        var addonEmpty = jQuery('.offer-contract-addon .button-wrapper');
                        var addonDurationSnippet = false;
                        if(activeDuration === '24'){
                            addonDurationSnippet = '024';
                        } else if(activeDuration === '12'){
                            addonDurationSnippet = '012';
                        } else if(activeDuration === '1'){
                            addonDurationSnippet = '001';
                        } else{}
                        var addonExtraSMS = false;
                        var addonDataSpeed = false;
                        if(addonDurationSnippet){
                            addonExtraSMS = jQuery('.offer-contract-addon button[data-bundle-key="BENB2B'+addonDurationSnippet+'SMS_1000"]');
                            addonDataSpeed = jQuery('.offer-contract-addon button[data-bundle-key="BENB2B'+addonDurationSnippet+'4GADDON"]');
                        }
                        var addon4GFree = addonDataSpeed.attr('data-bundle-price') === "0";
                        var activeDataKey = jQuery('.offer-contract-data button.is-active').attr('data-bundle-key');
                        var activeVoiceKey = jQuery('.offer-contract-call button.is-active').attr('data-bundle-key');

                        //Extra SMS bundle
                        //TODO: make completely dynamic
                        if(activeVoiceKey === 'BENB2B'+addonDurationSnippet+'MIN_ONBE'){
                            if(!addonEmpty.find('.no-extra-bundles').length && !jQuery('.offer-contract-addon button:visible').length)  {
                                addonEmpty.append('<p class="no-extra-bundles">Geen beschikbare bundels</p>');
                            }
                            if(addonExtraSMS.hasClass('is-active')){
                                addonExtraSMS.trigger("click");
                            }

                            addonExtraSMS.addClass('is-hidden');
                        } else if(activeDataKey === 'BENB2B'+addonDurationSnippet+'DAT_0000'){
                            addonEmpty.find('.no-extra-bundles').remove();

                            // OLD LOGIC USED WHEN EXTRA-SMS BUNDLE PRICES WHERE DIFFERENT BETWEEN BASIC BUNDLE COMBINATIONS
                            // addonExtraSMS.attr('data-bundle-original-price', '2');
                            // addonExtraSMS.attr('data-bundle-discount-price', '0.5');
                            // addonExtraSMS.attr('data-bundle-price', '1.5');
                            // addonExtraSMS.find('.price .actual .value').html('1,50');

                            addonExtraSMS.removeClass('is-hidden');

                            if(addonExtraSMS.hasClass('is-active')){
                                addonExtraSMS.trigger("click");
                            }

                        } else{
                            addonEmpty.find('.no-extra-bundles').remove();

                            // OLD LOGIC USED WHEN EXTRA-SMS BUNDLE PRICES WHERE DIFFERENT BETWEEN BASIC BUNDLE COMBINATIONS
                            // addonExtraSMS.attr('data-bundle-original-price', '2');
                            // addonExtraSMS.attr('data-bundle-discount-price', '2');
                            // addonExtraSMS.attr('data-bundle-price', '0');
                            // addonExtraSMS.find('.price .actual .value').html('0,00');

                            // OLD LOGIC USED BECAUSE THIS BUNDLE WAS FREE AND MANDATORY
                            // if(!addonExtraSMS.hasClass('is-active')){
                            //     addonExtraSMS.trigger("click");
                            // }

                            addonExtraSMS.removeClass('is-hidden');
                        }

                        if(activeDataKey === 'BENB2B'+addonDurationSnippet+'DAT_0000'){
                            if(addonDataSpeed.hasClass('is-active')){
                                addonDataSpeed.trigger("click");
                            }
                            addonDataSpeed.attr('disabled', true);
                            addonDataSpeed.attr('title', "Deze bundel kan alleen worden geselecteerd in combinatie met een internet bundel.");
                        } else if(addonDataSpeed.attr('data-bundle-state') === 'disabled'){
                            addonDataSpeed.attr('disabled', true);
                            addonDataSpeed.attr('title', "");
                        } else if(addon4GFree || activeDataKey === 'BENB2B'+addonDurationSnippet+'DAT_1500' || activeDataKey === 'BENB2B'+addonDurationSnippet+'DAT_2500'){
                            addonDataSpeed.attr('disabled', false);
                            if(!addonDataSpeed.hasClass('is-active')){
                                addonDataSpeed.trigger("click");
                            }
                            addonDataSpeed.attr('disabled', true);
                            addonDataSpeed.attr('title', "");
                        } else {
                            addonDataSpeed.attr('disabled', false);
                            addonDataSpeed.attr('title', "");
                        }

                        // only allow unlimited calling in combination with 0MB data
                        if(activeDataKey === 'BENB2B'+addonDurationSnippet+'DAT_0000') {
                            var callUnlimitedBlock = jQuery('.offer-contract-call button[data-bundle-key="BENB2B' + addonDurationSnippet + 'MIN_ONBE"]');
                            if (!callUnlimitedBlock.hasClass('is-active')) {
                                callUnlimitedBlock.trigger("click");
                            }
                            jQuery('.offer-contract-call').find('button').each(function () {
                                if (jQuery(this).attr('data-bundle-key') !== callUnlimitedBlock.attr('data-bundle-key')) {
                                    jQuery(this).attr('disabled', true);
                                    jQuery(this).attr('title', "Deze bundel kan alleen worden geselecteerd in combinatie met een internet bundel.");
                                }
                            });
                        } else {
                            jQuery('.offer-contract-call').find('button').each(function () {
                                jQuery(this).attr('disabled', false);
                                jQuery(this).attr('title', "");
                            });
                        }
                    }
                }

                function switchVodafonePaymentbundles() { // jshint ignore:line
                    var activeBasicBundle = jQuery('.offer-contract-allinone.is-vodafone button.is-active').attr('data-bundle-key');
                    var activephonePaymentBundle = jQuery('.offer-phone-payment button.is-active');
                    var phonePaymentBundles = jQuery('.offer-phone-payment button');
                    var setDefaultPhonepayment = true;

                    jQuery.each(phonePaymentBundles, function( index, value ) {
                        if(activeBasicBundle !== jQuery(this).attr('data-bundle-type')){
                            jQuery(this).removeClass('is-active');
                            jQuery(this).addClass('is-hidden');
                            jQuery(this).attr('disabled', true);
                        } else{
                            jQuery(this).attr('disabled', false);
                            jQuery(this).removeClass('is-hidden');
                            if(activephonePaymentBundle.attr('data-bundle-key') === jQuery(this).attr('data-bundle-key')){
                                jQuery(this).addClass('is-active');
                                setDefaultPhonepayment = false;
                            }
                        }
                    });

                    if(setDefaultPhonepayment){
                        applicablePhonePaymentbundles = jQuery('.offer-phone-payment button:not([disabled])');
                        applicablePhonePaymentbundles.last().addClass('is-active');
                    }

                    jQuery('button .name').matchHeight();
                }

                function setOfferLinks() { // jshint ignore:line
                    //Set active offer link
                    var baseURL = 'http://' + location.host + location.pathname;
                    //console.log(baseURL);
                    var colorParam = jQuery('.receipt.is-desktop input[name="phone_color_raw"]').val();
                    //console.log(colorParam);
                    var providerParam = jQuery('.receipt.is-desktop input[name="contract_type"]').val();
                    var simProviderParam = '';
                    if(jQuery('.receipt.is-desktop input[name="is_sim_with_phone"]').val() === "yes"){
                        providerParam = 'los_basis';
                        simProviderParam = jQuery('.receipt.is-desktop input[name="contract_type"]').val();
                    }
                    var typeParam = jQuery('.receipt.is-desktop input[name="contract_status"]').val();
                    var durationParam = jQuery('.receipt.is-desktop input[name="contract_duration"]').val();
                    //console.log(providerParam);
                    //console.log(simProviderParam);
                    var basicParam = jQuery('.receipt.is-desktop input[name="bouwblok_basis"]').val();
                    var dataParam = jQuery('.receipt.is-desktop input[name="bouwblok_data"]').val();
                    //console.log(dataParam);
                    var dataSpeedParam = jQuery('.receipt.is-desktop input[name="data_speed"]').val();
                    //console.log(dataSpeedParam);
                    var voiceParam = jQuery('.receipt.is-desktop input[name="bouwblok_voice"]').val();
                    //console.log(voiceParam);
                    var smsParam = jQuery('.receipt.is-desktop input[name="bouwblok_sms"]').val();
                    //console.log(smsParam);
                    var phonepayParam = jQuery('.receipt.is-desktop input[name="bouwblok_handset"]').val();
                    //console.log(phonepayParam);
                    var addonsParam = '';
                    var insuranceParam = jQuery('.receipt.is-desktop input[name="bundle_code_insurance"]').val();
                    //console.log(insuranceParam);

                    if(jQuery('.receipt.is-desktop input[name="order_type"]').val() === 'tele2'){
                        if(jQuery('.phonepay-meta .discount_monthly .value').text() === '0'){
                            phonepayParam = '00';
                        } else{
                            phonepayParam = jQuery('.phonepay-meta .discount_monthly .value').text();
                        }
                        dataParam = '';
                        voiceParam = '';
                        smsParam = '';
                    } else if(jQuery('.receipt.is-desktop input[name="order_type"]').val() === 'vodafone') {
                        if(jQuery('.phonepay-meta .discount_monthly .value').text() === '0'){
                            phonepayParam = '00';
                        } else{
                            phonepayParam = jQuery('.phonepay-meta .discount_monthly .value').text();
                        }
                        dataParam = '';
                        voiceParam = '';
                        smsParam = '';
                    } else if(jQuery('.receipt.is-desktop input[name="contract_type"]').val() === 'tmobile_go') {
                        basicParam = dataParam;
                        dataParam = '';
                        voiceParam = '';
                        smsParam = '';
                    } else if(jQuery('.receipt.is-desktop input[name="order_type"]').val() === 'ben'){
                        phonepayParam = jQuery('.offer-phone-payment button.is-active').attr('data-bundle-key-original');
                        basicParam = '';
                    } else{
                        basicParam = '';
                    }

                    function getAddonParams(){
                        for (i = 1; i <= 10; i++) {
                            var iFormatted = ("0" + Number(i)).slice(-2);
                            var addonKey = jQuery('.receipt.is-desktop input.bundle_code_addon_'+iFormatted+'').val();
                            if(addonKey.indexOf('AI') !== -1 || addonKey.indexOf('CI') !== -1){
                                return true;
                            }
                            if(addonKey === ''){
                                return addonsParam;
                            }
                            if(addonsParam !== ''){
                                addonsParam += '|';
                            }
                            addonsParam += addonKey;
                        }
                    }
                    getAddonParams();

                    if(colorParam !== undefined && colorParam !== ''){
                        colorParam = '?color='+colorParam;
                    } else{
                        colorParam = '';
                    }

                    if(providerParam !== undefined && providerParam !== ''){
                        providerParam = '&provider='+providerParam;
                    } else{
                        providerParam = '';
                    }

                    if(simProviderParam !== undefined && simProviderParam !== ''){
                        simProviderParam = '&sim_provider='+simProviderParam;
                    } else{
                        simProviderParam = '';
                    }

                    if(typeParam !== undefined && typeParam !== ''){
                        typeParam = '&type='+typeParam;
                    } else{
                        typeParam = '';
                    }

                    if(durationParam !== undefined && durationParam !== ''){
                        durationParam = '&looptijd='+durationParam;
                    } else{
                        durationParam = '';
                    }

                    if(basicParam !== undefined && basicParam !== ''){
                        basicParam = '&basic='+basicParam;
                    } else{
                        basicParam = '';
                    }

                    if(dataParam !== undefined && dataParam !== ''){
                        dataParam = '&data='+dataParam;
                    } else{
                        dataParam = '';
                    }

                    if(dataSpeedParam !== undefined && dataSpeedParam !== ''){
                        dataSpeedParam = '&data_speed='+dataSpeedParam;
                    } else{
                        dataSpeedParam = '';
                    }

                    if(voiceParam !== undefined && voiceParam !== ''){
                        voiceParam = '&call='+voiceParam;
                    } else{
                        voiceParam = '';
                    }

                    if(smsParam !== undefined && smsParam !== ''){
                        smsParam = '&sms='+smsParam;
                    } else{
                        smsParam = '';
                    }

                    if(phonepayParam !== undefined && phonepayParam !== ''){
                        phonepayParam = '&phonepay='+phonepayParam;
                    } else{
                        phonepayParam = '';
                    }

                    if(addonsParam !== undefined && addonsParam !== ''){
                        addonsParam = '&addons='+addonsParam;
                    } else{
                        addonsParam = '';
                    }

                    if(insuranceParam !== undefined && insuranceParam !== ''){
                        insuranceParam = '&insurance='+insuranceParam;
                    } else{
                        insuranceParam = '';
                    }

                    //console.log(baseURL+colorParam+providerParam+simProviderParam+typeParam+durationParam+dataParam+dataSpeedParam+voiceParam+smsParam+phonepayParam+addonsParam+insuranceParam);
                    var offerLink = baseURL+colorParam+providerParam+simProviderParam+typeParam+durationParam+basicParam+dataParam+dataSpeedParam+voiceParam+smsParam+phonepayParam+addonsParam+insuranceParam;
                    jQuery('.receipt input[name="offer_link"]').val(offerLink);

                }

                function classHelper(){ // jshint ignore:line
                    var step = jQuery('.is-step');
                    jQuery.each(step, function( index, value ) {
                        jQuery(this).find('.is-section:visible:first').addClass('is-first');
                        jQuery(this).find('.is-section:visible:last').addClass('is-last');
                    });
                }

                classHelper();


                function setActiveBenInputs(){ // jshint ignore:line
                    if(jQuery('input.contract_provider').val() === 'ben') {
                        var activeDataSpeed = '';
                        var activeBundleKeyDataSpeed = '';
                        //Find active bundle keys
                        var activeBundleKeyData = jQuery('.offer-contract-data button.is-active').attr('data-bundle-key');
                        var dataSpeedBlockActive = jQuery('.offer-contract-addon button.is-dataspeed.is-active');
                        var activeBundleKeyAddonSMS = jQuery('.offer-contract-addon button.is-extrasms.is-active').attr('data-bundle-key');
                        var extraSmsBlockActive = jQuery('.offer-contract-addon button.is-extrasms.is-active');
                        var hasExtraSMS = false;
                        var activeDuration = jQuery('.receipt.is-desktop input[name="contract_duration"]').val();
                        if(activeDuration === '1'){
                            activeDuration = '01';
                        }
                        if(dataSpeedBlockActive.length){
                            activeDataSpeed = '4G';
                            activeBundleKeyDataSpeed = dataSpeedBlockActive.attr('data-bundle-key');
                        } else{
                            activeDataSpeed = '3G';
                            //TODO: always make available 4G addon bundle key available, even when there is only 3G, so we can check for the existence of this key in getActiveBenConfID()
                            activeBundleKeyDataSpeed = 'BENB2B0'+activeDuration+'4GADDON';
                        }

                        jQuery('.receipt.is-desktop input[name="data_speed"]').val(activeDataSpeed);

                        if(extraSmsBlockActive.length){
                            hasExtraSMS = true;
                            activeBundleKeyAddonSMS = jQuery('.offer-contract-addon button.is-extrasms.is-active').attr('data-bundle-key');
                        } else{
                            activeBundleKeyAddonSMS = 'BENB2B0'+activeDuration+'SMS_1000';
                        }

                        var activeBundleKeyVoice = jQuery('.offer-contract-call button.is-active').attr('data-bundle-key');
                        var activeBundleKeyPhonePay = jQuery('.offer-phone-payment button.is-active').attr('data-bundle-key-original');

                        //Get active Ben configuration ID
                        var confID = getActiveBenConfID(activeBundleKeyData, activeBundleKeyDataSpeed, activeBundleKeyVoice, activeBundleKeyPhonePay, activeDataSpeed, activeBundleKeyAddonSMS, hasExtraSMS);
                        jQuery('input.contract_configuration_no').val(confID);
                        console.log('Active ID = '+confID);
                        // AJAX POST REQUEST -> $_GET['action'] == 'getblokken' confID -> $_POST['id']
                        jQuery.post('/?action=getblokken',{
                                id: confID
                            }
                        ).done(function(data){
                            console.log(data);
                        });

                        var packageDiscountRow = jQuery('.offer-receipt .contract-discount');

                        jQuery.each(benBundleCombinations[confID], function( index, value ) {
                            if (index.toLowerCase().indexOf("pak") >= 0 ||
                                index.toLowerCase().indexOf("niets") >= 0){
                                var packageDiscount = benBundleCombinations[confID][index];
                                var packageAmount = packageDiscount.bruto_maandprijs;
                                var packageAmountDiscount = benBundleCombinations[confID][index].bundel_korting;

                                jQuery('.offer-contract-type button.is-active').attr('data-bundle-key', index);
                                jQuery('.offer-contract-type button.is-active').attr('data-bundle-price', packageAmount);
                                jQuery('.offer-contract-type button.is-active').attr('data-bundle-discount-price', packageAmountDiscount);
                                jQuery('.offer-contract-type button.is-active').attr('data-bundle-discount-price', packageAmountDiscount);

                                var packageAmountDiscountFormatted = parseFloat(packageAmountDiscount).toFixed(2).toString().replace(".", ",");

                                if(packageAmountDiscount === '0'){
                                    packageDiscountRow.hide();
                                } else{
                                    packageDiscountRow.show();
                                }

                                packageDiscountRow.find('.price .value').html(packageAmountDiscountFormatted);
                                packageDiscountRow.attr('data-bundle-price', packageAmountDiscount);
                            }
                        });

                    }
                }

                function setHiddenInputs(){ // jshint ignore:line
                    //TODO: integrate in offerBundle array

                    //Active contract type
                    var activeContractType = jQuery('input.contract_type').val();

                    //Addons
                    var activeBundleAddons = jQuery('.offer-contract-addon button.is-active, .offer-phone-insurance button.is-active');

                    //Monthly price
                    var activeBundlePriceBasic = jQuery('.offer-contract-type button.is-active span.actual .value').text().trim();
                    var activeBundlePriceDiscountBasic = jQuery('.receipt-monthly .contract-addon-combi-discount .price .value').text().trim();
                    var activeBundlePriceData = jQuery('.offer-contract-data button.is-active .price span.actual .value').text().trim();
                    var activeBundlePriceVoice = jQuery('.offer-contract-call button.is-active .price span.actual .value').text().trim();
                    var activeBundlePriceSMS = jQuery('.offer-contract-sms button.is-active .price span.actual .value').text().trim();
                    var activeBundlePricePhonePay = jQuery('.offer-phone-payment button.is-active span.price-month span.value').text().trim();
                    var activeBundlePriceInsurance = jQuery('.offer-phone-insurance button.is-active span.actual .value').text().trim();
                    var priceMonthTotal = jQuery('.receipt-monthly .contract-monthly-total .price .value').text().trim();

                    //Once price
                    var priceOncePhoneCopyLevy = jQuery('.receipt-once .copy-levy .price .value').text().trim();
                    var priceOncePhoneOriginal = jQuery('.receipt-once .phone-with-contract .price .value').text().trim();
                    var priceOncePhoneDiscount = jQuery('.receipt-once .phone-discount-once .price .value').text().trim();
                    var priceOnceSubtotal = jQuery('.receipt-once .phone-after-discount .price .value').text().trim();
                    var priceOncePhoneContractDiscount = jQuery('.receipt-once .phone-total-monthly .price .value').text().trim();
                    var priceOncePhoneTotal = jQuery('.receipt-once .phone-total-once .price .value').text().trim();
                    var priceOnceTotal = jQuery('.receipt-once .contract-once-total .price .value').text().trim();
                    var priceOnceDelivery = jQuery('.receipt-once .delivery .price .value').text().trim();
                    var priceOnceContractConnection = jQuery('.receipt-once .connection-cost .price .value').text().trim();
                    var priceOnceiDEAL = jQuery('.receipt-once .ideal .price .value').text().trim();

                    //Once price fallbacks
                    if(priceOncePhoneOriginal == null || priceOncePhoneOriginal == 'NaN'){ // jshint ignore:line
                        priceOncePhoneOriginal = '';
                    }
                    if(priceOncePhoneDiscount == null || priceOncePhoneDiscount == 'NaN'){ // jshint ignore:line
                        priceOncePhoneDiscount = '';
                    }
                    if(priceOncePhoneContractDiscount == null || priceOncePhoneContractDiscount == 'NaN'){ // jshint ignore:line
                        priceOncePhoneContractDiscount = '';
                    }

                    //Bundle Keys
                    var activeBundleKeyBasic = jQuery('.offer-contract-type button.is-active').attr('data-bundle-key');
                    var activeBundleKeyData = jQuery('.offer-contract-data button.is-active').attr('data-bundle-key');
                    var activeBundleKeyVoice = jQuery('.offer-contract-call button.is-active').attr('data-bundle-key');
                    var activeBundleKeySMS = jQuery('.offer-contract-sms button.is-active').attr('data-bundle-key');
                    var activeBundleKeyPhonePay = jQuery('.offer-phone-payment button.is-active').attr('data-bundle-key');
                    var activeBundleKeyInsurance = jQuery('.offer-phone-insurance button.is-active').attr('data-bundle-key');


                    if(activeContractType == 'tele2_basis') { //jshint ignore:line
                        activeBundleKeyBasic = jQuery('.offer-contract-allinone button.is-active').attr('data-bundle-key');
                        activeBundleKeyVoice = jQuery('.offer-contract-allinone button.is-active').attr('data-bundle-key-voice');
                        activeBundleKeyData = jQuery('.offer-contract-allinone button.is-active').attr('data-bundle-key-data');
                        activeBundleKeySMS = jQuery('.offer-contract-allinone button.is-active').attr('data-bundle-key-sms');
                        if(jQuery('input[name="has_phone"]').val() !== '1'){
                            activeBundleKeyPhonePay = 'T00000';
                        } else{
                            activeBundleKeyPhonePay = jQuery('.phonepay-meta .bundle_code .value').text().trim();
                        }

                    }

                    if(activeContractType == 'vodafone_basis') { //jshint ignore:line
                        activeBundleKeyBasic = jQuery('.offer-contract-allinone button.is-active').attr('data-bundle-key');
                        activeBundleKeyVoice = jQuery('.offer-contract-allinone button.is-active').attr('data-bundle-key-voice');
                        activeBundleKeyData = jQuery('.offer-contract-allinone button.is-active').attr('data-bundle-key-data');
                        activeBundleKeySMS = jQuery('.offer-contract-allinone button.is-active').attr('data-bundle-key-sms');
                        var phoneColorRaw = jQuery('input[name="phone_color_raw"]').val();
                        if(jQuery('input[name="has_phone"]').val() !== '1'){
                            var activeDurationYears = parseFloat(jQuery('input.contract_duration').val()) / 12;
                            if (phoneColorRaw === 'sim-only') {
                                activeBundleKeyPhonePay = 'AO_TOESTEL_RED4_RED_SIMONLY_CR';
                                priceOnceiDEAL = 0.01;
                            }
                            else {
                                activeBundleKeyPhonePay = 'AO_TOESTELBETALING_' + activeDurationYears + '_JAAR_0_EURO_CR';
                            }
                        } else{
                            activeBundleKeyPhonePay = jQuery('.phonepay-meta .bundle_code .value').text().trim();
                        }
                    }

                    if(activeContractType == 'tmobile_go') { //jshint ignore:line
                        activeBundleKeyData = jQuery('.offer-contract-allinone button.is-active').attr('data-bundle-key');
                    }

                    if(activeContractType == 'ben_basis') { // jshint ignore:line
                        //console.log(jQuery('input[name="has_phone"]').val());
                        if(jQuery('input[name="has_phone"]').val() === '1'){
                            activeBundleKeyBasic = jQuery('.offer-phone-payment button.is-active').attr('data-bundle-key');
                        } else{
                            activeBundleKeyBasic = 'BENB2BSIMONLY';
                        }

                        activeBundleKeySMS = jQuery('.offer-contract-type button.is-active').attr('data-bundle-key');
                        activeBundleKeyPhonePay = '';
                    }

                    //Bundle Key fallbacks

                    if(activeBundleKeySMS == null || activeBundleKeySMS == ''){ // jshint ignore:line
                        if(activeContractType == 'ben_basis'){ // jshint ignore:line
                            activeBundleKeySMS = 'BENB2B024NIETS';
                        }  else if(activeContractType == 'tmobile_go'){ // jshint ignore:line
                            activeBundleKeySMS = 'SMM00';
                        } else{
                            activeBundleKeySMS = '';
                        }
                    }

                    if(activeBundleKeyVoice == null || activeBundleKeyVoice == ''){ // jshint ignore:line
                        if(activeContractType == 'tmobile_go'){ // jshint ignore:line
                            activeBundleKeyVoice = 'MIN00';
                        } else{
                            activeBundleKeyVoice = '';
                        }
                    }

                    //Bundle Titles
                    var activeBundleTitleBasic = jQuery('.offer-contract-type button.is-active span.name').text().trim();
                    var activeBundleTitleBasicType = 'Basisbundel';
                    var activeBundleTitleData = jQuery('.offer-contract-data button.is-active span.name .actual').text().trim();
                    var activeBundleTitleDataUnit = jQuery('.offer-contract-data button.is-active span.name .unit').text().trim();
                    var activeBundleTitleVoice = jQuery('.offer-contract-call button.is-active span.name .actual').text().trim();
                    var activeBundleTitleVoiceUnit = jQuery('.offer-contract-call button.is-active span.name .unit').text().trim();
                    var activeBundleTitleSMS = jQuery('.offer-contract-sms button.is-active span.name .actual').text().trim();
                    var activeBundleTitleSMSUnit = jQuery('.offer-contract-sms button.is-active span.name .unit').text().trim();
                    var activeBundleTitlePhonePay = jQuery('.offer-phone-payment button.is-active span.name').text().trim();
                    var activeBundleTitleInsurance = jQuery('.offer-phone-insurance button.is-active span.name').text().trim();

                    if(activeContractType == 'vodafone_basis') { //jshint ignore:line
                        activeBundleTitleBasicType = jQuery('.offer-contract-allinone button.is-active .allinone-type').text().trim();
                    }

                    var activeBundlePriceBasicRaw = '';
                    var activeBundlePriceDiscountBasicRaw = '';
                    var activeBundlePriceDataRaw = '';
                    var activeBundlePriceVoiceRaw = '';
                    var activeBundlePriceSMSRaw = '';

                    if(activeContractType == 'tmobile_go') { //jshint ignore:line
                         activeBundleTitleBasicType = jQuery('.offer-contract-allinone button.is-active span.name .actual').text().trim();
                         activeBundleTitleData = jQuery('.offer-contract-allinone button.is-active .allinone-data').text().trim();
                         activeBundleTitleDataUnit = '';
                         activeBundleTitleVoice = jQuery('.offer-contract-allinone button.is-active .allinone-voice').text().trim();
                         activeBundleTitleVoiceUnit = '';
                         activeBundleTitleSMS = jQuery('.offer-contract-allinone button.is-active .allinone-sms').text().trim();
                         activeBundleTitleSMSUnit = '';

                        activeBundlePriceBasicRaw = jQuery('.offer-contract-allinone button.is-active').attr('data-bundle-price');
                        activeBundlePriceBasic = parseFloat(activeBundlePriceBasicRaw).toFixed(2).toString().replace(".", ",");
                        activeBundlePriceDiscountBasicRaw = '0';
                        activeBundlePriceDiscountBasic = parseFloat(activeBundlePriceDiscountBasicRaw).toFixed(2).toString().replace(".", ",");
                        activeBundlePriceDataRaw = '0';
                        activeBundlePriceData = parseFloat(activeBundlePriceDataRaw).toFixed(2).toString().replace(".", ",");
                        activeBundlePriceVoiceRaw = '0';
                        activeBundlePriceVoice = parseFloat(activeBundlePriceVoiceRaw).toFixed(2).toString().replace(".", ",");
                        activeBundlePriceSMSRaw = '0';
                        activeBundlePriceSMS = parseFloat(activeBundlePriceSMSRaw).toFixed(2).toString().replace(".", ",");
                    }

                    //TODO integrate tele2 dynamically > this is a temp fix
                    if(activeContractType == 'tele2_basis' || activeContractType == 'vodafone_basis') { //jshint ignore:line
                        //Monthly price
                        activeBundlePriceBasicRaw = jQuery('.offer-contract-allinone button.is-active').attr('data-bundle-price');
                        activeBundlePriceBasic = parseFloat(activeBundlePriceBasicRaw).toFixed(2).toString().replace(".", ",");
                        activeBundlePriceDiscountBasicRaw = jQuery('.offer-contract-allinone button.is-active').attr('data-phone-discount');
                        activeBundlePriceDiscountBasic = parseFloat(activeBundlePriceDiscountBasicRaw).toFixed(2).toString().replace(".", ",");
                        activeBundlePriceDataRaw = jQuery('.offer-contract-allinone button.is-active').attr('data-bundle-price-data');
                        activeBundlePriceData = parseFloat(activeBundlePriceDataRaw).toFixed(2).toString().replace(".", ",");
                        activeBundlePriceVoiceRaw = jQuery('.offer-contract-allinone button.is-active').attr('data-bundle-price-voice');
                        activeBundlePriceVoice = parseFloat(activeBundlePriceVoiceRaw).toFixed(2).toString().replace(".", ",");
                        activeBundlePriceSMSRaw = jQuery('.offer-contract-allinone button.is-active').attr('data-bundle-price-sms');
                        activeBundlePriceSMS = parseFloat(activeBundlePriceSMSRaw).toFixed(2).toString().replace(".", ",");
                        var activeBundlePricePhonePayRaw = jQuery('.phonepay-meta .discount_monthly .value').text().trim();
                        activeBundlePricePhonePay = parseFloat(activeBundlePricePhonePayRaw).toFixed(2).toString().replace(".", ",");

                        if(activeContractType == 'vodafone_basis'){ //jshint ignore:line
                            activeBundlePriceData = '';
                            activeBundlePriceVoice = '';
                            activeBundlePriceSMS = '';
                        }

                        //Once price
                        // priceOncePhoneOriginal = jQuery('').text().trim();
                        // priceOncePhoneDiscount = jQuery('').text().trim();
                        // priceOnceSubtotal = jQuery('').text().trim();
                        // priceOncePhoneContractDiscount = jQuery('').text().trim();
                        // priceOncePhoneTotal = jQuery('').text().trim();
                        // priceOnceTotal = jQuery('').text().trim();
                        // priceOnceDelivery = jQuery('').text().trim();
                        // priceOnceContractConnection = jQuery('').text().trim();
                        // priceOnceiDEAL = jQuery('').text().trim();

                        activeBundleTitleData = jQuery('.offer-contract-allinone button.is-active .allinone-data').text().trim();
                        activeBundleTitleVoice = jQuery('.offer-contract-allinone button.is-active .allinone-voice').text().trim();
                    }

                    //Bundle Keys
                    //console.log(activeBundleKeyBasic);
                    jQuery('input.bundle_code_basic').val(activeBundleKeyBasic);
                    jQuery('input.bundle_code_data').val(activeBundleKeyData);
                    jQuery('input.bundle_code_voice').val(activeBundleKeyVoice);
                    jQuery('input.bundle_code_sms').val(activeBundleKeySMS);
                    jQuery('input.bundle_code_phonepay').val(activeBundleKeyPhonePay);
                    jQuery('input.bundle_code_insurance').val(activeBundleKeyInsurance);

                    //Bundle Titles
                    jQuery('input.bundle_title_basic').val(activeBundleTitleBasic);
                    jQuery('input.bundle_title_basic_type').val(activeBundleTitleBasicType);
                    jQuery('input.bundle_title_data').val(activeBundleTitleData + ' ' + activeBundleTitleDataUnit);
                    jQuery('input.bundle_title_voice').val(activeBundleTitleVoice + ' ' + activeBundleTitleVoiceUnit);
                    jQuery('input.bundle_title_sms').val(activeBundleTitleSMS + ' ' + activeBundleTitleSMSUnit);
                    jQuery('input.bundle_title_phonepay').val(activeBundleTitlePhonePay);
                    jQuery('input.bundle_title_insurance').val(activeBundleTitleInsurance);

                    //Check if is Sim only or sim with phone
                    var isSimWithPhone = jQuery('input.is_sim_with_phone').val();

                    //Phone list price
                    var activeListPricePhonePay = 0;
                    if(activeContractType == 'los'){ // jshint ignore:line
                        activeListPricePhonePay = jQuery('.phone-colors.is-main button.is-active').attr('data-bundle-price-once');
                    } else if(priceOncePhoneCopyLevy == null || priceOncePhoneCopyLevy == '' || typeof priceOncePhoneCopyLevy == 'undefined'){ // jshint ignore:line
                        //Sim Only
                        activeListPricePhonePay = '0';
                    } else if(isSimWithPhone == 'yes'){ // jshint ignore:line
                        activeListPricePhonePay = '0';
                    } else{
                        activeListPricePhonePay = jQuery('.offer-phone-payment button.is-active').attr('data-phone-list-price');
                    }

                    //TODO integrate tele2 dynamically > this is a temp fix
                    if(activeContractType == 'tele2_basis' || activeContractType == 'vodafone_basis'){//jshint ignore:line
                        if(jQuery('input[name="has_phone"]').val() === '1') {
                            activeListPricePhonePay = jQuery('.phonepay-meta .total_cost_after_discount_monthly .value').text().trim();
                        } else{
                            activeListPricePhonePay = '0';
                        }
                    }

                    //Phone list price
                    jQuery('input.price_once_phone_lvp').val(activeListPricePhonePay);

                    //Addons
                    //Reset addons
                    for (i = 1; i <= 10; i++) {
                        var iFormatted = ("0" + Number(i)).slice(-2);
                        //codes
                        jQuery('input.bundle_code_addon_'+iFormatted+'').val('');
                        //titles
                        jQuery('input.bundle_title_addon_'+iFormatted+'').val('');
                        //prices
                        jQuery('input.price_month_addon_'+iFormatted+'').val('');
                    }

                    //Set addons
                    jQuery.each(activeBundleAddons,function(key, value){
                        var activeBundleNoAddon = ("0" + Number(key + 1)).slice(-2);
                        var activeBundleKeyAddon = jQuery(this).attr('data-bundle-key');
                        var activeBundleTitleAddon = jQuery(this).find('span.name').text().trim();
                        var activeBundlePriceAddon = jQuery(this).find('span.value').text().trim();
                        jQuery('input.bundle_code_addon_'+activeBundleNoAddon+'').val(activeBundleKeyAddon);
                        jQuery('input.bundle_title_addon_'+activeBundleNoAddon+'').val(activeBundleTitleAddon);
                        jQuery('input.price_month_addon_'+activeBundleNoAddon+'').val(activeBundlePriceAddon);
                    });

                    //Monthly price
                    jQuery('input.price_month_basic').val(activeBundlePriceBasic);
                    jQuery('input.price_month_discount_basic').val(activeBundlePriceDiscountBasic);
                    jQuery('input.price_month_data').val(activeBundlePriceData);
                    jQuery('input.price_month_voice').val(activeBundlePriceVoice);
                    jQuery('input.price_month_sms').val(activeBundlePriceSMS);
                    jQuery('input.price_month_phonepay').val(activeBundlePricePhonePay);
                    jQuery('input.price_month_insurance').val(activeBundlePriceInsurance);
                    jQuery('input.price_month_total').val(priceMonthTotal);

                    //Once price
                    jQuery('input.price_once_phone_copylevy').val(priceOncePhoneCopyLevy);
                    jQuery('input.price_once_phone_original').val(priceOncePhoneOriginal);
                    jQuery('input.price_once_phone_discount').val(priceOncePhoneDiscount);
                    jQuery('input.price_once_phone_subtotal').val(priceOnceSubtotal);
                    jQuery('input.price_once_phone_contract_discount').val(priceOncePhoneContractDiscount);
                    jQuery('input.price_once_phone_total').val(priceOncePhoneTotal);
                    jQuery('input.price_once_total').val(priceOnceTotal);
                    jQuery('input.price_once_delivery').val(priceOnceDelivery);
                    jQuery('input.price_once_contract_connection').val(priceOnceContractConnection);
                    jQuery('input.price_once_ideal').val(priceOnceiDEAL);

                    //Ben
                    ///Configuration ID
                    setActiveBenInputs();

                    //Phone
                    var activePhoneImageSrcRaw = jQuery('.phone-image img').attr('src');
                    var activePhoneImageSrc = activePhoneImageSrcRaw.replace('http','https');
                    //var activePhoneArticleNo = jQuery('.phone-colors button.active').attr('src');

                    jQuery('input.phone_img_link').val(activePhoneImageSrc);

                }

                function setPhonePaymentTable(){ // jshint ignore:line
                    var hasPhone = jQuery('input.has_phone').val();
                    var phoneTermPriceRaw = jQuery('input.price_month_phonepay').val();
                    var paymentTable = jQuery('.credit-table');
                    if(hasPhone === '1'){
                        if(phoneTermPriceRaw !== 'undefined' && phoneTermPriceRaw !== '0' && phoneTermPriceRaw !== '0,00'){
                            var phoneTermPrice = parseFloat(phoneTermPriceRaw.replace(",", ".")).toFixed(2).toString().replace(".", ",");
                            //Todo: decide whether to use original price or price after contract discount
                            //var phoneTotalPrice = jQuery('input.price_once_phone_original').val();
                            var phoneTotalPrice = jQuery('input.price_once_phone_subtotal').val();
                            var phoneOncePrice = jQuery('input.price_once_phone_total').val();
                            var phoneTermAmountRaw = jQuery('input.contract_duration').val();
                            var phoneTermAmount = '';
                            if(phoneTermAmountRaw === '1'){
                                phoneTermAmount = phoneTermAmountRaw + ' maand';
                            } else{
                                phoneTermAmount = phoneTermAmountRaw + ' maanden';
                            }
                            var phoneCreditPriceRaw = parseFloat(phoneTermPriceRaw.replace(",", ".")) * parseFloat(phoneTermAmountRaw);
                            var phoneCreditPrice = parseFloat(phoneCreditPriceRaw).toFixed(2).toString().replace(".", ",");

                            //table cells of credit table
                            var phoneTotalPriceCell = jQuery('.credit-table .phone-total-price');
                            var phoneOncePriceCell = jQuery('.credit-table .phone-once-price');
                            var phoneCreditPriceCell = jQuery('.credit-table .phone-credit-price');
                            var phoneTermPriceCell = jQuery('.credit-table .phone-term-price');
                            var phoneTermAmountCell = jQuery('.credit-table .phone-term-amount');

                            //input value for total phone credit
                            var phoneCreditPriceCellInput = jQuery('.order-wrapper input[name="price_phonepay_total"]');

                            //fill cells with data
                            phoneTotalPriceCell.html('&euro; '+phoneTotalPrice);
                            phoneOncePriceCell.html('&euro; '+phoneOncePrice);
                            phoneCreditPriceCell.html('&euro; '+phoneCreditPrice);
                            phoneTermPriceCell.html('&euro; '+phoneTermPrice);
                            phoneTermAmountCell.html(phoneTermAmount);

                            phoneCreditPriceCellInput.val(phoneCreditPriceRaw);

                            if(phoneCreditPriceRaw > 250){
                                $('#ilt-calculator').removeClass('is-hidden');
                            } else{
                                $('#ilt-calculator').addClass('is-hidden');
                            }

                            paymentTable.removeClass('is-hidden');
                        } else{
                            paymentTable.addClass('is-hidden');
                        }
                    }
                }

                //Highlight receipt row
                function highLightRow(bundleType, activeButton) { // jshint ignore:line
                    // var monthTotalRow = jQuery('.contract-monthly-total');
                    // var onceTotalRow = jQuery('.contract-once-total');

                    //Active contract type
                    var activeContractType = jQuery('input.contract_type').val();

                    if(activeContractType != 'tele2_basis' && activeContractType != 'vodafone_basis' && activeContractType != 'tmobile_go') { //jshint ignore:line

                        //Fill addon bundle data
                        var monthTotalRowDesktop = jQuery('.is-mq-desktop .contract-monthly-total');
                        var onceTotalRowDesktop = jQuery('.is-mq-desktop .contract-once-total');

                        var monthTotalRowMobile = jQuery('.is-mq-mobile .contract-monthly-total');
                        var onceTotalRowMobile = jQuery('.is-mq-mobile .contract-once-total');

                        if (bundleType.buttonToggle !== false) {

                            activeButtonKey = activeButton.attr('data-bundle-key');
                            targetAddonRowClass = '.addon-on-' + activeButtonKey;
                            targetAddonRow = jQuery(bundleType.receiptRowMonth).find(targetAddonRowClass);

                            //Highlight monthly payment row
                            if (targetAddonRow !== false) {
                                // TODO: commented code was not executed on time, use when() in order to run this code after addon row html has been added to receipt (otherwise there is nothing to highlight)
                                // TODO: current addon highlight is executed in setReceiptData(), just after html has been added
                                // targetAddonRow.addClass("is-highlight").delay(750).queue(function () {
                                //     targetAddonRow.removeClass("is-highlight").dequeue();
                                // });

                                if (!jQuery('.receipt-monthly').hasClass('is-expanded')) {
                                    //Highlight monthly total row for desktop
                                    monthTotalRowDesktop.addClass("is-highlight").delay(750).queue(function () {
                                        monthTotalRowDesktop.removeClass("is-highlight").dequeue();
                                    });
                                }

                                //Highlight monthly total row for mobile
                                monthTotalRowMobile.addClass("is-highlight").delay(750).queue(function () {
                                    monthTotalRowMobile.removeClass("is-highlight").dequeue();
                                });
                            }

                        } else {

                            //Highlight once payment row
                            if (bundleType.receiptRowOnce !== false) {
                                bundleType.receiptRowOnce.addClass("is-highlight").delay(750).queue(function () {
                                    bundleType.receiptRowOnce.removeClass("is-highlight").dequeue();
                                });

                                if (!jQuery('.receipt-once').hasClass('is-expanded')) {
                                    //Highlight once total row for desktop
                                    onceTotalRowDesktop.addClass("is-highlight").delay(750).queue(function () {
                                        onceTotalRowDesktop.removeClass("is-highlight").dequeue();
                                    });
                                }

                                //Highlight once total row for mobile
                                onceTotalRowMobile.addClass("is-highlight").delay(750).queue(function () {
                                    onceTotalRowMobile.removeClass("is-highlight").dequeue();
                                });
                            }

                            //Highlight monthly payment row
                            if (bundleType.receiptRowMonth !== false) {
                                bundleType.receiptRowMonth.addClass("is-highlight").delay(750).queue(function () {
                                    bundleType.receiptRowMonth.removeClass("is-highlight").dequeue();
                                });

                                if (!jQuery('.receipt-monthly').hasClass('is-expanded')) {
                                    //Highlight month total row for desktop
                                    monthTotalRowDesktop.addClass("is-highlight").delay(750).queue(function () {
                                        monthTotalRowDesktop.removeClass("is-highlight").dequeue();
                                    });
                                }

                                //Highlight monthly total row for mobile
                                monthTotalRowMobile.addClass("is-highlight").delay(750).queue(function () {
                                    monthTotalRowMobile.removeClass("is-highlight").dequeue();
                                });
                            }
                        }
                    }
                }


                //Calculate monthly price
                var receiptRowTotalMonthly = jQuery('.contract-monthly-total .price .value');
                function calculateMonthly(){ // jshint ignore:line
                    var activeButtons = jQuery(".is-monthly.is-active");
                    totalMonthlyRaw = 0;

                    jQuery.each(activeButtons, function(){
                        totalMonthlyRaw += parseFloat(jQuery(this).attr(buttonPriceMonthValueRaw));
                    });

                    totalMonthly = totalMonthlyRaw.toFixed(2).toString().replace(".", ",");
                    receiptRowTotalMonthly.html(totalMonthly);
                    //TODO: receiptRowMobileTotalMonthly.html(totalMonthly);
                }

                function calculateMonthlyUntilGoodie(triggerButton){ // jshint ignore:line
                    var dataWrapper = jQuery('.offer-contract-data');
                    var activeData = jQuery(".offer-contract-data .is-monthly.is-active");
                    var voiceWrapper = jQuery('.offer-contract-call');
                    var activeVoice = jQuery(".offer-contract-call .is-monthly.is-active");

                    var activeDataKey = activeData.attr(buttonKey);
                    var activeDataPrice = parseFloat(activeData.attr(buttonPriceMonthValueRaw));
                    var activeDataAmount = parseFloat(activeData.find('.value .actual').html());
                    var activeVoiceKey = activeVoice.attr(buttonKey);
                    var activeVoicePrice = parseFloat(activeVoice.attr(buttonPriceMonthValueRaw));
                    var activeVoiceAmount = parseFloat(activeVoice.find('.value .actual').html());

                    var totalCurrent = activeDataPrice + activeVoicePrice;
                    var totalMonthlyUntilGoodie = false;

                    var dataUntilGoodie = false;
                    var voiceUntilGoodie = false;

                    dataWrapper.removeClass('goodyTrigger');
                    voiceWrapper.removeClass('goodyTrigger');

                    if(activeDataKey !== 'DAT44' && activeDataKey !== 'DAT45' && activeVoiceKey !== 'MIN44'){
                        jQuery('body').addClass('hasGoody');
                        if(triggerButton){
                            triggerButton.closest('.is-section').addClass('goodyTrigger');
                        }
                    } else{
                        totalMonthlyUntilGoodie = 12.5 - totalCurrent;
                        dataUntilGoodie = 750 - activeDataAmount;
                        voiceUntilGoodie = 200 - activeVoiceAmount;
                        if(dataUntilGoodie < 0){
                            totalMonthlyUntilGoodie = 5 - activeVoicePrice;
                        }
                        if(voiceUntilGoodie < 0){
                            totalMonthlyUntilGoodie = 7.5 - activeDataPrice;
                        }
                        jQuery('body').removeClass('hasGoody');
                    }

                    if(jQuery('body').hasClass('hasGoody')){
                        jQuery('.offer-deal #no-jbl').hide();
                        jQuery('.offer-deal #has-jbl').show();
                    } else{
                        jQuery('.offer-deal #no-jbl #data-to-jbl-wrapper').show();
                        jQuery('.offer-deal #no-jbl #voice-to-jbl-wrapper').show();
                        jQuery('.offer-deal #has-jbl').hide();
                        jQuery('.offer-deal #no-jbl #cost-to-jbl').html(totalMonthlyUntilGoodie.toFixed(2).toString().replace(".", ","));
                        jQuery('.offer-deal #no-jbl #data-to-jbl').html(dataUntilGoodie);
                        jQuery('.offer-deal #no-jbl #voice-to-jbl').html(voiceUntilGoodie);
                        if(voiceUntilGoodie <= 0){
                            jQuery('.offer-deal #no-jbl #voice-to-jbl-wrapper').hide();
                        }
                        if(dataUntilGoodie <= 0){
                            jQuery('.offer-deal #no-jbl #data-to-jbl-wrapper').hide();
                        }
                        jQuery('.offer-deal #no-jbl').show();
                    }

                }

                //Calculate one time price
                var receiptRowTotalOnce = jQuery('.contract-once-total .price .value');
                function calculateOnce(){ // jshint ignore:line
                    var activeButtons = jQuery(".is-once.is-active");
                    //console.log(activeButtons);
                    var totalOnceRaw = 0;

                    jQuery.each(activeButtons, function(){
                        totalOnceRaw += parseFloat(jQuery(this).attr(buttonPriceOnceRaw));
                    });

                    totalOnce = totalOnceRaw.toFixed(2).toString().replace(".", ",");
                    receiptRowTotalOnce.html(totalOnce);
                    //TODO: receiptRowMobileTotalOnce.html(totalOnce);
                }

                function extraFunctions(){ // jshint ignore:line
                    var activeDatabutton = jQuery('.offer-contract-data button.is-active');
                    var dataExpanderTrigger = activeDatabutton.parent().next().find('.expander-trigger');
                    if(dataExpanderTrigger.hasClass('expander-hidden') && activeDatabutton.attr('data-bundle-key') === 'DAT35_U'){
                        dataExpanderTrigger.trigger('click');
                    }
                }

                function activateOrderbutton(){ // jshint ignore:line
                    var orderButton = jQuery('#order-button.orderable');
                    orderButton.find('.wait').hide('fast');
                    orderButton.removeAttr('disabled');
                    orderButton.removeClass('loading');
                    orderButton.find('.order').show('fast');
                }

                //Offer bundle switches
                function switchActiveButton(bundleType) { // jshint ignore:line
                    bundleType.button.click(function () {
                        bundleType.button.removeClass('is-active');
                        jQuery(this).addClass('is-active');
                        hideBenBlocks();
                        setReceiptData(bundleType, jQuery(this));
                        setHiddenInputs();
                        calculateMonthly();
                        //calculateMonthlyUntilGoodie(jQuery(this));
                        calculateOnce();
                        setHiddenInputs();
                        setPhonePaymentTable();
                        setOfferLinks();
                        highLightRow(bundleType, jQuery(this));
                        extraFunctions();
                    });
                }

                //Offer bundle toggles
                function toggleActiveButton(bundleType) { // jshint ignore:line
                    bundleType.button.click(function () {
                        jQuery(this).toggleClass('is-active');
                        setReceiptData(bundleType, jQuery(this));
                        setHiddenInputs();
                        calculateMonthly();
                        calculateOnce();
                        setHiddenInputs();
                        setOfferLinks();
                        highLightRow(bundleType, jQuery(this));
                        extraFunctions();
                    });
                }

                var rangeSlider = document.getElementById('slider-range');
                //Offer bundle toggles
                function switchPhonePayment(bundleType) { // jshint ignore:line
                    if(rangeSlider !== null) {
                        rangeSlider.noUiSlider.on('change', function () {
                            //console.log('slider value is changed');
                            setReceiptData(bundleType, jQuery('.offer-contract-allinone button.is-active'));
                            setHiddenInputs();
                            calculateMonthly();
                            calculateOnce();
                            setHiddenInputs();
                            setPhonePaymentTable();
                            setOfferLinks();
                            // highLightRow(bundleType, jQuery(this));
                        });
                    }
                }


                if(jQuery('input.contract_provider').val() == 'tele2' || jQuery('input.contract_provider').val() == 'vodafone') { // jshint ignore:line
                    switchActiveButton(offerBundle.allinone);
                    switchPhonePayment(offerBundle.allinone);

                    if(offerBundle.allinone.buttonCount > 0){setReceiptData(offerBundle.allinone, offerBundle.allinone.buttonActive);}
                }
                else if(jQuery('input.contract_provider').val() == 'vodafone') { // jshint ignore:line

                    switchActiveButton(offerBundle.allinonevoda);
                    switchPhonePayment(offerBundle.allinonevoda);

                    if(offerBundle.allinonevoda.buttonCount > 0){setReceiptData(offerBundle.allinonevoda, offerBundle.allinonevoda.buttonActive);}
                }
                else{
                    if(jQuery('input.contract_type').val() !== 'tmobile_go'){
                        switchActiveButton(offerBundle.data);
                        switchActiveButton(offerBundle.voice);
                        switchActiveButton(offerBundle.sms);
                    } else{
                        switchActiveButton(offerBundle.allinoneGo);
                    }
                    toggleActiveButton(offerBundle.addon);
                    switchActiveButton(offerBundle.phonepay);
                    switchActiveButton(offerBundle.insurance);
                    switchActiveButton(offerBundle.simcombination);
                    switchActiveButton(offerBundle.simprovider);

                    hideBenBlocks();

                    if(offerBundle.contractType.buttonCount > 0){setReceiptData(offerBundle.contractType, offerBundle.contractType.buttonActive);}

                    if(jQuery('input.contract_type').val() !== 'tmobile_go'){
                        if(offerBundle.data.buttonCount > 0){setReceiptData(offerBundle.data, offerBundle.data.buttonActive);}
                        if(offerBundle.voice.buttonCount > 0){setReceiptData(offerBundle.voice, offerBundle.voice.buttonActive);}
                        if(offerBundle.sms.buttonCount > 0){setReceiptData(offerBundle.sms, offerBundle.sms.buttonActive);}
                    } else{
                        if(offerBundle.allinoneGo.buttonCount > 0){setReceiptData(offerBundle.allinoneGo, offerBundle.allinoneGo.buttonActive);}
                    }

                    if(offerBundle.addon.buttonCount > 0){setReceiptData(offerBundle.addon, offerBundle.addon.buttonActive);}
                    if(offerBundle.phonepay.buttonCount > 0){setReceiptData(offerBundle.phonepay, offerBundle.phonepay.buttonActive);}
                    if(offerBundle.insurance.buttonCount > 0){setReceiptData(offerBundle.insurance, offerBundle.insurance.buttonActive);}
                    if(offerBundle.phonecolor.buttonCount > 0){setReceiptData(offerBundle.phonecolor, offerBundle.phonecolor.buttonActive);}
                }

                //TODO: Split code in order not to have to run setHiddenInputs() twice!!
                setHiddenInputs();
                calculateMonthly();
                //calculateMonthlyUntilGoodie();
                calculateOnce();
                setHiddenInputs();
                setPhonePaymentTable();
                setOfferLinks();
                extraFunctions();
                activateOrderbutton();

                // Switch offer guide steps
                // var scrollSpySource = jQuery('div.is-step');
                // if(scrollSpySource.length){
                //     jQuery(scrollSpySource).each(function(i) {
                //         var currentID = jQuery(this).attr('id');
                //         var scrollSpyTarget = jQuery('.offer-guide a[href=#'+currentID+']');
                //         var position = jQuery(this).position();
                //         var elementHeight = jQuery(this).height();
                //         var windowHeight = window.innerHeight;
                //         var customHeight = 0;
                //         if(currentID === 'step-contract-provider'){
                //             offset = position.top;
                //             customHeight = jQuery('#step-contract-info').height();
                //         }
                //         jQuery(this).scrollspy({
                //             min: position.top - windowHeight,
                //             max: position.top + elementHeight + customHeight - windowHeight,
                //             onEnter: function(element, position) {
                //                 jQuery(scrollSpyTarget).addClass('is-active');
                //             },
                //             onLeave: function(element, position) {
                //                 jQuery(scrollSpyTarget).removeClass('is-active');
                //             }
                //         });
                //     });
                // }

                var scrollSpySource = jQuery('#step-contract-provider');
                var scrollSpyTarget = jQuery('.config-trigger');
                if(scrollSpySource.length && scrollSpyTarget.length) {
                    var position = scrollSpySource.position();
                    scrollSpySource.scrollspy({
                        min: 0,
                        max: position.top,
                        onEnter: function (element, position) {
                            jQuery(scrollSpyTarget).removeClass('is-hidden');
                        },
                        onLeave: function (element, position) {
                            jQuery(scrollSpyTarget).addClass('is-hidden');
                        }
                    });
                }


            }
        }
    }
};
