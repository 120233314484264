jQuery(document).ready(function() {

    jQuery(".expander-trigger").addClass("expander-hidden");
    jQuery(".expander-content").hide();
    jQuery('.expander-trigger').click(function(e){
        e.preventDefault();

        var thisContent = jQuery(this).siblings(".expander-content");
        if(jQuery(this).hasClass("expander-hidden")){
            jQuery(this).removeClass("expander-hidden");
            jQuery(this).siblings(".expander-content").show(300);
        } else{
            jQuery(this).addClass("expander-hidden");
            jQuery(this).siblings(".expander-content").hide(300);
        }
    });

    jQuery('.expander-close').click(function(e){
        e.preventDefault();
        jQuery(this).closest(".expander-content").hide(300);
        jQuery(this).closest(".expander-content").siblings(".expander-trigger").addClass("expander-hidden");
    });

});