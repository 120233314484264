jQuery(document).ready(function() {

    //Switch between screens
    var previousStep = jQuery('.step-back');
    var nextStep = jQuery('.step-trigger');

    jQuery(nextStep).click(function() {
        // Function Runs On NEXT Button Click
        var jQuerythisNextStep = jQuery(this);

        if (jQuerythisNextStep.hasClass('exact')){

            //console.log('forward exact');
            jQuerythisNextStep.closest('.calculator-step').next().addClass('flex-container').hide().fadeIn('slow');
            jQuerythisNextStep.closest('.calculator-step').siblings('.step-3').find('.step-back').addClass('exact-calc');
            jQuerythisNextStep.closest('.calculator-step').removeClass('flex-container');

        } else if(jQuerythisNextStep.hasClass('estimate')){

            //console.log('forward estimate');
            jQuerythisNextStep.closest('.calculator-step').siblings('.step-2-estimate-people').addClass('flex-container').hide().fadeIn('slow');
            jQuerythisNextStep.closest('.calculator-step').siblings('.step-3').find('.step-back').removeClass('exact-calc');
            jQuerythisNextStep.closest('.calculator-step').removeClass('flex-container').hide();

        } else if(jQuerythisNextStep.parent().hasClass('is-sub')){

            //console.log('forward sub');
            if (jQuerythisNextStep.parent().hasClass('is-last-part')){
                jQuerythisNextStep.closest('.calculator-step').siblings('.step-3').addClass('flex-container').hide().fadeIn('slow');

                // Adding Class Active To Show Steps Forward;
                jQuery('.calculator-steps a.active:last').addClass('completed').next().addClass('active completed');
                jQuery('.calculator-steps a.active:last').prev().removeClass('active');
            } else{
                jQuerythisNextStep.parent().next().addClass('flex-container').hide().fadeIn('slow');
            }

            jQuerythisNextStep.parent().removeClass('flex-container').hide();

        } else if(jQuerythisNextStep.parent().hasClass('is-main')){

            if(jQuerythisNextStep.parent().hasClass('step-3')){
                jQuerythisNextStep.parent().next().addClass('is-loading');
                jQuerythisNextStep.parent().next().addClass('flex-container').hide().fadeIn('slow');
                jQuerythisNextStep.parent().removeClass('flex-container').hide();
                jQuery(document).on('show-step-4', function() {
                    jQuerythisNextStep.parent().next().removeClass('is-loading');

                    jQuery('.calculator-steps a.active:last').next().addClass('completed');

                    // Adding Class Active To Show Steps Forward;
                    jQuery('.calculator-steps a.active:last').addClass('completed').next().addClass('active');
                    jQuery('.calculator-steps a.active:last').prev().removeClass('active');
                });
            } else{
                jQuerythisNextStep.parent().next().addClass('flex-container').hide().fadeIn('slow');
                jQuerythisNextStep.parent().removeClass('flex-container').hide();

                // Adding Class Active To Show Steps Forward;
                jQuery('.calculator-steps a.active:last').addClass('completed').next().addClass('active');
                jQuery('.calculator-steps a.active:last').prev().removeClass('active');
            }
        } else{
            //console.log('no match');
        }

    });
    jQuery(previousStep).click(function() {
        var jQuerythisPreviousStep = jQuery(this);

        if (jQuerythisPreviousStep.hasClass('exact')) {
            //console.log('back exact');

            // Function Runs On PREVIOUS Button Click
            jQuerythisPreviousStep.parent().prev().addClass('flex-container').hide().fadeIn('slow');
            jQuerythisPreviousStep.parent().removeClass('flex-container');
        } else if(jQuerythisPreviousStep.hasClass('exact-calc')){
            //console.log('back exact-calc');

            jQuerythisPreviousStep.closest('.calculator-step').siblings('.step-2-exact').addClass('flex-container').hide().fadeIn('slow');
            jQuerythisPreviousStep.closest('.calculator-step').removeClass('flex-container');

            // Removing Class Active To Show Steps Backward;
            jQuery('.calculator-steps a.active:last').prev().addClass('active');
            jQuery('.calculator-steps a.active:last').removeClass('active');
        } else if(jQuerythisPreviousStep.hasClass('estimate')){
            //console.log('back estimate');

            jQuerythisPreviousStep.closest('.calculator-step').siblings('.step-2').addClass('flex-container').hide().fadeIn('slow');
            jQuerythisPreviousStep.closest('.calculator-step').removeClass('flex-container');
        } else if(jQuerythisPreviousStep.parent().hasClass('is-sub')){
            //console.log('back sub');

            jQuerythisPreviousStep.parent().prev().addClass('flex-container').hide().fadeIn('slow');
            jQuerythisPreviousStep.parent().removeClass('flex-container');
        } else if(jQuerythisPreviousStep.parent().hasClass('is-main')) {
            //console.log('back main');

            // Function Runs On PREVIOUS Button Click
            jQuerythisPreviousStep.parent().prev().addClass('flex-container').hide().fadeIn('slow');
            jQuerythisPreviousStep.parent().removeClass('flex-container');
            // Removing Class Active To Show Steps Backward;
            jQuery('.calculator-steps a.active:last').prev().addClass('active');
            jQuery('.calculator-steps a.active:last').removeClass('active');
        } else{
            //console.log('no match');
        }

    });

    //change content
    var changeContent = jQuery('.change-data');
    jQuery(changeContent).click(function() {
        //console.log('change content');
        var jQuerythisChangeContent = jQuery(this);

        jQuery('.calculator-steps a.active:last').removeClass('active');

        if (jQuerythisChangeContent.hasClass('goto-step-1')) {
            //console.log('step 1');

            jQuerythisChangeContent.closest('.calculator-step').siblings('.step-1').addClass('flex-container').hide().fadeIn('slow');
            jQuerythisChangeContent.closest('.calculator-step').removeClass('flex-container');

            jQuery('.calculator-steps a.step-1').addClass('active');
        } else if(jQuerythisChangeContent.hasClass('goto-step-2')) {
            //console.log('step 2');

            jQuerythisChangeContent.closest('.calculator-step').siblings('.step-2').addClass('flex-container').hide().fadeIn('slow');
            jQuerythisChangeContent.closest('.calculator-step').removeClass('flex-container');

            jQuery('.calculator-steps a.step-2').addClass('active');
        } else if(jQuerythisChangeContent.hasClass('goto-step-2-exact')) {
            //console.log('step 2 exact');

            jQuerythisChangeContent.closest('.calculator-step').siblings('.step-2-exact').addClass('flex-container').hide().fadeIn('slow');
            jQuerythisChangeContent.closest('.calculator-step').removeClass('flex-container');

            jQuery('.calculator-steps a.step-2').addClass('active');
        } else if(jQuerythisChangeContent.hasClass('goto-step-2-estimate')) {
            //console.log('step 2 estimate');

            jQuerythisChangeContent.closest('.calculator-step').siblings('.step-2-estimate-people').addClass('flex-container').hide().fadeIn('slow');
            jQuerythisChangeContent.closest('.calculator-step').removeClass('flex-container');

            jQuery('.calculator-steps a.step-2').addClass('active');
        } else{
            //console.log('no match');
        }
    });

    //switch exact/estimate
    var dataRowExact = jQuery('.data-row.is-exact');
    var dataRowEstimate = jQuery('.data-row.is-estimate');
    var dataRowUsageExact = jQuery('.data-row.is-usage-exact');
    var dataRowUsageEstimate = jQuery('.data-row.is-usage-estimate');
    var checkExact = jQuery('.step-trigger.exact');
    var checkEstimate = jQuery('.step-trigger.estimate');

    jQuery(checkExact).click(function() {
        dataRowEstimate.hide();
        dataRowUsageEstimate.hide();
        dataRowExact.show();
        dataRowUsageExact.show();
    });

    jQuery(checkEstimate).click(function() {
        dataRowExact.hide();
        dataRowUsageExact.hide();
        dataRowEstimate.show();
        dataRowUsageEstimate.show();
    });

    //switch meter type
    var energyUsageWrapper = jQuery('.energy-usage');
    var dataRowExactSingle = jQuery('.overview-single-meter');
    var dataRowExactDouble = jQuery('.overview-double-meter');
    var meterTypeSingle = jQuery('.single-meter-switch');
    var meterTypeDouble = jQuery('.double-meter-switch');

    jQuery(meterTypeDouble).click(function() {
        energyUsageWrapper.removeClass('is-single');
        energyUsageWrapper.addClass('is-double');

        dataRowExactSingle.hide();
        dataRowExactDouble.show();
    });

    jQuery(meterTypeSingle).click(function() {
        energyUsageWrapper.removeClass('is-double');
        energyUsageWrapper.addClass('is-single');

        dataRowExactDouble.hide();
        dataRowExactSingle.show();
    });

    //switch steps
    var switchStep = jQuery('.calculator-steps a');
    jQuery(switchStep).click(function() {
        //console.log('switch step');
        var jQuerythisSwitchStep = jQuery(this);

        if (jQuerythisSwitchStep.hasClass('completed')) {

            jQuery('.calculator-steps a.active:last').removeClass('active');
            jQuery('.calculator-step').removeClass('flex-container');

            if (jQuerythisSwitchStep.hasClass('step-1')) {
                //console.log('step 1');

                jQuery('.calculator-step.step-1').addClass('flex-container').hide().fadeIn('slow');
                jQuery('.calculator-steps a.step-1').addClass('active');

            } else if(jQuerythisSwitchStep.hasClass('step-2')) {
                //console.log('step 2');

                jQuery('.calculator-step.step-2').addClass('flex-container').hide().fadeIn('slow');
                jQuery('.calculator-steps a.step-2').addClass('active');

            } else if(jQuerythisSwitchStep.hasClass('step-3')) {
                //console.log('step 3');

                jQuery('.calculator-step.step-3').addClass('flex-container').hide().fadeIn('slow');
                jQuery('.calculator-steps a.step-3').addClass('active');

            } else if(jQuerythisSwitchStep.hasClass('step-4')) {
                //console.log('step 4');

                jQuery('.calculator-step.step-4').addClass('flex-container').hide().fadeIn('slow');
                jQuery('.calculator-steps a.step-4').addClass('active');

            } else{
                //console.log('no match');
            }
        }
    });

    //sync people select and anchors
    var peopleAnchor = jQuery('div.estimate-people a');
    var peopleSelect = jQuery('select.estimate-people');


    // sync anchor when changing select
    jQuery(peopleSelect).change(function(event) {
        event.preventDefault();
        //Get option value
        var peopleValue = jQuery(this).val();

        //set active class on desktop link
        var peopleValueLink = jQuery("div.estimate-people a[data-radio-value='" + peopleValue + "']");
        peopleValueLink.addClass("active");
        peopleValueLink.siblings().removeClass("active");
    });

    // sync select when changing anchor
    jQuery(peopleAnchor).click(function(event) {
        event.preventDefault();
        jQuery(this).addClass("active");
        jQuery(this).siblings().removeClass("active");
        var peopleValue = jQuery(this).attr("data-radio-value");

        //set selected option on mobile select
        jQuery(peopleSelect).val(peopleValue);
    });

    //sync house select and anchors

    var houseAnchor = jQuery('div.estimate-house a');
    var houseSelect = jQuery('select.estimate-house');

    // sync anchor when changing select
    jQuery(houseSelect).change(function(event) {
        event.preventDefault();
        //Get option value
        var houseValue = jQuery(this).val();

        //set active class on desktop link
        var houseValueLink = jQuery("div.estimate-house a[data-radio-value='" + houseValue + "']");
        houseValueLink.addClass("active");
        houseValueLink.siblings().removeClass("active");
    });

    // sync select when changing anchor
    jQuery(houseAnchor).click(function(event) {
        event.preventDefault();
        jQuery(this).addClass("active");
        jQuery(this).siblings().removeClass("active");
        var houseValue = jQuery(this).attr("data-radio-value");

        //set selected option on mobile select
        jQuery(houseSelect).val(houseValue);
    });
});