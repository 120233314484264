/**
 * [Detect scrollbar width](http://davidwalsh.name/detect-scrollbar-width)
 */

function scrollbarMeasure() {
    // Create the measurement node
    var scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    document.body.appendChild(scrollDiv);
    // Get the scrollbar width
    var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    // Delete the DIV
    document.body.removeChild(scrollDiv);
    // Return scrollbar width
    return scrollbarWidth;
}
