jQuery(document).ready(function() {

    // Switch tab on select change
    jQuery(".tab-links select").change(function(event) {
        event.preventDefault();
        //Get option value
        var tab = jQuery(this).val();

        //set active class on desktop link
        var activeTabLink = jQuery(".tab-links a[href='" + tab + "']");
        activeTabLink.addClass("is-active");
        activeTabLink.siblings().removeClass("is-active");

        //activate tab content
        jQuery(".tab-content .entry-content").not(tab).css("display", "none");
        jQuery(tab).fadeIn();
    });

    // Switch select option on tab click
    jQuery(".tab-links a").click(function(event) {
        event.preventDefault();
        jQuery(this).addClass("is-active");
        jQuery(this).siblings().removeClass("is-active");
        var tab = jQuery(this).attr("href");

        //set selected option on mobile select
        jQuery(".tab-links select").val(tab);

        //activate tab content
        jQuery(".tab-content .entry-content").not(tab).css("display", "none");
        jQuery(tab).fadeIn();
    });

});