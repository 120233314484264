//Get function
//TODO: move to separate js file to use as general function
// var triggeredLogger = false;
//
// function sanitizeValue(value){
//     if(value.match(/^[0-9a-zA-Z\-]{1,25}$/)){
//         return value;
//     }
//     else{
//         if(triggeredLogger){
//             return '';
//         } else{
//             jQuery.ajax(themeUrl + '/lib/logger.php', {
//                 dataType: 'json',
//                 success: function(result) {
//                     //console.log(result);
//                 },
//                 error: function(result) {
//                     //console.log(result);
//                 }
//             });
//         }
//         triggeredLogger = true;
//         return '';
//     }
// }

var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameter,
        sParameterName,
        sParameterValue,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameter = sURLVariables[i].split('=');
        sParameterName = sParameter[0];
        sParameterValue = sParameter[1];

        if (sParameterName === sParam) {
            return sParameterValue === undefined ? true : sParameterValue;
        }
    }
};

function setFilterOptions(screenType){
    var filterType = '.filter-options.is-'+screenType;

    //provider
    var contractTypeTmobileParam = getUrlParameter('contract-provider-tmobile');
    var contractTypeBenParam = getUrlParameter('contract-provider-ben');
    var contractTypeLosParam = getUrlParameter('contract-provider-los');

    //brands
    var phoneBrandAppleParam = getUrlParameter('phone-brand-apple');
    var phoneBrandHuaweiParam = getUrlParameter('phone-brand-huawei');
    var phoneBrandSimOnlyParam = getUrlParameter('phone-brand-sim-only');
    var phoneBrandSonyParam = getUrlParameter('phone-brand-sony');

    //max month price
    var contractMaxMonthPrice = getUrlParameter('bundle-month-price');

    //deals
    var dealParam = getUrlParameter('deal');

    //data amount min/max
    //TODO: set get parameter
    // var contractDataMax = getUrlParameter('');
    // var contractDataMin = getUrlParameter('');

    //TODO: check if screentype is needed, since selections are synchronized between desktop and mobile
    //if(screenType === 'desktop') {
        //TODO: Handle parameters dynamically > set get array per filter

        //provider
        if(contractTypeTmobileParam !== undefined) {
            jQuery(filterType+' input#contract-provider-tmobile').prop('checked', true);
        }
        if(contractTypeBenParam !== undefined) {
            jQuery(filterType+' input#contract-provider-ben').prop('checked', true);
        }
        if(contractTypeLosParam !== undefined) {
            jQuery(filterType+' input#contract-provider-los').prop('checked', true);
        }

        //brands
        if(phoneBrandAppleParam !== undefined) {
            jQuery(filterType+' input#phone-brand-apple').prop('checked', true);
        }
        if(phoneBrandHuaweiParam !== undefined) {
            jQuery(filterType+' input#phone-brand-huawei').prop('checked', true);
        }
        if(phoneBrandSimOnlyParam !== undefined) {
            jQuery(filterType+' input#phone-brand-sim-only').prop('checked', true);
        }
        if(phoneBrandSonyParam !== undefined) {
            jQuery(filterType+' input#phone-brand-sony').prop('checked', true);
        }

        //max month price
        if(contractMaxMonthPrice !== undefined){
            jQuery(filterType+' input#bundle-month-price').val(contractMaxMonthPrice);
        }

        //deals
        if(dealParam !== undefined){
            jQuery(filterType+' input[value="'+dealParam+'"').prop('checked', true);
        }
    //}


}

function checkDataType(element, screenType) {
    var thingyChosen = false;
    var filterType = '.filter-options.is-' + screenType;
    var effectivelyChosen = false;
    // Desktop
    //if (screenType === 'desktop') {
        jQuery.each(jQuery(filterType + ' .contract-datatype input'), function (i, val) {
            //console.log(jQuery(val).is(':checked'));
            //console.log(jQuery(val).val());
            //console.log(jQuery(element).attr('data-type'));
            if (jQuery(val).is(':checked') && jQuery(element).attr('data-type') === jQuery(val).val()) {
                effectivelyChosen = true;
                //jQuery('.filter-options.is-mobile .contract-datatype select').val(jQuery(val).val());
            }
            if (jQuery(val).is(':checked')) {
                thingyChosen = true;
            }
        });
    //}

    if(thingyChosen === true){
        return effectivelyChosen;
    }
    return true;
}

function checkProvider(element, screenType){
    var thingyChosen = false;
    var filterType = '.filter-options.is-'+screenType;
    var effectivelyChosen = false;
    // Desktop
    if(screenType === 'desktop') {
        jQuery.each(jQuery(filterType+' .contract-provider input'), function (i, val) {
            if (jQuery(val).is(':checked') && jQuery(element).attr('data-provider') === jQuery(val).val()) {
                effectivelyChosen = true;
                jQuery('.filter-options.is-mobile .contract-provider select').val(jQuery(val).val());
            }
            if (jQuery(val).is(':checked')) {
                thingyChosen = true;
            }
        });
    }

    // Mobile
    if(screenType === 'mobile') {
        if (jQuery(filterType+' .contract-provider select').val() !== 'all') {
            thingyChosen = true;
            if (jQuery(element).attr('data-provider') === jQuery(filterType+' .contract-provider select').val()) {
                effectivelyChosen = true;
                jQuery('.filter-options.is-desktop .contract-provider input').prop('checked', false);
                jQuery('.filter-options.is-desktop .contract-provider input[value="'+jQuery(filterType+' .contract-provider select').val()+'"]').prop('checked', true);
            }
        }
    }

    if(thingyChosen === true){
        return effectivelyChosen;
    }
    return true;
}

function checkBrand(element, screenType){
    var thingyChosen = false;
    var filterType = '.filter-options.is-'+screenType;
    var effectivelyChosen = false;

    // Desktop
    if(screenType === 'desktop') {
        jQuery.each(jQuery('.filter-options.is-desktop .phone-brand input'), function (i, val) {
            if (jQuery(val).is(':checked') && jQuery(element).attr('data-brand') === jQuery(val).val()) {
                effectivelyChosen = true;
                jQuery('.filter-options.is-mobile .phone-brand select').val(jQuery(val).val());
            }
            if (jQuery(val).is(':checked')) {
                thingyChosen = true;
            }
        });
    }

    // Mobile
    if(screenType === 'mobile') {
        if (jQuery('.filter-options.is-mobile .phone-brand select').val() !== 'all') {
            thingyChosen = true;
            if (jQuery(element).attr('data-brand') === jQuery('.filter-options.is-mobile .phone-brand select').val()) {
                effectivelyChosen = true;
                jQuery('.filter-options.is-desktop .phone-brand input').prop('checked', false);
                jQuery('.filter-options.is-desktop .phone-brand input[value="'+jQuery('.filter-options.is-mobile .phone-brand select').val()+'"]').prop('checked', true);
            }
        }
    }

    if(thingyChosen === true){
        return effectivelyChosen;
    }
    return true;
}

function checkColor(element, screenType){
    var thingyChosen = false;
    var filterType = '.filter-options.is-'+screenType;
    var effectivelyChosen = false;

    var colors = eval(jQuery(element).attr('data-colors')); // jshint ignore:line

    jQuery.each(colors, function(j, tileVal) {
        // Desktop
        if (screenType === 'desktop') {
            jQuery.each(jQuery('.filter-options.is-desktop .phone-color input'), function (i, val) {
                var input = Number( jQuery(val).val() );
                if (jQuery(val).is(':checked') && tileVal === input) {
                    effectivelyChosen = true;
                    jQuery('.filter-options.is-mobile .phone-color select').val(input);
                }
                if (jQuery(val).is(':checked')) {
                    thingyChosen = true;
                }
            });
        }

        // Mobile
        if (screenType === 'mobile') {
            if (jQuery('.filter-options.is-mobile .phone-color select').val() !== 'all') {
                thingyChosen = true;
                if (tileVal === Number(jQuery('.filter-options.is-mobile .phone-color select').val())) {
                    effectivelyChosen = true;
                    jQuery('.filter-options.is-desktop .phone-color input').prop('checked', false);
                    jQuery('.filter-options.is-desktop .phone-color input[value="' + jQuery('.filter-options.is-mobile .phone-color select').val() + '"]').prop('checked', true);
                }
            }
        }
    });

    if(thingyChosen === true){
        return effectivelyChosen;
    }
    return true;
}

function checkSubscription(element, screenType){
    var thingyChosen = false;
    var effectivelyChosen = false;

    // Desktop
    if(screenType === 'desktop') {
        jQuery.each(jQuery('.filter-options.is-desktop .contract-type input'), function (i, val) {
            if (jQuery(val).is(':checked') && jQuery(element).attr('data-customer-type') === jQuery(val).val()) {
                effectivelyChosen = true;
            }
            if (jQuery(val).is(':checked')) {
                thingyChosen = true;
            }
        });
    }

    if(thingyChosen === true){
        return effectivelyChosen;
    }
    return true;
}

function checkDeals(element, screenType){
    var thingyChosen = false;
    var effectivelyChosen = false;
    var filterType = '.filter-options.is-' + screenType;

    // Desktop
    //if(screenType === 'desktop') {
        jQuery.each(jQuery(filterType + ' .contract-deals input'), function (i, val) {
            if (jQuery(val).is(':checked') && jQuery(element).attr('data-deal') === jQuery(val).val()) {
                effectivelyChosen = true;
            }
            if (jQuery(val).is(':checked')) {
                thingyChosen = true;
            }
        });
    //}

    if(thingyChosen === true){
        return effectivelyChosen;
    }
    return true;
}

function checkPricePerMonth(element, screenType){
    var filterType = '.filter-options.is-'+screenType;
    var price = Number( jQuery(element).attr('data-base-price') );
    var filterPrice = Number( jQuery(filterType+' input#bundle-month-price').val() );
    return (price <= filterPrice);
}

function checkDataBundles(element, screenType){
    var effectivelyChosen = false;
    var filterType = '.filter-options.is-'+screenType;
    var possibilities = eval(jQuery(element).attr('data-possibilities')); // jshint ignore:line

    jQuery.each(possibilities, function(j, tileVal){
        if (tileVal >= jQuery(filterType+' select#bundle-data-min').val() && tileVal <= jQuery(filterType+' select#bundle-data-max').val()) {
            effectivelyChosen = true;
        }
    });

    var desktopDataMin = jQuery( '.filter-options.is-desktop select#bundle-data-min');
    var desktopDataMinValue = desktopDataMin.val();
    var desktopDataMax = jQuery( '.filter-options.is-desktop select#bundle-data-max');
    var desktopDataMaxValue = desktopDataMax.val();
    var mobileDataMin = jQuery( '.filter-options.is-mobile select#bundle-data-min');
    var mobileDataMinValue = mobileDataMin.val();
    var mobileDataMax = jQuery( '.filter-options.is-mobile select#bundle-data-max');
    var mobileDataMaxValue = mobileDataMax.val();

    if(screenType === 'mobile'){
        desktopDataMin.val(mobileDataMinValue);
        desktopDataMax.val(mobileDataMaxValue);
    }
    if(screenType === 'desktop'){
        mobileDataMin.val(desktopDataMinValue);
        mobileDataMax.val(desktopDataMaxValue);
    }

    return effectivelyChosen;
}

function checkVoiceBundles(element, screenType){
    var effectivelyChosen = false;
    var filterType = '.filter-options.is-'+screenType;
    var possibilities = eval(jQuery(element).attr('voice-possibilities')); // jshint ignore:line
    var tileProvider = element.attr('data-provider');
    var voiceMin = parseInt(jQuery(filterType+' select#bundle-voice-min').val());
    var voiceMax = parseInt(jQuery(filterType+' select#bundle-voice-max').val());
    var smsMin = parseInt(jQuery(filterType+' select#bundle-sms-min').val());
    var smsMax = parseInt(jQuery(filterType+' select#bundle-sms-max').val());

    jQuery.each(possibilities, function(j, tileVal){
        // console.log(tileProvider);
        // console.log(tileVal);
        // console.log('min');
        //console.log(voiceMin);
        //console.log(smsMin);
        // console.log(voiceMin + smsMin);
        // console.log('max');
        // console.log(voiceMax);
        // console.log(smsMax);
        // console.log(voiceMax + smsMax);

        if(tileProvider === 'ben' && voiceMin !== 999999 && smsMin !== 999999){
            if (tileVal >= (voiceMin + smsMin) &&
                tileVal <= (voiceMax + smsMax)
            ) {
                effectivelyChosen = true;
            }
        } else{
            if (tileVal >= voiceMin && tileVal <= voiceMax
            ) {
                effectivelyChosen = true;
            }
        }
    });

    var desktopVoiceMin = jQuery( '.filter-options.is-desktop select#bundle-voice-min');
    var desktopVoiceMinValue = desktopVoiceMin.val();
    var desktopVoiceMax = jQuery( '.filter-options.is-desktop select#bundle-voice-max');
    var desktopVoiceMaxValue = desktopVoiceMax.val();
    var mobileVoiceMin = jQuery( '.filter-options.is-mobile select#bundle-voice-min');
    var mobileVoiceMinValue = mobileVoiceMin.val();
    var mobileVoiceMax = jQuery( '.filter-options.is-mobile select#bundle-voice-max');
    var mobileVoiceMaxValue = mobileVoiceMax.val();

    if(screenType === 'mobile'){
        desktopVoiceMin.val(mobileVoiceMinValue);
        desktopVoiceMax.val(mobileVoiceMaxValue);
    }
    if(screenType === 'desktop'){
        mobileVoiceMin.val(desktopVoiceMinValue);
        mobileVoiceMax.val(desktopVoiceMaxValue);
    }

    return effectivelyChosen;
}

function checkSmsBundles(element, screenType){
    var effectivelyChosen = false;
    var filterType = '.filter-options.is-'+screenType;
    var possibilities = eval(jQuery(element).attr('sms-possibilities')); // jshint ignore:line
    var tileProvider = element.attr('data-provider');
    var voiceMin = parseInt(jQuery(filterType+' select#bundle-voice-min').val());
    var voiceMax = parseInt(jQuery(filterType+' select#bundle-voice-max').val());
    var smsMin = parseInt(jQuery(filterType+' select#bundle-sms-min').val());
    var smsMax = parseInt(jQuery(filterType+' select#bundle-sms-max').val());

    jQuery.each(possibilities, function(j, tileVal){
        if (tileVal >= jQuery(filterType+' select#bundle-sms-min').val() && tileVal <= jQuery(filterType+' select#bundle-sms-max').val()) {
            effectivelyChosen = true;
        }

        if(tileProvider === 'ben' && voiceMin !== 999999 && smsMin !== 999999){
            if (tileVal >= (voiceMin + smsMin) &&
                tileVal <= (voiceMax + smsMax)
            ) {
                effectivelyChosen = true;
            }
        } else{
            if (tileVal >= smsMin && tileVal <= smsMax
            ) {
                effectivelyChosen = true;
            }
        }
    });



    var desktopSmsMin = jQuery( '.filter-options.is-desktop select#bundle-sms-min');
    var desktopSmsMinValue = desktopSmsMin.val();
    var desktopSmsMax = jQuery( '.filter-options.is-desktop select#bundle-sms-max');
    var desktopSmsMaxValue = desktopSmsMax.val();
    var mobileSmsMin = jQuery( '.filter-options.is-mobile select#bundle-sms-min');
    var mobileSmsMinValue = mobileSmsMin.val();
    var mobileSmsMax = jQuery( '.filter-options.is-mobile select#bundle-sms-max');
    var mobileSmsMaxValue = mobileSmsMax.val();

    if(screenType === 'mobile'){
        desktopSmsMin.val(mobileSmsMinValue);
        desktopSmsMax.val(mobileSmsMaxValue);
    }
    if(screenType === 'desktop'){
        mobileSmsMin.val(desktopSmsMinValue);
        mobileSmsMax.val(desktopSmsMaxValue);
    }

    return effectivelyChosen;
}

function runChecks(screenType){
    //console.time('runChecks');

    var results = [];
    var filterType = '.filter-options.is-'+screenType;
    var tileCount = 0;
    var emptyFilterDiv = jQuery('.filter-result .empty-filter');

    /**
     * Run applicable filters
     */
    jQuery.each(jQuery('.offer-wrapper div.tile'), function(i, val){
        results[i] = [];

        /**
         * Type of subscription // Consumer or business
         */
        if(typeof jQuery(filterType+' .contract-type').html() !== 'undefined') {
            results[i].push(checkSubscription(jQuery(val), screenType));
            //console.log('checkSubscription');
        }
        /**
         * Type deals // JBL
         */
        if(typeof jQuery(filterType+' .contract-deals').html() !== 'undefined') {
            results[i].push(checkDeals(jQuery(val), screenType));
            //console.log('checkSubscription');
        }
        /**
         * Type contract
         */
        if(typeof jQuery(filterType+' .contract-provider').html() !== 'undefined') {
            results[i].push(checkProvider(jQuery(val), screenType));
            //console.log('checkProvider');
        }
        /**
         * Type contract
         */
        if(typeof jQuery(filterType+' .contract-datatype').html() !== 'undefined') {
            results[i].push(checkDataType(jQuery(val), screenType));
            //console.log('checkDataType');
        }
        /**
         * Prices
         */
        if(typeof jQuery(filterType+' .bundle-month-price').html() !== 'undefined' && jQuery(filterType+' input#bundle-month-price').val() !== ''){
            results[i].push(checkPricePerMonth(jQuery(val), screenType));
            //console.log('checkPricePerMonth');
        }
        /**
         * Data bundles
         */
        if(typeof jQuery(filterType+' .bundle-data').val() !== 'undefined' && (jQuery(filterType+' select#bundle-data-min').val() !== '0' || jQuery(filterType+' select#bundle-data-max').val() !== '999999')){
            results[i].push(checkDataBundles(jQuery(val), screenType));
            //console.log('checkDataBundles');
        }
        /**
         * Voice bundles
         */
        if(typeof jQuery(filterType+' .bundle-voice').val() !== 'undefined' && (jQuery(filterType+' select#bundle-voice-min').val() !== '0' || jQuery(filterType+' select#bundle-voice-max').val() !== '999999')){
            results[i].push(checkVoiceBundles(jQuery(val), screenType));
            //console.log('checkVoiceBundles');
        }
        /**
         * SMS bundles
         */
        if(typeof jQuery(filterType+' .bundle-sms').val() !== 'undefined' && (jQuery(filterType+' select#bundle-sms-min').val() !== '0' || jQuery(filterType+' select#bundle-sms-max').val() !== '999999')){
            results[i].push(checkSmsBundles(jQuery(val), screenType));
            //console.log('checkSmsBundles');
        }
        /**
         * Brands
         */
        if(typeof jQuery(filterType+' .phone-brand').html() !== 'undefined'){
            results[i].push(checkBrand(jQuery(val), screenType));
            //console.log('checkBrand');
        }
        /**
         * Colors
         */
        if(typeof jQuery(filterType+' .phone-color').html() !== 'undefined'){
            results[i].push(checkColor(jQuery(val), screenType));
            //console.log('checkColor');
        }
    });


    /**
     * Show/Hide tiles
     */
    //console.log(results);

    jQuery.each(results, function(i, val){
        var element = jQuery('.offer-wrapper div.tile').get(i);
        if(val.indexOf(false) !== -1){

            //Remove grid classes for invisible elements
            jQuery(element).removeClass('is-second is-third is-fourth');

            //Hide element
            jQuery(element).hide();
        }
        else{
            //Reset grid classes for visible elements
            jQuery(element).removeClass('is-second is-third is-fourth');

            //Increment tile counter
            tileCount++;

            //Set grid classes for visible elements
            var isSecondClass = '';
            var isThirdClass = '';
            var isFourthClass = '';
            if (tileCount % 2 === 0){
                isSecondClass = 'is-second';
            }
            if (tileCount % 3 === 0){
                isThirdClass = 'is-third';
            }
            if (tileCount % 4 === 0){
                isFourthClass = 'is-fourth';
            }
            jQuery(element).addClass([isSecondClass, isThirdClass, isFourthClass].join(' '));

            //Show element
            jQuery(element).show();
        }
    });

    if(tileCount === 0){
        emptyFilterDiv.removeClass('is-hidden');
    } else{
        emptyFilterDiv.addClass('is-hidden');
    }

    //console.timeEnd('runChecks');
}

jQuery(document).ready(function(){

    if(jQuery('body').hasClass('is-mq-desktop')){
        setFilterOptions('desktop');
        runChecks('desktop');
    } else{
        setFilterOptions('mobile');
        runChecks('mobile');
    }

    jQuery('form.filter-options.is-desktop input, form.filter-options.is-desktop select').change(function(){
        runChecks('desktop');
        jQuery.fn.matchHeight._update();
    });
    jQuery('form.filter-options.is-mobile input, form.filter-options.is-mobile select').change(function(){
        runChecks('mobile');
        jQuery.fn.matchHeight._update();
    });

    var filterOptions = jQuery('form.filter-options');

    jQuery('select[name=sorter-type]').change(function(){
        filterOptions.append('<input name="sorter-type" value="'+jQuery(this).val()+'" type="hidden" />');
        filterOptions.submit();
        jQuery.fn.matchHeight._update();
    });

    //match image height
    jQuery('.filter-result .tile:visible .offer-image').matchHeight();

    //match tile height (to fix subpixel rendering issues)
    jQuery('.filter-result .tile:visible').matchHeight();
});
