var iltForm = $("#ilt-form"),
    iltResponse = $('#ilt-response'),
    livingSituationQuestion = $("#ilt-living-situation"), // gezinssamenstelling
    netIncomeQuestion = $("#ilt-net-income"), // netto maandinkomen
    livingCostQuestion = $("#ilt-living-cost"), // maandelijke huur / netto hypotheek
    calculationTrigger = $("#ilt-calculate"), // calculate maximum monthly credit
    livingCostNorm = 223, // minimum woonlasten NIBUD
    baseSpendingNorm = { // basisnorm uitgaven VFN
        single : 797,
        single_with_children : 908,
        together : 1244,
        together_with_children : 1246
    },
    creditCkiTotal = 0, // totale kredietbedrag in CKI,
    creditCkiModifier = 0.02, // kredietbedrag percentage
    creditCkiNorm = creditCkiTotal * creditCkiModifier, // kredietnorm CKI
    incomeDependentModifier = 0.15, // inkomensafhankelijke opslag percentage
    incomeDependentSurplus, // inkomensafhankelijke opslag [incomeDependentModifier*(netIncome - baseSpendingNorm[livingSituation] - livingCostNorm)]
    incomeDependentSurplusRaw, // raw version without rounding and two decimal formatting
    loanNorm, // lening normering [baseSpendingNorm[livingSituation] + incomeDependentSurplus]
    maxMonthlyPhoneCredit, // maximale toestelkrediet per maand [netIncome - livingCost - creditNorm]
    maxMonthlyPhoneCreditRaw; // raw version without rounding and two decimal formatting

calculationTrigger.on('click', function(e){
    e.preventDefault();
    //console.clear();

    var responseText = '',
        responseHeader = '',
        responseClass = '';

    var livingSituation = livingSituationQuestion.find('option:selected').attr('data-name'),
        netIncome = parseFloat(netIncomeQuestion.val()),
        livingCost = parseFloat(livingCostQuestion.val());

    if(isNaN(netIncome) || isNaN(livingCost)){
        responseHeader = 'Controleer je gegevens';
        responseText += '<ul class="is-list">';
        responseClass = 'is-error';
        if(isNaN(netIncome)){
            //console.warn('je hebt geen geldig inkomen ingevuld');
            responseText += '<li>Je hebt geen geldig inkomen ingevuld</li>';
        }
        if(isNaN(livingCost)){
            //console.warn('je hebt geen geldige woonlasten ingevuld');
            responseText += '<li>Je hebt geen geldige woonlasten ingevuld</li>';
        }
        responseText += '</ul>';
    } else{
        incomeDependentSurplusRaw = incomeDependentModifier*(netIncome - baseSpendingNorm[livingSituation] - livingCostNorm);
        incomeDependentSurplus = Math.round(incomeDependentSurplusRaw * 100) / 100;

        loanNorm = baseSpendingNorm[livingSituation] + incomeDependentSurplus;

        maxMonthlyPhoneCreditRaw = netIncome - livingCost - loanNorm - creditCkiNorm;
        maxMonthlyPhoneCredit = Math.round(maxMonthlyPhoneCreditRaw);

        if(maxMonthlyPhoneCredit >= 50){
            responseClass = 'is-success';
            responseHeader = 'Goed nieuws';
            responseText = 'Je hebt voldoende bestedingsruimte en kan waarschijnlijk zonder problemen een toestelkrediet aangaan. Wanneer je daadwerkelijk een abonnement met toestel neemt, zijn we verplicht om je nog een keer de toets te laten doen.';
        } else if(maxMonthlyPhoneCredit > 0){
            responseClass = 'is-info';
            responseHeader = 'Maximaal toestelkrediet: &euro; '+maxMonthlyPhoneCredit+' per maand.';
            responseText = 'Nu weet je hoeveel ruimte je hebt voor een extra krediet voor het toestel. Hier kun je rekening mee houden als je een abonnement met toestel neemt. Wanneer je daadwerkelijk een abonnement met toestel neemt, zijn we verplicht om je nog een keer de toets te laten doen.';
        } else{
            responseClass = 'is-warning';
            responseHeader = 'Betaal het toestel in één keer of neem een Sim Only';
            responseText = 'Je hebt niet genoeg ruimte om een extra krediet voor je toestel erbij te nemen. Natuurlijk kun je wel het toestel direct betalen bij het abonnement, of een Sim Only nemen.';
        }

        // console.warn('!--START INKOMENS- EN LASTENTOETS--!');
        //
        // console.info('huishouden: '+livingSituation);
        // console.info('je inkomen: '+netIncome);
        // console.info('woonlasten: '+livingCost);
        // console.log('\n');
        //
        // console.info('Inkomensafh. opsl.: '+incomeDependentSurplus);
        // console.log(' berekening:        '+incomeDependentModifier+' * ('+netIncome+' - '+baseSpendingNorm[livingSituation]+' - '+livingCostNorm+') = '+incomeDependentSurplus);
        // console.log('  uitleg:           opslagpercentage * (inkomen - basisnorm - norm woonlasten) = inkomensafhankelijke opslag');
        // console.log('\n');
        //
        // console.info('Leennorm:           '+loanNorm);
        // console.log(' berekening:        '+baseSpendingNorm[livingSituation]+' + '+incomeDependentSurplus+' = '+loanNorm);
        // console.log('  uitleg:           basisnorm + inkomensafhankelijke opslag = leennorm');
        // console.log('\n');
        //
        // console.info('Kredietnorm CKI:    '+creditCkiNorm);
        // console.log(' berekening:        '+creditCkiTotal+' * '+creditCkiModifier+' = '+creditCkiNorm);
        // console.log('  uitleg:           totale kredietbedrag in CKI * kredietbedrag percentage = kredietnorm CKI');
        // console.log('\n');
        //
        // console.info('Toestelkrediet:     '+maxMonthlyPhoneCreditRaw);
        // console.log(' berekening:        '+netIncome+' - '+livingCost+' - '+loanNorm+' - '+creditCkiNorm+' = '+maxMonthlyPhoneCredit);
        // console.log('  uitleg:           inkomen - woonlasten - leennorm - kredietnorm CKI = toestelkrediet');
        // console.log('\n');
        //
        // console.info('Maximale maandelijkse betaling: '+maxMonthlyPhoneCredit);
        // console.log('\n');
        //
        // console.warn('!--EIND INKOMENS- EN LASTENTOETS--!');
        // console.log('\n');
    }

    iltResponse.find('.entry-content').removeClass('is-success is-info is-warning is-error').addClass(responseClass).html('<h3>'+responseHeader+'</h3><p>'+responseText+'</p>');
    iltResponse.removeClass('is-hidden');
});

(function ( $ ) {
    $.fn.keyActionHandler = function(options) {
        // Extend our default options with those provided.
        // Note that the first argument to extend is an empty
        // object – this is to keep from overriding our "defaults" object.
        var opts = $.extend( {}, $.fn.keyActionHandler.defaults, options ),
            $this = $(this);

        $this.on("keyup", function(e){
            if (e.which === opts.code){
                switch(opts.type){
                    case 'submit':
                        e.preventDefault();
                        calculationTrigger.trigger('click');
                        break;
                    default:
                        console.log('keyActionHandler: type "'+opts.type+'" is not assigned, please add a case');
                }
            }
        });

        return this;
    };

    // Plugin defaults – added as a property on our plugin function.
    $.fn.keyActionHandler.defaults = {
        code: 13, //enter
        trigger: "keyup",
        type: 'submit'
    };
}( jQuery ));

iltForm.keyActionHandler();
