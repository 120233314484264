var routingScrolltofixed = {
    // Scrolltofixed demo page
    'scrolltofixed': {
        init: function() {
            // JavaScript to be fired on the about us page

            // Scrolltofixed
            jQuery('.sidebar-mobile').scrollToFixed( {
                bottom: 0,
                maxWidth: (767 - scrollbarMeasure()),
                limit: function () {
                    // Limit without compensating for wrapper margin used in the demo
                    //var limit = jQuery('footer').offset().top - jQuery('.sidebar-mobile').outerHeight(true);
                    var limit = jQuery('footer').offset().top - jQuery('.sidebar-mobile').outerHeight(true) - parseFloat(jQuery('.sidebar-wrapper').css("margin-bottom"));
                    return limit;
                },
                zIndex: 1000
            });
            jQuery('.sidebar').scrollToFixed({
                marginTop: function() {
                    var marginTop = jQuery(window).height() - jQuery('.sidebar').outerHeight(true) - 20;
                    if (marginTop >= 0) {return jQuery('header').outerHeight();}
                    return marginTop;
                },
                minWidth: (768 - scrollbarMeasure()),
                limit: function () {
                    var limit = jQuery('footer').offset().top - jQuery('.sidebar').outerHeight(true);
                    return limit;
                },
                zIndex: 999
            });
            //console.log('I am scrolltofixed!');
        },
        finalize: function() {
            // JavaScript to be fired on the home page, after the init JS
        }
    }
};