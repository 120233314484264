// Add media query classes to body

//For specific MQ JS target purposes
enquire.register("screen and (max-width:27.9375em)", {
    match : function() {
        jQuery('body').addClass('is-mq-xxs');
    },
    unmatch : function() {
        jQuery('body').removeClass('is-mq-xxs');
    }
});
enquire.register("screen and (min-width:28em) and (max-width:35.9375em)", {
    match : function() {
        jQuery('body').addClass('is-mq-xs');
    },
    unmatch : function() {
        jQuery('body').removeClass('is-mq-xs');
    }
});
enquire.register("screen and (min-width:36em) and (max-width:47.9375em)", {
    match : function() {
        jQuery('body').addClass('is-mq-sm');
    },
    unmatch : function() {
        jQuery('body').removeClass('is-mq-sm');
    }
});
enquire.register("screen and (min-width:48em) and (max-width:63.9375em)", {
    match : function() {
        jQuery('body').addClass('is-mq-md');
    },
    unmatch : function() {
        jQuery('body').removeClass('is-mq-md');
    }
});
enquire.register("screen and (min-width:64em) and (max-width:79.9375em)", {
    match : function() {
        jQuery('body').addClass('is-mq-lg');
    },
    unmatch : function() {
        jQuery('body').removeClass('is-mq-lg');
    }
});
enquire.register("screen and (min-width:80em)", {
    match : function() {
        jQuery('body').addClass('is-mq-xl');
    },
    unmatch : function() {
        jQuery('body').removeClass('is-mq-xl');
    }
});


//For device width JS target purposes
enquire.register("screen and (max-width:47.9375em)", {
    match : function() {
        jQuery('body').addClass('is-mq-mobile');
    },
    unmatch : function() {
        jQuery('body').removeClass('is-mq-mobile');
    }
});
enquire.register("screen and (min-width:48em)", {
    match : function() {
        jQuery('body').addClass('is-mq-desktop');
    },
    unmatch : function() {
        jQuery('body').removeClass('is-mq-desktop');
    }
});


//Devices widths (for tracking)
enquire.register("screen and (max-width:35.9375em)", {
    match : function() {
        jQuery('body').addClass('is-small-device');
    },
    unmatch : function() {
        jQuery('body').removeClass('is-small-device');
    }
});
enquire.register("screen and (min-width:36em) and (max-width:63.9375em)", {
    match : function() {
        jQuery('body').addClass('is-medium-device');
    },
    unmatch : function() {
        jQuery('body').removeClass('is-medium-device');
    }
});