/**
 * Scrollcheck
 */

function mainLabelMenuClose(){
    //console.log('mainLabelMenuClose');
    var labelMenuTrigger = jQuery(".labels .categories");
    var labelMenu = jQuery(".category-menu.is-desktop");

    labelMenuTrigger.find('> .fa').removeClass('fa-caret-up');
    labelMenuTrigger.find('> .fa').addClass('fa-caret-down');
    labelMenu.slideUp(200, 'swing');
    labelMenuTrigger.removeClass('is-open');
    labelMenuTrigger.addClass('is-closed');
}

var startScroll,
    toHandler,
    endScroll,
    scrollDistance,
    scrollTrigger;

function scrollCheck(triggerDistance, triggerFunction) {
    scrollTrigger = false;
    if (!startScroll) {
        startScroll = jQuery(window).scrollTop();
    } else {
        triggerDistance = typeof triggerDistance !== 'undefined' ? triggerDistance : 50;
        triggerFunction = typeof triggerFunction !== 'undefined' ? triggerFunction : '';
        var triggerDistanceNegative = -Math.abs(triggerDistance);

        if (toHandler) { 
            clearTimeout(toHandler);
        }
        toHandler = setTimeout(function () {
            endScroll = jQuery(window).scrollTop();
            scrollDistance = endScroll - startScroll;
            // console.log("Ended :"+endScroll);
            // console.log("Scrolled "+scrollDistance);

            if ((endScroll - startScroll) > triggerDistance || (endScroll - startScroll) < triggerDistanceNegative) {
                scrollTrigger = true;
                //console.log('scrolled more than 50px');
                if(triggerFunction === 'mainLabelMenuClose'){
                    mainLabelMenuClose();
                }
            }

            //return scrollTrigger; 
            startScroll = 0;

        }, 200);
    }
}

var bodyElement = jQuery('body');
jQuery(window).on("scroll",function(){

    if(!bodyElement.hasClass('is-scrolled')){ 
        bodyElement.addClass('is-scrolled');
    }
    scrollCheck(20, 'mainLabelMenuClose');
});