/**
 * Created by Peter on 2-6-2016.
 */
jQuery(document).ready(function() {

    function alphabetToNumber(string){
        string = string.toLowerCase();
        var value = '';
        var ibanModifier = 9;
        for (var i = 0, len = string.length; i < len; i++) {
            value += (string[i].charCodeAt(0) - 96) + ibanModifier;
        }
        return value;
    }

    function pad (str, max) {
        str = str.toString();
        return str.length < max ? pad("0" + str, max) : str;
    }

    function modulo (divident, divisor) {
        var cDivident = '';
        var cRest = '';

        for (var i in divident ) {
            var cChar = divident[i];
            var cOperator = cRest + '' + cDivident + '' + cChar;

            if ( cOperator < parseInt(divisor) ) {
                cDivident += '' + cChar;
            } else {
                cRest = cOperator % divisor;
                if ( cRest === 0 ) {
                    cRest = '';
                }
                cDivident = '';
            }
        }
        cRest += '' + cDivident;
        if (cRest === '') {
            cRest = 0;
        }
        return cRest;
    }

    function ibanCheckSumCalc(ibanCheckNumber){
        var ibanModulo = 97;
        var ibanFixedNo = 98;
        var ibanCheckNumberRaw1 = ibanCheckNumber + '00';
        var ibanCheckNumberRaw2 = modulo(ibanCheckNumberRaw1,ibanModulo);
        ibanCheckNumber = ibanFixedNo - ibanCheckNumberRaw2;

        return ibanCheckNumber; 
    }

    function convertIban(countryCode, bankCode, accountNoRaw){
        var accountNo = accountNoRaw;
        if(accountNoRaw.length < 10){
            accountNo = pad(accountNoRaw, 10);
        } else if(accountNoRaw.length > 10){
            accountNo = accountNoRaw.substring(accountNoRaw.length -10, accountNoRaw.length);
        }
        var bankCodeNumber = alphabetToNumber(bankCode);
        var countryCodeNumber = alphabetToNumber(countryCode);
        var ibanCheckRaw = bankCodeNumber+accountNo+countryCodeNumber;
        var ibanCheckNumberRaw = ibanCheckSumCalc(ibanCheckRaw);
        var ibanCheckNumber = pad(ibanCheckNumberRaw, 2);

        var ibanNumber = countryCode+ibanCheckNumber+bankCode+accountNo;

        return ibanNumber;
    }
    
    jQuery('.iban-calculator').click(function () {
        var bankCodeField = jQuery('.form-account-bank-code');
        var bankCodeValue = jQuery('#account_bank_code option:selected').val();
        var accountNoField = jQuery('.form-account-number-old');
        var accountNoValue = jQuery('#account_number_old').val();

        bankCodeField.removeClass('has-error');
        bankCodeField.find('.is-input').removeClass('form-hint-wrapper');
        bankCodeField.find('.is-input .form-hint').remove();

        accountNoField.removeClass('has-error');
        accountNoField.find('.is-input').removeClass('form-hint-wrapper');
        accountNoField.find('.is-input .form-hint').remove();

        if(bankCodeValue === ''){
            //console.log('fill in your bank');

            bankCodeField.addClass('has-error');
            bankCodeField.find('.is-input').addClass('form-hint-wrapper');
            bankCodeField.find('.is-input').append('<span class="form-hint is-always-visible">Kies je bank</span>');
        }

        if(accountNoValue === ''){
            //console.log('fill in your number');

            accountNoField.addClass('has-error');
            accountNoField.find('.is-input').addClass('form-hint-wrapper');
            accountNoField.find('.is-input').append('<span class="form-hint is-always-visible">Vul je oude rekeningnummer in</span>');
        }

        if(bankCodeValue !== '' && accountNoValue !== ''){
            var convertedIBAN = convertIban('NL', bankCodeValue, accountNoValue);
            var ibanAPIField = jQuery('.form-account input');
            ibanAPIField.val(convertedIBAN);

            //Highlight monthly total row for mobile
            ibanAPIField.addClass("is-highlight").delay(750).queue(function () {
                ibanAPIField.removeClass("is-highlight").dequeue();
            });

            jQuery(this).closest(".expander-content").hide(750);
            jQuery(this).closest(".expander-content").siblings(".expander-trigger").addClass("expander-hidden");
        }



    });

});