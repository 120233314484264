var routingServicePage = {
    // GSM pages
    'template_service_page': {
        init: function() {
            // JavaScript to be fired on the home page
            //console.log('I am initially gsm offer!');

            //set active class
            jQuery(function () {
                setNavigation('.service-provider-menu ul');
            });
        },
        finalize: function() {
            // JavaScript to be fired on the home page, after the init JS
            //console.log('I am finally gsm offer!');
        }
    }
};