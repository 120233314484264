/*
 Operating hours check
 =====================================
 */

var hoursMorning = [
    // Sunday
    {
        allday: false
    }, // Closed all day
    // Monday
    {
        open: '09:00',
        close: '12:00'
    },

    // Tuesday
    {
        open: '09:00',
        close: '12:00'
    },

    // Wednesday
    {
        open: '09:00',
        close: '12:00'
    },

    // Thursday
    {
        open: '09:00',
        close: '12:00'
    },

    // Friday
    {
        open: '09:00',
        close: '12:00'
    },

    // Saturday
    {
        allday: false
    } // Closed all day
];

var hoursAfternoon = [
    // Sunday
    {
        allday: false
    }, // Closed all day
    // Monday
    {
        open: '13:00',
        close: '15:00'
    },

    // Tuesday
    {
        open: '13:00',
        close: '15:00'
    },

    // Wednesday
    {
        open: '13:00',
        close: '15:00'
    },

    // Thursday
    {
        open: '13:00',
        close: '15:00'
    },

    // Friday
    {
        open: '13:00',
        close: '15:00'
    },

    // Saturday
    {
        allday: false
    } // Closed all day
];

// Arrays of holiday dates, format is [year, month,day]
var holidays =
    [
        [2015,  1, 1], [2015,  4, 3], [2015,  4, 5], [2015,  4, 6], [2015,  5, 24], [2015,  5, 25], [2015,  12, 25], [2015,  12, 26],
        [2016, 1, 1], [2016, 3, 27], [2016, 3, 28], [2016, 4, 27], [2016, 5, 5], [2016, 5, 15], [2016, 5, 16], [2016, 12, 25], [2016, 12, 26],
        [2017, 1, 1], [2017, 4, 17], [2017, 4, 27], [2017, 5, 25], [2017, 5, 26], [2017, 6, 5], [2017, 12, 25], [2017, 12, 26]
    ];

function isHoliday(holidays) {
    var now = new Date(),
        thisDay = now.getDate(),
        thisMonth = now.getMonth() + 1,
        thisYear = now.getFullYear(),
        holiday = false;

    jQuery.each(jQuery(holidays), function( index, value ) {
        var hYear = value[0],
            hMonth = value[1],
            hDay = value[2];

        if(
            hYear === thisYear &&
            hMonth === thisMonth &&
            hDay === thisDay
        ){
            holiday = true ;
        }
    });

    return holiday;
}

/*
 Check if "now" is within operating hours
 */
function checkOpenHours(now, operatingDate, open, close) {
    // Splitting times to array
    var o = open.split(":"),
        c = close.split(":"),
        i;

    // Hours not in proper format
    if(o.length < 2 || c.length < 2) {
        return false;
    }

    // Converting array values to int
    for(i = 0; i < o.length; i++) {
        o[i] = parseInt(o[i]);
    }
    for(i = 0; i < c.length; i++) {
        c[i] = parseInt(c[i]);
    }

    // Set opening Date()
    var od = new Date(operatingDate.getFullYear(), operatingDate.getMonth(), operatingDate.getDate(), o[0], o[1], 0, 0);

    // Set closing Date()
    var closingDay = operatingDate.getDate();

    // Closing after midnight, shift day to tomorrow
    if(o[0] > c[0]) {
        closingDay++;
    }
    var cd = new Date(operatingDate.getFullYear(), operatingDate.getMonth(), closingDay, c[0], c[1], 0, 0);

    // Is within operating hours?
    return now > od && now < cd;
}

function isOpen(hours) {
    var now = new Date(),
        today = now.getDay(),
        thisMonth = now.getMonth(),
        thisYear = now.getFullYear(),
        open,
        close;

    var yesterday = today - 1;
    if(yesterday < 0) {yesterday = 6;}

    // Check today's opening hours
    if(hours[today]) {

        // It's open all day today
        if(hours[today].allday === true) {
            return true;
        }

        // It's closed all day today
        else if(hours[today].allday === false){
            return false;
        }

        // Not open all day
        else {
            open = hours[today].open;
            close = hours[today].close;

            // Check if the location is open
            if(checkOpenHours(now,now,open,close)) {
                return true;
            }
        }

    }

    // Check yesterday's opening hours in case of closing after midnight
    if(hours[yesterday]) {

        // Not open all day (possibly closing after midnight)
        if(!hours[yesterday].allday) {
            open = hours[today].open;
            close = hours[today].close;

            var yesterday_date = new Date(now.getFullYear(), now.getMonth(), (now.getDate()-1), 0, 0, 0, 0);
            if(checkOpenHours(now,yesterday_date,open,close)) {
                return true;
            }
        }

    }

    // Not open
    return false;
}

if(isOpen(hoursMorning) || isOpen(hoursAfternoon) && !isHoliday(holidays)) {
    //alert("We're open!");
    jQuery('body').addClass('service-open');
}
else {
    //alert("We're closed!");
    jQuery('body').addClass('service-closed');
}
