var routingMatchheight = {
    // Matchheight demo page
    'matchheight': {
        init: function() {
            // JavaScript to be fired on the home page

            jQuery(function() {
                jQuery('section.basic div').matchHeight();
            });
            jQuery('section.target div').matchHeight({
                target: jQuery('.follow-me')
            });
            //console.log('I am matchheight!');
        },
        finalize: function() {
            // JavaScript to be fired on the home page, after the init JS
        }
    }
};