// Localised script variables
var themeUrl = WPVARS.themeUrl;

function fillResults(data) {
   var item;
   var utility;
   var year;
   var currentKey;
   var currentTable;
   var currentUtility;
   var currentValue;
   var currentYear;

   for(year in data) {
      currentYearValue = year.replace('year', '');
      currentTable = 'div.step-4 ul#product-'+currentYearValue+' ';
      currentYear = data[year];

      for(utility in currentYear) {

         currentUtility = currentYear[utility];

         for(item in currentUtility) {

            currentKey = item;

            currentValue = currentUtility[item];

            //Set the selector element
            currentElement = currentTable + '.' + currentKey + '-' + utility;

            //For field .js-usage-elec we have to change the current element to .js-usage-elec-high
            if (currentElement === currentTable + '.js-usage-elec') {
                currentElement = currentTable + '.js-usage-elec-high';
            }

            //Fill in energy costs table
            jQuery(currentElement).text(currentValue);

            //Fill in hidden input fields
             jQuery(currentElement + '-form').val(currentValue);

         }
      }
   }

   jQuery(document).trigger('show-step-4');

}

jQuery(document).ready(function() {

   var estimate = true;

   jQuery('#check-step-2-exact').click(function() {
      estimate = false;
   });

   jQuery('#check-step-2-estimate').click(function() {
      estimate = true;
   });

   jQuery('.check-step-3').click(function() {
      var postalcode             = jQuery('#postalcode').val(),
          housenumber            = jQuery('#housenumber').val(),
          housenumberaddon       = jQuery('#housenumber-addon').val(), // optional
          electricityusagehigh   = 0,
          electricityusagelow    = 0,
          gasusage               = 0;
          estimatepeople         = '';
          estimatehouse          = '';

      if(estimate === true) {
         electricityusagehigh = jQuery('select.estimate-people option:selected').attr('data-usage');
         electricityusagelow  = '';
         gasusage             = jQuery('select.estimate-house option:selected').attr('data-usage');
         estimatepeople       = jQuery('select.estimate-people option:selected').val();
         estimatehouse        = jQuery('select.estimate-house option:selected').attr('data-radio-value');

         jQuery('.data-estimate-people').text(estimatepeople + ' personen');
         jQuery('.data-estimate-house').text(estimatehouse);
      } else {
         electricityusagehigh = jQuery('#electricity-usage-high').val();
         electricityusagelow  = jQuery('#electricity-usage-low').val();
         gasusage             = jQuery('#gas-usage').val();
      }

      jQuery('.data-postal-code').text(postalcode);
      jQuery('.data-housenumber').text(housenumber);
      jQuery('.data-housenumber-addon').text(housenumberaddon);

      jQuery('.data-estimate-people').text(estimatepeople);
      //estimate-house

      jQuery('.data-gas-usage').html(gasusage + ' m<sup>3</sup> gas');
      jQuery('.data-electricity-usage-high').text(electricityusagehigh);
      jQuery('.data-estimate-house-value').html(gasusage + ' m<sup>3</sup> gas');
      jQuery('.data-estimate-people-value').text(electricityusagehigh);
   });

   jQuery('#check-step-4').click(function() {

      // TODO: toevoegen validatie van gegevens en afvangen van ontbrekende waarden

      var postalcode             = jQuery('#postalcode').val(),
          housenumber            = jQuery('#housenumber').val(),
          housenumberaddon       = jQuery('#housenumber-addon').val(), // optional
          electricityusagehigh   = 0,
          electricityusagelow    = 0,
          gasusage               = 0;

      if(estimate) {
         electricityusagehigh = jQuery('select.estimate-people option:selected').attr('data-usage');
         electricityusagelow  = '';
         gasusage             = jQuery('select.estimate-house option:selected').attr('data-usage');
      } else {
         electricityusagehigh = jQuery('#electricity-usage-high').val();
         electricityusagelow  = jQuery('#electricity-usage-low').val();
         gasusage             = jQuery('#gas-usage').val();
      }

      jQuery('.electricityusagehigh').val(electricityusagehigh);
      jQuery('.electricityusagelow').val(electricityusagelow);
      jQuery('.gasusage').val(gasusage);

      jQuery.ajax(themeUrl + '/lib/energy/calculator.php', {
         data: {  postalcode: postalcode,
                  housenumber: housenumber,
                  housenumberaddon: housenumberaddon,
                  electricityusagehigh: electricityusagehigh,
                  electricityusagelow:electricityusagelow,
                  gasusage:gasusage
         },
         dataType: 'json',
         success: function(result) {
            fillResults(result);
         },
         error: function() {
            return false;

         }
      });

   });

});