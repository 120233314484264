/**
 * General input validation functions
 */
var initials = jQuery('#initials');
initials.on('keyup', function (e) {
  if (e.keyCode === 37 || e.keyCode === 39 || e.keyCode === 16 || e.keyCode === 8) {
    return;
  }
  this.value = this.value.toUpperCase().replace(/[^A-Z]/g, '');
  this.value = this.value.replace(/(.{1})/g, '$1.');
  if (this.value.length > 12) {
    this.value = this.value.slice(0, 12 - this.value.length);
  }
  return;
});
