var routingExample = {
    // All pages
    'example': {
        init: function() {
            // JavaScript to be fired on all pages
            //console.log('I am an initially loaded example!');
        },
        finalize: function() {
            // JavaScript to be fired on all pages, after page specific JS is fired
            //console.log('I am a finally loaded example!');
        }
    }
};