/**
 * Created by pvdsteen on 02/05/16.
 */
var rangeSlider = document.getElementById('slider-range');
var phonePaySectionTitle = jQuery('.offer-phone-payment .section-title');

function rangeArray(minPayment,maxPayment,activeProvider){

    var range = maxPayment - minPayment,
        customRange = {},
        i = minPayment,
        count = 0;

    for (i; i <= maxPayment; i++) {
        if(i === minPayment){
            //min
            customRange.min = i;
        } else if(i === maxPayment){
            //max
            customRange.max = i;
        } else{
            if(maxPayment > 2 && activeProvider === 'vodafone' && (i === 1 || i === 2)){
                count++;
                continue;
            }
            //in between
            customRange[(100 / range) * count + '%'] = i;
        }
        count++;
    }

    return customRange;
}

//todo activate if vodafone 0 euro payment should be supported
function filterMinMax(value){
    var basicBundleButtonActive = jQuery('.offer-contract-allinone button.is-active');
    var minPayment = parseInt(basicBundleButtonActive.attr('data-min-payment'),10);
    var maxPayment = parseInt(basicBundleButtonActive.attr('data-max-payment'),10);
    return value === minPayment || value === maxPayment ? 1 : 2;
}

jQuery(document).ready(function() {
    var activeProvider = jQuery('input.contract_provider').val();

    if(rangeSlider !== null) {
        var manualChange = false;
        var curDuration = jQuery('.offer-contract-duration button.is-active').attr('data-contract-duration');
        var basicBundleButtonActive = jQuery('.offer-contract-allinone button.is-active');
        var basicBundleButton = jQuery('.offer-contract-allinone button');
        var minPayment = parseInt(basicBundleButtonActive.attr('data-min-payment'),10);
        var maxPayment = parseInt(basicBundleButtonActive.attr('data-max-payment'),10);
        var maxPossiblePayment = parseInt(jQuery('.offer-contract-allinone button:first').attr('data-max-payment'),10);
        var initialPayment = parseInt(jQuery('#slider-range').attr('data-initial-payment'),10);
        var initialMaxPayment = false;
        var oncePayment = parseInt(jQuery('#slider-range').attr('data-phone-price'),10);

        if(initialPayment >= maxPayment){
            initialPayment = maxPayment;
            initialMaxPayment = true;
        }
        noUiSlider.create(rangeSlider, {
            start: [initialPayment],
            step: 1,
            range: {
                'min': [minPayment],
                'max': [maxPayment]
            },
            //todo activate if vodafone 0 euro payment should be supported
            //range: rangeArray(minPayment,maxPayment,activeProvider),
            connect: 'lower',
            tooltips: true,
            format: {
                to: function ( value ) {
                    return '€ ' + Math.round(value) + ',-';
                },
                from: function ( value ) {
                    return value.replace(',-', '').replace('&euro ','');
                }
            },
            //todo activate if vodafone 0 euro payment should be supported
            //snap: true,
            pips: { // Show a scale with the slider
                mode: 'steps',
                density: 1,
                //todo activate if vodafone 0 euro payment should be supported
                //filter: filterMinMax,
                format: {
                    to: function ( value ) {
                        return '€' +  Math.round(value);
                    },
                    from: function ( value ) {
                        return value.replace('€','');
                    }
                }
            }
        });

        var selectedPayment = parseInt(rangeSlider.noUiSlider.get().replace('€ ','').replace(',-',''), 10);

        rangeSlider.noUiSlider.on('slide', function(){
            manualChange = true;
            basicBundleButtonActive = jQuery('.offer-contract-allinone button.is-active');
            updatePhonePay(basicBundleButtonActive);
        });

        rangeSlider.noUiSlider.on('change', function(){
            selectedPayment = parseInt(rangeSlider.noUiSlider.get().replace('€ ','').replace(',-',''), 10);
            updatePhonePay(basicBundleButtonActive);
        });

        function updateSliderRange (minPayment, maxPayment, noPaymentChoice) { //jshint ignore:line
            var activePayment = initialPayment;
            rangeSlider.removeAttribute('disabled');
            rangeSlider.style.display = 'block';
            if(noPaymentChoice === 'at-once'){
                activePayment = '0';
                rangeSlider.setAttribute('disabled', true);
                rangeSlider.style.display = 'none';
            } else if(noPaymentChoice === 'no-choice'){
                rangeSlider.setAttribute('disabled', true);
                rangeSlider.style.display = 'none';
            } else if(manualChange){
                activePayment = selectedPayment;
            } else if(initialMaxPayment){
                activePayment = maxPayment;
            }

            rangeSlider.noUiSlider.updateOptions({
                range: {
                    'min': [minPayment],
                    'max': [maxPayment]
                },
                //todo activate if vodafone 0 euro payment should be supported
                //range: rangeArray(minPayment,maxPayment,activeProvider),
                start: activePayment,
                pips: { // Show a scale with the slider
                    mode: 'steps',
                    density: 1,
                    //todo activate if vodafone 0 euro payment should be supported
                    //filter: filterMinMax,
                    format: {
                        to: function ( value ) {
                            return '€' +  Math.round(value);
                        },
                        from: function ( value ) {
                            return value.replace('€','');
                        }
                    }
                }
            });
            // Remove current pips
            var p = rangeSlider.querySelector('.noUi-pips');
            p.parentElement.removeChild(p);
            // Add pips with current options (or pass new options)
            rangeSlider.noUiSlider.pips(rangeSlider.noUiSlider.options.pips);
        }


        function updatePhonePay ( activeBundle ) { //jshint ignore:line
            var originalPhoneCost = parseFloat(activeBundle.attr('data-phone-price'),10);
            var activePhoneDiscountOnce = parseFloat(activeBundle.attr('data-phone-discount'),10);
            var activePhoneDiscountOnceTotal = originalPhoneCost + activePhoneDiscountOnce;
            var activePhoneDiscountMonthRaw = rangeSlider.noUiSlider.get();
            var activePhoneDiscountMonth = activePhoneDiscountMonthRaw.replace('€ ','').replace(',-','');
            var activePhoneDiscountMonthTotal = activePhoneDiscountMonth * curDuration;
            var activePhonePayOnce = originalPhoneCost + activePhoneDiscountOnce - activePhoneDiscountMonthTotal;
            var activePhoneiDeal = activePhonePayOnce + parseFloat(4.24) + parseFloat(9.95);

            var metaBundleCode = jQuery('.phonepay-meta .bundle_code .value');
            var metaPhoneCostOriginal = jQuery('.phonepay-meta .total_cost_original .value');
            var metaPhoneDiscountOnce = jQuery('.phonepay-meta .discount_once .value');
            var metaPhoneCostAfterDiscountOnce = jQuery('.phonepay-meta .total_cost_after_discount_once .value');
            var metaPhoneDiscountMonth = jQuery('.phonepay-meta .discount_monthly .value');
            var metaPhoneDiscountMonthTotal = jQuery('.phonepay-meta .discount_monthly_total .value');
            var metaPhoneCost = jQuery('.phonepay-meta .total_cost_after_discount_monthly .value');
            var metaiDealCost = jQuery('.phonepay-meta .ideal .value');

            var descriptionPhoneCostAfterDiscountOnce = jQuery('.phonepay-description .total_cost_after_discount_once .value');
            var descriptionPhoneDiscountMonth = jQuery('.phonepay-description .discount_monthly .value');
            var descriptionDiscountMonthTotal = jQuery('.phonepay-description .discount_monthly_total .value');
            var descriptionPhoneCost = jQuery('.phonepay-description .total_cost_after_discount_monthly .value');

            var descriptionNoOrHasPhonePay = jQuery('.phonepay-description .no-or-has-phone-pay');
            var descriptionOnlyPhonePay = jQuery('.phonepay-description .only-phone-pay');
            var descriptionNoPhonePay = jQuery('.phonepay-description .no-phone-pay');
            var descriptionHasPhonePay = jQuery('.phonepay-description .has-phone-pay');

            var summaryPhoneDiscountMonth = jQuery('.phonepay-summary .discount_monthly .value');
            var summaryPhoneCost = jQuery('.phonepay-summary .total_cost_after_discount_monthly .value');

            var phoneColorRaw = jQuery('input[name="phone_color_raw"]').val();

            if(activePhonePayOnce === '0'){
                descriptionNoOrHasPhonePay.removeClass('is-hidden');
                descriptionNoPhonePay.removeClass('is-hidden');
                descriptionHasPhonePay.addClass('is-hidden');
                descriptionOnlyPhonePay.addClass('is-hidden');
            } else if(activePhoneDiscountMonth === '0'){
                descriptionOnlyPhonePay.removeClass('is-hidden');
                descriptionNoOrHasPhonePay.addClass('is-hidden');
                descriptionHasPhonePay.addClass('is-hidden');
                descriptionNoPhonePay.addClass('is-hidden');
            } else{
                descriptionNoOrHasPhonePay.removeClass('is-hidden');
                descriptionHasPhonePay.removeClass('is-hidden');
                descriptionNoPhonePay.addClass('is-hidden');
                descriptionOnlyPhonePay.addClass('is-hidden');
            }

            //TODO: set phonepay bundle code
            //console.log(activeBundle);
            var activeDurationMonths = jQuery('input.contract_duration').val();
            var activeDurationYears = parseFloat(activeDurationMonths) / 12;
            var rawBundleCode = false;
            var pad = false;
            var activeBundleCode = false;
            if(activeProvider == 'tele2'){//jshint ignore:line
                rawBundleCode = activePhoneDiscountMonth + '00';
                pad = "00000";
                activeBundleCode = 't'+pad.substring(0, pad.length - rawBundleCode.length) + rawBundleCode;
            } else if(activeProvider == 'vodafone'){//jshint ignore:line
                rawBundleCode = activePhoneDiscountMonth;

                if (phoneColorRaw === 'sim-only') {
                    activeBundleCode = 'AO_TOESTEL_RED4_RED_SIMONLY_CR';
                }
                else {
                    activeBundleCode = 'AO_TOESTELBETALING_' + activeDurationYears + '_JAAR_' + rawBundleCode + '_EURO_CR';
                }
            }
            metaBundleCode.html(activeBundleCode);
            //console.log(metaBundleCode);
            metaPhoneCostOriginal.html(originalPhoneCost);
            //console.log(originalPhoneCost);
            metaPhoneDiscountOnce.html(activePhoneDiscountOnce);
            //console.log(activePhoneDiscountOnce);
            metaPhoneCostAfterDiscountOnce.html(activePhoneDiscountOnceTotal);
            descriptionPhoneCostAfterDiscountOnce.html(activePhoneDiscountOnceTotal);
            //console.log(activePhoneDiscountOnceTotal);
            metaPhoneDiscountMonth.html(activePhoneDiscountMonth);
            descriptionPhoneDiscountMonth.html(activePhoneDiscountMonth);
            summaryPhoneDiscountMonth.html(activePhoneDiscountMonth);
            //console.log(activePhoneDiscountMonth);
            metaPhoneDiscountMonthTotal.html(activePhoneDiscountMonthTotal);
            descriptionDiscountMonthTotal.html(activePhoneDiscountMonthTotal);
            //console.log(activePhoneDiscountMonthTotal);
            metaPhoneCost.html(activePhonePayOnce);
            descriptionPhoneCost.html(activePhonePayOnce);
            summaryPhoneCost.html(activePhonePayOnce);
            //console.log(activePhonePayOnce);
            metaiDealCost.html(activePhoneiDeal);
            //console.log(activePhoneiDeal);
        }

        //console.log(rangeSlider.noUiSlider.get());

        basicBundleButton.on('click', function(){
            var minPayment = parseInt(jQuery(this).attr('data-min-payment'),10);
            var maxPayment = parseInt(jQuery(this).attr('data-max-payment'),10);
            var noPaymentChoice = false;
            var phonePaySectionTitleHTML = 'Kies je maandelijkse toestelbetaling';
            var noPaymentChoiceExplanation = false;
            var noPaymentChoiceExplanationElement = $('.no-payment-explanation');
            if(noPaymentChoiceExplanationElement.length){
                noPaymentChoiceExplanationElement.remove();
            }
            if(maxPayment === 0){
                noPaymentChoice = 'at-once';
                phonePaySectionTitleHTML = 'Je toestelbetaling';
                noPaymentChoiceExplanation = '<p class="no-payment-explanation is-at-once">Bij de door jou gekozen bundel kan de telefoon alleen volledig eenmalig worden betaald.</p>';
                phonePaySectionTitle.after(noPaymentChoiceExplanation);
                maxPayment = 1;
            } else if(minPayment === maxPayment){
                noPaymentChoice = 'no-choice';
                phonePaySectionTitleHTML = 'Je maandelijkse toestelbetaling';
                noPaymentChoiceExplanation = '<p class="no-payment-explanation is-no-choice">Bij de door jou gekozen bundel kan de telefoon met een maandelijks bedrag van &euro;'+maxPayment+',- worden betaald.</p>';
                phonePaySectionTitle.after(noPaymentChoiceExplanation);
            } else{
                jQuery('.no-payment-explanation').remove();
            }
            phonePaySectionTitle.html(phonePaySectionTitleHTML);
            updateSliderRange(minPayment, maxPayment, noPaymentChoice);
            updatePhonePay(jQuery(this));
        });

        basicBundleButtonActive.trigger("click");
    }
});
