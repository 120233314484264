/**
 * Tile JS
 */

//Ribbon
tileRibbon = jQuery('.tile.is-offer .ribbon');
tileRibbon.each(function(){
    currentRibbon = jQuery(this);
    tileRibbonHeightJS = currentRibbon.height();
    if(tileRibbonHeightJS > 22){
        currentRibbon.addClass('is-multiline');
    }
    // console.log(currentRibbon);
    // console.log('tile height JS = '+tileRibbonHeightJS);
});

