/**
 * Created by pvdsteen on 08/04/16.
 */
jQuery(window).on("load", function () {
    var urlHash = window.location.href.split("#")[1];
    if (urlHash &&  jQuery('#' + urlHash).length ){

        if(jQuery('body').hasClass('is-smooth')) {
            //is smooth class is never added

            if (jQuery('body').hasClass('logged-in')) {
                if (jQuery('body').hasClass('is-mq-mobile')) {
                    jQuery('html,body').animate({
                        scrollTop: jQuery('#' + urlHash).offset().top - 47 //base 0
                    }, 1000);
                } else if (jQuery('body').hasClass('is-mq-desktop')) {
                    jQuery('html,body').animate({
                        scrollTop: jQuery('#' + urlHash).offset().top - 147 //base 87
                    }, 1000);
                } else {
                    jQuery('html,body').animate({
                        scrollTop: jQuery('#' + urlHash).offset().top - 47 // base 0
                    }, 1000);
                }
            } else {
                if (jQuery('body').hasClass('is-mq-mobile')) {
                    jQuery('html,body').animate({
                        scrollTop: jQuery('#' + urlHash).offset().top - 15 //base 0
                    }, 1000);
                } else if (jQuery('body').hasClass('is-mq-desktop')) {
                    jQuery('html,body').animate({
                        scrollTop: jQuery('#' + urlHash).offset().top - 115 //base 87
                    }, 1000);
                } else {
                    jQuery('html,body').animate({
                        scrollTop: jQuery('#' + urlHash).offset().top - 15 // base 0
                    }, 1000);
                }
            }

        } else{

            if (jQuery('body').hasClass('logged-in')) {
                if (jQuery('body').hasClass('is-mq-mobile')) {
                    jQuery('html,body').animate({
                        scrollTop: jQuery('#' + urlHash).offset().top - 47 //base 0
                    }, 0);
                } else if (jQuery('body').hasClass('is-mq-desktop')) {
                    jQuery('html,body').animate({
                        scrollTop: jQuery('#' + urlHash).offset().top - 147 //base 87
                    }, 0);
                } else {
                    jQuery('html,body').animate({
                        scrollTop: jQuery('#' + urlHash).offset().top - 47 // base 0
                    }, 0);
                }
            } else {
                if (jQuery('body').hasClass('is-mq-mobile')) {
                    jQuery('html,body').animate({
                        scrollTop: jQuery('#' + urlHash).offset().top - 15 //base 0
                    }, 0);
                } else if (jQuery('body').hasClass('is-mq-desktop')) {
                    jQuery('html,body').animate({
                        scrollTop: jQuery('#' + urlHash).offset().top - 215 //base 87
                    }, 0);
                } else {
                    jQuery('html,body').animate({
                        scrollTop: jQuery('#' + urlHash).offset().top - 15 // base 0
                    }, 0);
                } 
            }

        }

    }
});