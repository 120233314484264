function stripInput(input)
{
    setTimeout(function(){
        input.value = input.value.replace(/\s/gi,"");
        input.value = input.value.replace(/-/g, "");
        return true;
    }, 0);
}

function stripEmailInput(input)
{
    setTimeout(function(){
        input.value = input.value.replace(/,/g,".");
        return true;
    }, 0);
}

function stripTelInput(input)
{
    setTimeout(function(){
        input.value = input.value.replace(/\D/g, "");
        return true;
    }, 0);
}