/*
  Formalize - version 1.2

  Note: This file depends on the jQuery library.
*/

// Module pattern:
// http://yuiblog.com/blog/2007/06/12/module-pattern
var FORMALIZE = (function(jQuery, window, document, undefined) {

  // Private constants.
  var PLACEHOLDER_SUPPORTED = 'placeholder' in document.createElement('input');
  var AUTOFOCUS_SUPPORTED = 'autofocus' in document.createElement('input');

  // Expose innards of FORMALIZE.
  return {
    // FORMALIZE.go
    go: function() {
      var i, j = this.init;

      for (i in j) {
        j.hasOwnProperty(i) && j[i](); // jshint ignore:line
      }
    },
    // FORMALIZE.init
    init: {
      // FORMALIZE.init.disable_link_button
      disable_link_button: function() {
        jQuery(document.documentElement).on('click', 'a.button_disabled', function() {
          return false;
        });
      },
      // FORMALIZE.init.checkboxes
      checkboxes: function() {
        if (!jQuery('input[type="checkbox"]') && !jQuery('input[type="radio"]').length || jQuery('input').hasClass('no-input-icon')) {
          return;
        }

        jQuery('input[type="checkbox"], input[type="radio"].is-checkbox').after('<span class="input-icon"></span>');
        jQuery('input[type="checkbox"], input[type="radio"].is-checkbox').hide();
      },
      // FORMALIZE.init.radiobuttons
      radiobuttons: function() {
        if (!jQuery('input[type="radio"]:not(.is-checkbox)').length || jQuery('input[type="radio"]:not(.is-checkbox)').hasClass('no-input-icon')) {
          return;
        }

        jQuery('input[type="radio"]:not(.is-checkbox)').after('<span class="input-icon"></span>');
        jQuery('input[type="radio"]:not(.is-checkbox)').hide();
      },
      // FORMALIZE.init.radiobuttons
      parent: function() {
        if (!jQuery('input[type="checkbox"]') && !jQuery('input[type="radio"]').length) {
          return; 
        }

        jQuery('input[type="checkbox"]').closest('label').addClass('is-checkbox');
        jQuery('input[type="radio"]').closest('label').addClass('is-radio');

        jQuery('input[type="checkbox"][disabled]').closest('label').addClass('is-disabled');
        jQuery('input[type="radio"][disabled]').closest('label').addClass('is-disabled');

        jQuery('input[type="checkbox"][required]').closest('label').addClass('is-required');
        jQuery('input[type="radio"][required]').closest('label').addClass('is-required');
      },
      // FORMALIZE.init.autofocus
      autofocus: function() {
        if (AUTOFOCUS_SUPPORTED || !jQuery(':input[autofocus]').length) {
          return;
        }

        var el = jQuery('[autofocus]')[0];

        if (!el.disabled) {
          el.focus();
        }
      },
      // FORMALIZE.init.placeholder
      placeholder: function() {
        if (PLACEHOLDER_SUPPORTED || !jQuery(':input[placeholder]').length) {
          // Exit if placeholder is supported natively,
          // or if page does not have any placeholder.
          return;
        }

        FORMALIZE.misc.add_placeholder();

        jQuery(':input[placeholder]').each(function() {
          // Placeholder obscured in older browsers,
          // so there's no point adding to password.
          if (this.type === 'password') {
            return;
          }

          var el = jQuery(this);
          var text = el.attr('placeholder');

          el.focus(function() {
            if (el.val() === text) {
              el.val('').removeClass('placeholder_text');
            }
          }).blur(function() {
            FORMALIZE.misc.add_placeholder();
          });

          // Prevent <form> from accidentally
          // submitting the placeholder text.
          el.closest('form').submit(function() {
            if (el.val() === text) {
              el.val('').removeClass('placeholder_text');
            }
          }).on('reset', function() {
            setTimeout(FORMALIZE.misc.add_placeholder, 50);
          });
        });
      }
    },
    // FORMALIZE.misc
    misc: {
      // FORMALIZE.misc.add_placeholder
      add_placeholder: function() {
        if (PLACEHOLDER_SUPPORTED || !jQuery(':input[placeholder]').length) {
          // Exit if placeholder is supported natively,
          // or if page does not have any placeholder.
          return;
        }

        jQuery(':input[placeholder]').each(function() {
          // Placeholder obscured in older browsers,
          // so there's no point adding to password.
          if (this.type === 'password') {
            return;
          }

          var el = jQuery(this);
          var text = el.attr('placeholder');

          if (!el.val() || el.val() === text) {
            el.val(text).addClass('placeholder_text');
          }
        });
      }
    }
  };
// Alias jQuery, window, document.
})(jQuery, this, this.document);

// Automatically calls all functions in FORMALIZE.init
jQuery(document).ready(function() {
  FORMALIZE.go();
});