jQuery(document).ready(function() {

    var anchorOffset = 15;
    var headerHeightInitial = 100;
    var headerHeightScrolled = 87;
    var loggedInMenuHeight = 0;

    jQuery('a[href*="#"][class*="smooth"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {

            if(jQuery('body').hasClass('is-mq-mobile')){
                headerHeight = 0;
            } else{
                if(jQuery('header.is-normal').hasClass('scroll-to-fixed-fixed')){
                    headerHeight = headerHeightScrolled;
                } else{
                    headerHeight = headerHeightInitial;
                }
            }
            if(jQuery('body').hasClass('logged-in')){
                loggedInMenuHeight = 32;
            }

            var totalOffset = loggedInMenuHeight + headerHeight + anchorOffset;
            var target = jQuery(this.hash);
            target = target.length ? target : jQuery('[name=' + this.hash.slice(1) +']');
            if (target.length) {
                jQuery('html,body').animate({
                    scrollTop: (target.offset().top - totalOffset) //base 87
                }, 1000);
                return false;
            }
        }
    });

});