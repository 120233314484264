jQuery(document).ready(function() {
    var accordionWapper = jQuery('.accordion');
    if(accordionWapper.hasClass('is-initial-open')){
        jQuery('.js-accordion-trigger').bind('click', function(e){
            jQuery(this).parent().find('.submenu').slideToggle('fast');  // apply the toggle to the ul
            jQuery(this).parent().toggleClass('is-expanded');
            e.preventDefault();
        });
    } else {
        jQuery('.js-accordion-trigger').bind('click', function(e){
            jQuery(this).parent().find('.submenu').slideToggle('fast');  // apply the toggle to the ul
            jQuery(this).parent().toggleClass('is-expanded');

            jQuery(this).parent().siblings().find('.submenu').slideUp('fast');
            jQuery(this).parent().siblings().removeClass('is-expanded');
            e.preventDefault();
        });
    }

});