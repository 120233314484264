var routingServiceLabel = {
    // GSM pages
    'template_service_label': {
        init: function() {
            // JavaScript to be fired on the home page
            //console.log('I am initially gsm offer!');
        },
        finalize: function() {
            // JavaScript to be fired on the home page, after the init JS
            //console.log('I am finally gsm offer!');
            jQuery('.service-pages li a').matchHeight();
        }
    }
};