function setNavigation(setNavigationMenu) {
    var path = window.location.pathname;
    path = path.replace(/\/jQuery/, "");
    path = decodeURIComponent(path);

    jQuery(setNavigationMenu +" a").each(function () {
        var href = jQuery(this).attr('href');
        if (path.substring(0, href.length) === href) {
            jQuery(this).closest('li').addClass('active');
            if(jQuery(setNavigationMenu).hasClass('accordion')){
                jQuery(this).closest('li').parent().closest('li').addClass('active is-expanded');
                jQuery(this).closest('li').parent().slideToggle('fast');
            }
        }
    });
}
