var routingCommon = {
    // All pages
    'common': {
        init: function() {
            // JavaScript to be fired on all pages
            //console.log('I am initially everywhere!');

            // Replace svg with png if browser does not support svg
            if(!Modernizr.svgasimg) {
                jQuery('img[src*="svg"]').attr('src', function() {
                    return jQuery(this).attr('src').replace('.svg', '.png');
                });
            }

            // Autosize textarea
            jQuery('textarea').each(function(){
                autosize(this);
            });

            // Lazy load product tile images
            jQuery('.lazy').unveil(300);

            // MatchHeight
            jQuery('body.is-miky footer .is-menu').matchHeight();

            // Miky label selection > Mobile menu
            jQuery(function() {
                var state = true;
                jQuery( ".category-menu.is-mobile .trigger" ).click(function(e) {
                    e.preventDefault();
                    if ( state ) {
                        jQuery(this).find('> .fa').removeClass('fa-plus');
                        jQuery(this).find('> .fa').addClass('fa-minus');
                        jQuery(".category-menu.is-mobile .menu-category").finish().slideDown(200, 'swing');
                    } else {
                        jQuery(this).find('> .fa').removeClass('fa-minus');
                        jQuery(this).find('> .fa').addClass('fa-plus');
                        jQuery(".category-menu.is-mobile .menu-category").finish().slideUp(200, 'swing');
                    }
                    state = !state;
                });
            });

            // Miky label selection > Desktop menu
            jQuery(function() {
                var labelMenuTrigger = jQuery(".labels .categories");
                var labelMenu = jQuery(".category-menu.is-desktop");
                labelMenuTrigger.addClass('is-closed');

                labelMenuTrigger.click(function(e) {
                    e.preventDefault();
                    if ( jQuery(this).hasClass('is-closed') ) {
                        jQuery(this).find('> .fa').removeClass('fa-caret-down');
                        jQuery(this).find('> .fa').addClass('fa-caret-up');
                        labelMenu.finish().slideDown(200, 'swing');
                        jQuery(this).removeClass('is-closed');
                        jQuery(this).addClass('is-open');
                    } else {
                        jQuery(this).find('> .fa').removeClass('fa-caret-up');
                        jQuery(this).find('> .fa').addClass('fa-caret-down');
                        labelMenu.finish().slideUp(200, 'swing');
                        jQuery(this).removeClass('is-open');
                        jQuery(this).addClass('is-closed');
                    }
                });

                jQuery(document).bind('click', function(event) {
                    if(!jQuery(event.target).is('is-closed') && !jQuery(event.target).parent().hasClass('category-wrapper')) {
                        labelMenuTrigger.find('> .fa').removeClass('fa-caret-up');
                        labelMenuTrigger.find('> .fa').addClass('fa-caret-down');
                        labelMenu.finish().slideUp(200, 'swing');
                        labelMenuTrigger.removeClass('is-open');
                        labelMenuTrigger.addClass('is-closed');
                    }
                });
            });

            // Current label > Mobile menu
            jQuery(function() {
                var state = true;
                jQuery( ".label-menu" ).click(function(e) {
                    e.preventDefault();
                    if ( state ) {
                        jQuery(".menu-wrapper").finish().slideDown(200, 'swing');
                    } else {
                        jQuery(".menu-wrapper").finish().slideUp(200, 'swing');
                    }
                    state = !state;
                });
            });

            // Toggle active class on mobile menu button
            var jQuerymenuToggleTrigger = jQuery('.menu > .trigger');
            jQuerymenuToggleTrigger.click(function(){jQuerymenuToggleTrigger.toggleClass('active');});

            // Open/Close submenu's
            function subMenuToggleMobile(e) {
                e.stopPropagation();
                var jQueryparentli = jQuery(this).closest('li');
                jQueryparentli.siblings('li.has-children').find('ul:visible').slideToggle(200, 'swing').closest('li').toggleClass('is-open');
                jQueryparentli.toggleClass('is-open').find('> ul').stop().slideToggle(200, 'swing');
            }

            // Prevent Submenu Parents from being clicked
            function subMenuParentClick(e) {
                e.preventDefault();
            }

            var jQuerymenuToggle = jQuery('.nav li.has-children > a');
            jQuerymenuToggle.on("click",subMenuParentClick);

            enquire.register("screen and (max-width:47.9375em)", {
                match : function() {
                    jQuerymenuToggle.on("click",subMenuToggleMobile);
                },
                unmatch : function() {
                    jQuery('.nav .sub-menu').css('display', '');
                    jQuerymenuToggle.off('click',subMenuToggleMobile);
                }
            });

            // slick carousel for all media queries
            jQuery('.bar-review .wrapper').finish().slick({
                infinite: true,
                arrows: false,
                fade: true,
                dots: true,
                speed: 500,
                autoplay: true,
                autoplaySpeed: 3000,
                adaptiveHeight: true,
                cssEase: 'ease-in-out',
                slidesToShow: 1,
                slidesToScroll: 1
            });



        },
        finalize: function() {
            // JavaScript to be fired on all pages, after page specific JS is fired
            //console.log('I am finally everywhere!');

            //hero carousel
            // slick carousel
            var heroCarousel = jQuery('section.hero.is-gsm .offer-wrapper');

            var initialSlideNumber = 0;

            if(heroCarousel.length){
                var i = jQuery('.hero-tile-wrapper').length;

                //initialSlideNumber = Math.floor(Math.random() * i);
            }

            heroCarousel.slick({
                infinite: true,
                mobileFirst: true,
                dots: true,
                fade: true,
                arrows: false,
                speed: 500,
                initialSlide: initialSlideNumber,
                autoplay: true,
                autoplaySpeed: 5000,
                slidesToShow: 1,
                slidesToScroll: 1
            });

            jQuery('section.hero.is-gsm .offer-wrapper .hero-tile-wrapper').matchHeight({
                target: jQuery('section.hero.is-gsm .offer-wrapper .hero-tile-wrapper .offer-image')
            });

            jQuery('.tile.is-gsm.is-provider .tile-content').matchHeight();
            jQuery('.tile.is-sim h3').matchHeight();
            jQuery('.tile.is-offer .offer-content').matchHeight();
            jQuery('.tile.is-offer h3').matchHeight();

            jQuery('.order-details .order-item > div').matchHeight();
            jQuery('.order-details > section > div').matchHeight();

            if(!jQuery('header').hasClass('is-order')){
                jQuery('header').scrollToFixed({
                    spacerClass: 'scrollspacer',
                    minWidth: (768 - scrollbarMeasure()),
                    zIndex: 1000
                });
            }

        }
    }
};
