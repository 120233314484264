jQuery(document).ready(function(){

    /*Temp conditional
    * TODO: add to dom based routing and only trigger on gsm
    * */
    if(jQuery('body').hasClass("template_order-form")) {
        /*
         * Contact address conditional fields
         * */
        var contactAddress = jQuery('.form-contact-address input');
        var contactAddressFields = jQuery('#contact-address-fields');

        contactAddress.on('change', function () {
            var addressChoice = jQuery('input[name=contact-address]:checked', '#energy-order');
            if (addressChoice.val() === 'j') {
                contactAddressFields.removeProp('hidden');
            } else if (addressChoice.val() === 'n') {
                contactAddressFields.prop('hidden', 'hidden');
            }
        });
       
        /*
         * Moving date conditional field
         * */
        var moveInput = jQuery('.form-move input');
        var moveDate = jQuery('.form-move-date');

        moveInput.on('change', function () {
            var moveChoice = jQuery('input[name=form-move]:checked', '#energy-order');
            if (moveChoice.val() === 'j') {
                moveDate.removeProp('hidden');
            } else if (moveChoice.val() === 'n') {
                moveDate.prop('hidden', 'hidden');
            }
        });
    }
 
}); 